import { useQuery, useQueryClient } from 'react-query';
import { IUser } from '@/common/types/user.type';
import { QUERY_KEYS } from '@/common/consts/app-keys.const';

export const setIsAnonUser = () => {
  const queryClient = useQueryClient();
  return (isAnon?: boolean) => {
    if (window === undefined) return;
    localStorage.setItem(QUERY_KEYS.IS_ANON_USER, isAnon ? 'true' : 'false');
    queryClient.invalidateQueries([QUERY_KEYS.IS_ANON_USER]);
  };
};

export const getIsAnonUser = () => {
  const { data: isAnon } = useQuery(
    [QUERY_KEYS.IS_ANON_USER],
    () => {
      if (window === undefined) return false;
      return localStorage.getItem(QUERY_KEYS.IS_ANON_USER) === 'true';
    },
    { staleTime: 1000 }
  );
  return !!isAnon;
};

export const setIsNonFinanceUser = () => {
  const queryClient = useQueryClient();
  return (isNonFinance?: boolean) => {
    if (window === undefined) return;
    localStorage.setItem(QUERY_KEYS.IS_NON_FINANCE, isNonFinance ? 'true' : 'false');
    queryClient.invalidateQueries([QUERY_KEYS.IS_NON_FINANCE]);
  };
};

export const getIsNonFinanceUser = () => {
  const { data: isNonFinance } = useQuery([QUERY_KEYS.IS_NON_FINANCE], () => {
    if (window === undefined) return false;
    return localStorage.getItem(QUERY_KEYS.IS_NON_FINANCE) === 'true';
  });
  // return !!isNonFinance;
  return false;
};

export const useUser = () => {
  const queryClient = useQueryClient();

  const user = queryClient.getQueryData<IUser>([QUERY_KEYS.USER]);

  return user;
};

export const useActivateUser = () => {
    const queryClient = useQueryClient();
  
    const user = queryClient.getQueryData<IUser>([QUERY_KEYS.USER]);
  
    const updateUser = (updatedUser: any) => {
      queryClient.setQueryData<IUser>([QUERY_KEYS.USER], (oldUser) => ({
        ...oldUser,
        ...updatedUser,
      }));
    };
  
    return { user, updateUser };
  };

