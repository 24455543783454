import styled from 'styled-components';
import Image from 'next/image';
import { FAMILIES } from '@/theme/fonts.const';

export const IdeaSearchInputContainer = styled.div<{ marginTop?: number }>`
  position: relative;
  width: 100%;
  max-width: 1078px;
`;

export const IdeaSearchInputImg = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const IdeaSearchInputImgContainer = styled.div<{ isAnon?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 100px;
  background: ${({ isAnon }) => (isAnon ? '#9D21FF' : '#0094d3')};
`;

export const CmdIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const KeyText = styled.p<{ isAnon?: boolean }>`
  color: ${({ isAnon }) => (isAnon ? '#9D21FF' : '#0094d3')};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const SearchWrapper = styled.div<{ isAnon?: boolean; mb?: number }>`
  position: relative;
  display: flex;
  padding: 8px;
  height: 49px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 20px;
  border: 1.5px solid ${({ isAnon }) => (isAnon ? 'rgba(115, 0, 205, 0.75)' : 'rgba(0, 148, 211, 0.75)')};
  background: rgba(246, 246, 246, 0.5);

  ${({ mb }) => mb && `margin-bottom: ${mb}px`}
`;

export const SearchIcon = styled(Image)`
  width: 30px;
  height: 30px;
  background: #0094d3;
`;

export const SearchInput = styled.input`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  &::placeholder {
    opacity: 1;
    color: #4d4d4d;
    font-weight: 400;
  }
`;

export const SoonText = styled.p`
  position: absolute;
  left: 166px;
  color: #970000;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;
