import React, { ReactElement } from 'react';
import { StockEntryModalComponent } from '@/common/components/onboard-new/components/stock-entry-modal';
import { ISelectedCompany } from '@/common/types/coverage.type';
import * as Styled from './security-select.styled';
import { removeFirstRepeatedWord } from '@/common/components/memo/components/submit-new-idea-form/model/helpers';

interface IProps {
  setCompany: (company: ISelectedCompany | null) => void;
  company: ISelectedCompany | null;
  uniqCompanies?: boolean;
  placeholder?: string;
}

export const SecuritySelectComponent = ({
  placeholder = 'Enter security ticker / name',
  setCompany,
  company,
  uniqCompanies
}: IProps): ReactElement => {
  const onSetCompany = (item: ISelectedCompany) => {
    setCompany(item);
  };

  const { id, name } = removeFirstRepeatedWord(`${company?.id} ${company?.name}`);
  return company ? (
    <Styled.ChipLabel>
      {id === name ? 'private' : id}&nbsp;{name}
    </Styled.ChipLabel>
  ) : (
    <StockEntryModalComponent
      uniqCompanies={uniqCompanies}
      onCompanyClick={onSetCompany}
      placeholder={placeholder}
      activeCompanies={[]}
      variant="poll-modal"
    />
  );
};
