import styled from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  height: fit-content;
`;

export const ActionButtonsContainer = styled.div``;

export const ModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;
export const ModalHeaderTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const ModalHeaderText = styled.div`
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  align-items: start;
  gap: 17px;
`;
export const ModalHeaderTitle = styled.div`
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const ModalTitle = styled.div`
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
`;

export const PollEndText = styled.div`
  color: #414141;
  line-height: 12px;
  font-size: 12px;
  font-weight: ${WEIGHTS.light};
  letter-spacing: 0;
  margin: 1px 0 11px;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
`;

export const SectionTitle = styled.p<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? '#C8C8C8' : '#008767')};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const SectionText = styled.div`
  color: #606060;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.1px;
`;

export const ActiveCompany = styled.button`
  padding: 8px 12px;
  border-radius: 5px;
  background: #eff1ff;
  color: #0016d8;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border: none;
  cursor: pointer;

  span {
    font-family: ${FONTS.FAMILIES.robotoFlex};
  }

  & > span:first-child {
    margin-right: 9px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  gap: 7px;
  height: 12px;
  margin-bottom: 10px;
`;

export const StepTitle = styled.div<{ isActive?: boolean }>`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: ${({ isActive }) => (isActive ? '#000' : '#8A8A8A')};
`;

export const StepText = styled.div`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #9f9f9f;
`;

export const ApearDescription = styled.p`
  color: #616161;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const SectionDescription = styled.p`
  color: #606060;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  padding-bottom: 10px;
`;

export const SectionInfo = styled.p`
  color: #979797;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const OptionalTitle = styled.p`
  padding: 10px 0;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.m};
`;

export const StepDot = styled.div`
  color: #c4c4c4;
  font-size: 12px;
  line-height: 12px;
  font-weight: ${FONTS.WEIGHTS.medium};
  display: flex;
  align-items: center;
`;

export const PollInput = styled.input<{ width?: number; mb?: number }>`
  display: flex;
  padding: 10px;
  width: ${({ width }) => (width ? `${width}px` : '395px')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  height: 40px;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #fbfbfb;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;

  &::placeholder {
    color: #969696;
    opacity: 1;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const KpiButton = styled.button`
  display: flex;
  width: 100px;
  border: none;
  padding: 11px 15px;
  align-items: center;
  gap: 2px;
  border-radius: 10px;
  color: #fff;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  background: #00a880;
  cursor: pointer;

  &:disabled {
    background: rgba(0, 168, 128, 0.2);
  }
`;

export const KpiButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
  margin: 3px;
`;
