import React, { useEffect, useState } from 'react';
import BlueSearch from '@styles/icons/blue-search.webp';
import Arrow from '@styles/icons/search-right-arrow.webp';
import * as Styled from './row-element-modal.styled';
import { ISearchObject } from '@/common/types/search.type';
import { AlgoliaIndexes } from '../../search-page.const';

interface IProp {
  keyData: string;
  value: string;
  index: number;
  nbHits: number;
  elements: ISearchObject[];
  onClick: (data: { type: string; clickedId: string; clickedValue: string; clickedTicker?: string }) => void;
  onClickBySeeMore: (keyData?: string) => void;
  onClickByMember: (profileId: string) => void;
}

export const RowElementComponent = ({ keyData, value, index, nbHits, elements, onClick, onClickBySeeMore, onClickByMember }: IProp) => {
  const [filteredResult, setFilteredResult] = useState<React.ReactElement[]>([]);

  useEffect(() => {
    if (keyData !== 'posts' && keyData !== 'key-debates' && keyData !== 'news' && keyData !== 'polls' && elements && elements.length > 0) {
      const result: React.ReactElement[] = [];

      for (let i = 0; i < elements.length; i += 1) {
        if (i <= 9) {
          result.push(
            <Styled.ButtonInRow
              onClick={() => {
                if (keyData === AlgoliaIndexes.USERS) {
                  onClickByMember(elements[i].id);
                } else {
                  onClick({
                    type: keyData,
                    clickedId: elements[i].id,
                    clickedValue: elements[i].text,
                    clickedTicker: elements[i].ticker
                  });
                }
              }}
            >
              <p>{elements[i].text}</p>
              {elements[i].ticker && <Styled.TickerText>{keyData === 'privates' ? 'private' : elements[i].ticker}</Styled.TickerText>}
            </Styled.ButtonInRow>
          );
        } else {
          result.push(
            <Styled.ButtonInRow onClick={() => onClickBySeeMore(keyData)}>
              <div>see more</div>
              <Styled.Arrow src={Arrow} alt="Arrow" />
            </Styled.ButtonInRow>
          );
          break;
        }
      }

      setFilteredResult(result);
    }
  }, [elements]);

  return (
    <Styled.RowWrapper key={keyData + index}>
      <Styled.RowTitle>
        <Styled.Icon src={BlueSearch} alt="Search" />
        <div>{nbHits}</div>
        <div>{value}</div>
      </Styled.RowTitle>
      {keyData === 'posts' && (
        <Styled.ButtonInRow onClick={() => onClickBySeeMore(keyData)}>
          <div>see all posts</div>
          <Styled.Arrow src={Arrow} alt="Arrow" />
        </Styled.ButtonInRow>
      )}
      {keyData === 'key-debates' && (
        <Styled.ButtonInRow onClick={() => onClickBySeeMore(keyData)}>
          <div>see all key debates</div>
          <Styled.Arrow src={Arrow} alt="Arrow" />
        </Styled.ButtonInRow>
      )}
      {keyData === 'news' && (
        <Styled.ButtonInRow onClick={() => onClickBySeeMore(keyData)}>
          <div>see all news</div>
          <Styled.Arrow src={Arrow} alt="Arrow" />
        </Styled.ButtonInRow>
      )}
      {keyData === 'polls' && (
        <Styled.ButtonInRow onClick={() => onClickBySeeMore(keyData)}>
          <div>see all polls</div>
          <Styled.Arrow src={Arrow} alt="Arrow" />
        </Styled.ButtonInRow>
      )}
      {keyData !== 'posts' && keyData !== 'key-debates' && keyData !== 'news' && keyData !== 'polls' && (filteredResult as any)}
    </Styled.RowWrapper>
  );
};
