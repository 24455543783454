import Image from 'next/image';
import styled from 'styled-components';
import { PostButtonCommonStyle } from '@/common/components/modal/components/post-button';
import { SelectButtonComponent } from '@/common/components/select-button';
import { BEAR_ANSWER, BULL_ANSWER } from '@/common/consts/idea.const';
import { FONTS, COLORS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';

export const CoverageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  min-height: 608px;
  height: fit-content;
`;

export const RepliesContainer = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 6px;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 30px;
`;

export const AddReplyButton = styled.button<{ isFirst: boolean; left?: number; bottom?: number }>`
  border: none;
  background: none;
  color: #0016d9;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 14px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '22px')};
  left: ${({ left }) => (left ? `${left}px` : '36px')};
  cursor: pointer;
  &:disabled {
    cursor: default;
    color: #cbcbcb;
  }
`;

export const AddReplyButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const PrivatePostButton = styled.button<{ isPrivate: boolean }>`
  border: none;
  cursor: pointer;
  display: flex;
  padding: 7px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ isPrivate }) => (isPrivate ? '#7900A4' : '#0086a4')};
  background: ${({ isPrivate }) => (isPrivate ? 'rgba(121, 0, 164, 0.10)' : 'rgba(0, 134, 164, 0.1)')};
`;

export const PrivatePostButtonImage = styled(Image)`
  display: flex;
  padding: 3px;
  width: 20px;
  height: 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 18px;
  width: 100%;
`;

export const ModalTitle = styled.div`
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
`;

export const ModalHeaderText = styled.div`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Section = styled.div<{ gap?: number }>`
  width: 100%;
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}px` : '5px')};
  flex-direction: column;
  align-items: flex-start;
`;

export const StepContainer = styled.div`
  display: flex;
  gap: 7px;
  height: 12px;
  margin-bottom: 10px;
`;

export const StepTitle = styled.div<{ isActive?: boolean }>`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: ${({ isActive }) => (isActive ? '#000' : '#8A8A8A')};
`;

export const StepText = styled.div`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #9f9f9f;
`;

export const OptionalTitle = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const OptionalText = styled.div`
  color: #c86000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const StepDot = styled.div`
  color: #c4c4c4;
  font-size: 12px;
  line-height: 12px;
  font-weight: ${FONTS.WEIGHTS.medium};
  display: flex;
  align-items: center;
`;

export const EnterCompanyContainer = styled.div`
  width: 100%;
`;

export const CompanyInput = styled.input`
  border: none;
  background: transparent;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: 20px;
  color: ${COLORS.black};
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #969696;
  }
`;

export const ActiveCompaniesContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4px;
`;

export const ActiveCompany = styled.button`
  padding: 8px 12px;
  border-radius: 5px;
  background: #f2f2f2;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border: none;
  cursor: pointer;

  span {
    font-family: ${FONTS.FAMILIES.robotoFlex};
  }

  & > span:first-child {
    margin-right: 9px;
  }
`;

export const TimeHorizonContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const TimeHorizonItem = styled.button<{ isDisabled?: boolean }>`
  border: 0;
  border-radius: 5px;
  background: ${({ isDisabled }) => (isDisabled ? '#F6F6F6' : '#f2f2f2')};
  color: ${({ isDisabled }) => (isDisabled ? '#C2C2C2' : '#000')};
  padding: 8px 12px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
  font-family: ${FONTS.FAMILIES.robotoFlex};
`;
export const ReasonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ReasonSelectItem = styled(SelectButtonComponent)<{ answer: number; selected: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  padding: 8px 12px;
  border-radius: 5px;
  background: ${({ selected }) => (!selected ? '#f2f2f2' : '#D0D0D0')};
  ${({ answer, selected }) => (answer === BEAR_ANSWER && selected ? 'color: #4D0EB4;' : '')}
  ${({ answer, selected }) => (answer === BEAR_ANSWER && selected ? 'background: rgba(77, 14, 180, 0.10);' : '')}
  ${({ answer, selected }) => (answer === BULL_ANSWER && selected ? 'color: #12847D;' : '')}
  ${({ answer, selected }) => (answer === BULL_ANSWER && selected ? 'background: rgba(0, 146, 137, 0.10);' : '')}
`;

export const BackButton = styled.button`
  ${PostButtonCommonStyle}
  color: #515151;
  padding: 8px 10px;
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.s};
  background: #eee;
  border-radius: 10px;
`;

export const BackButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const DeleteButton = styled.button<{ isClicked: boolean }>`
  ${PostButtonCommonStyle}
  padding: 10px 12px;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  background: #ededed;
  border-radius: 8px;
  ${({ isClicked }) =>
    isClicked ? 'border: 1px solid rgba(241, 0, 0, 0.20); background: rgba(241, 0, 0, 0.03); color: #F10000; padding: 9px 11px' : ''};
`;

export const DeleteButtonImage = styled(Image)`
  width: 11px;
  height: 14px;
`;

export const DeleteButtonClose = styled(Image)`
  width: 10px;
  height: 10px;
  margin-left: 12px;
`;
