import { IUser } from './user.type';

export interface IEmailVerifySendPayload {
  email: string;
}

export interface IEmailVerifyConfirmPayload extends IEmailVerifySendPayload {
  code: string;
}

export interface ICreateSimplifiedAnonUserPayload {
  username: string;
  vertical: string;
  subSectorId: string | null;
}

export interface ICreateAnonUserPayload extends ICreateSimplifiedAnonUserPayload {
  email: string;
  keyword: string;
}

export interface ICheckReservedUsernamePayload {
  username: string;
}

export enum FormType {
  Everyone = 'everyone',
  EveryoneAndUs = 'everyoneAndUs'
}

export enum ActionType {
    POST = 'POST',
    CONFIRM = 'CONFIRM'
}
  
export interface IReservetionProps {
  setIsUsernameReserved: (isReserved: boolean) => void;
  setIsUsernameConfirmed: (isUsernameConfirmed: boolean) => void;
  setUsernameToCheck: (username: string) => void;
}

export interface IReservedUsernameCodeConfirmPayload extends ICheckReservedUsernamePayload {
  code: string;
}

export interface IAnonUser extends IUser {
  isAnon: boolean;
  isUserHaveAnon: boolean;
  accessToken: string;
  refreshToken: string;
}

export interface IRealUser extends IUser {
  isAnon: boolean;
  isUserHaveAnon: boolean;
  accessToken: string;
  refreshToken: string;
}

export interface ILinkAnonToRealPayload {
  keyword: string;
}

export interface IFormData {
  email: string;
  username: string;
  password: string;
}
