/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, LineController } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SentimentType } from '@/common/types/question.type';
import * as Styled from './poll-graph.styled';
import { COLORS } from '@/theme';
import { BEAR_ANSWER, BULL_ANSWER } from '@/common/consts/idea.const';
import { IPollPosts } from '@/common/types/post.type';
import { PollTextComponent } from '../poll-text';
import { createAddBullBearPlugin, createAddTextPlugin, createAddYouPlugin } from './lib/plugins';
import { calculateChartPoints, getUnitText } from './lib/helpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, LineController);

interface IProps {
  item: IPollPosts;
  highlight?: string;
  isFinal?: boolean;
}

export const PollGraphComponent: React.FC<IProps> = ({ item, isFinal, highlight }) => {
  if (!item) return <span />;
  const [chartKey, setChartKey] = useState(0);
  const { midPoint, totalIntervalCount, ownAnswer, ownAnswerPosition } = item;
  const answers = [midPoint, ...item.answers];
  const lastAnswer = parseFloat(item?.answers[item.answers.length - 1]?.toFixed(3));
  const { bottomPoint, topPoint } = calculateChartPoints(midPoint);

  const getDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);

    return `${day} ${month}`;
  };

  const [firstDate, secondDate] = useMemo(() => {
    const startDate = new Date(item!.startDate);
    const endDate = new Date(item!.endDate);
    return startDate < endDate ? [startDate, endDate] : [endDate, startDate];
  }, [item]);

  const unitText = getUnitText(item.unit);
  const isOwnAnswerMoreThenMidPoint = ownAnswer && ownAnswer < midPoint;
  const labels = useMemo(() => Array.from({ length: Math.abs(totalIntervalCount!) + 1 }, (_, i) => i.toString()), [totalIntervalCount]);

  useEffect(() => {
    setChartKey((prevKey) => prevKey + 1);
  }, [ownAnswer, ownAnswerPosition]);

  return (
    <Styled.SentimentSwingsItemWrapper>
      <PollTextComponent poll={item} highlight={highlight} />
      <Styled.SwingsChartWrapper>
        <Styled.BoxContainer>
          <Styled.ChartDateLineContainer>
            <Styled.ChartDateLine />
            <Styled.ChartDateText>
              {item.midPoint}
              {unitText}
            </Styled.ChartDateText>
          </Styled.ChartDateLineContainer>
        </Styled.BoxContainer>
        <Styled.ChartLineWrapper>
          <Line
            key={chartKey}
            style={{
              width: '100%',
              height: '100%'
            }}
            plugins={[
              createAddYouPlugin(ownAnswer!, unitText!, isOwnAnswerMoreThenMidPoint as boolean, ownAnswerPosition),
              createAddTextPlugin(
                ownAnswer!,
                ownAnswerPosition,
                unitText!,
                isOwnAnswerMoreThenMidPoint as boolean,
                lastAnswer,
                midPoint,
                bottomPoint.toString(),
                topPoint.toString()
              ),
              createAddBullBearPlugin({ bottomPoint, unitText: unitText!, topPoint: topPoint! })
            ]}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false
                },
                tooltip: {
                  enabled: false
                }
              },
              scales: {
                x: {
                  display: false
                },
                y: {
                  display: false,
                  max:
                    item!.sentimentType === SentimentType.POLL
                      ? topPoint
                      : item!.sentimentType === SentimentType.QUERY
                      ? 6 + 0.05
                      : BULL_ANSWER,
                  min:
                    item!.sentimentType === SentimentType.POLL
                      ? bottomPoint
                      : item!.sentimentType === SentimentType.QUERY
                      ? 1 - 0.05
                      : BEAR_ANSWER
                }
              },
              elements: {
                point: {
                  radius: 0
                },
                line: {
                  borderWidth: 2
                }
              }
            }}
            data={{
              labels,
              datasets: [
                {
                  data: answers,
                  borderColor(context) {
                    const { chart } = context;
                    const { ctx, chartArea } = chart;
                    if (chartArea) {
                      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                      gradient.addColorStop(0, COLORS.sentimentSwingLineGradientFrom);
                      gradient.addColorStop(1, COLORS.sentimentSwingLineGradientTo);
                      return gradient;
                    }
                  },
                  spanGaps: true,
                  cubicInterpolationMode: 'monotone'
                }
              ]
            }}
          />
        </Styled.ChartLineWrapper>
      </Styled.SwingsChartWrapper>
      <Styled.ChartDateContainer>
        <Styled.ChartDate horizontal="left">
          {getDate(firstDate)} {firstDate.getFullYear()}
        </Styled.ChartDate>
        <Styled.ChartDate horizontal="right">
          {getDate(secondDate)} {secondDate.getFullYear()}
        </Styled.ChartDate>
      </Styled.ChartDateContainer>
    </Styled.SentimentSwingsItemWrapper>
  );
};
