import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { StackProps } from '@mui/material';
import { FlexComponent } from '@/common/components/flex';
import * as Styled from './unit-select.styled';
import { unitOptions, EUnits } from '@/common/types/poll.types';

interface IProps {
  selectedUnit: EUnits | string;
  setSelectedUnit: Dispatch<SetStateAction<EUnits | string>>;
  mt?: StackProps['mt'];
}

export const UnitSelectComponent = ({ selectedUnit, setSelectedUnit, mt }: IProps): ReactElement => (
  <FlexComponent gap="5px" mt={mt} alignItems="center">
    <Styled.UnitText>Unit:</Styled.UnitText>
    {unitOptions.map((item) => (
      <Styled.Item key={item.value} onClick={() => setSelectedUnit(item.value)} isActive={selectedUnit === item.value}>
        {item.label}
      </Styled.Item>
    ))}
  </FlexComponent>
);
