export const createAddYouPlugin = (
  ownAnswer: number,
  unitText: string,
  isOwnAnswerMoreThenMidPoint: boolean,
  ownAnswerPosition: number
) => ({
  id: 'addYouPlugin',
  afterDraw: (chart: any) => {
    const { ctx, chartArea, scales } = chart;
    if (!chartArea || !ownAnswer) return;

    const datasetMeta = chart.getDatasetMeta(0);
    if (!datasetMeta) return;
    const visiblePoints = datasetMeta.data.filter((point: any) => !point?.skip);

    if (visiblePoints.length < 2) return;

    const firstPoint = visiblePoints[0];
    const secondPoint = visiblePoints[1];
    const stepX = secondPoint.x - firstPoint.x;
    let targetX = firstPoint.x + stepX * ownAnswerPosition;
    let targetY = scales.y.getPixelForValue(ownAnswer);

    const padding = 40;
    let isOut;
    let isOutLeft;
    let bottomOut;
    if (targetX + padding > chartArea.right) {
      targetX = chartArea.right - padding;
    }
    if (targetY - padding < chartArea.top) {
      isOut = true;
      targetY = chartArea.top + padding;
    }
    if (targetY + padding > chartArea.bottom) {
      isOut = true;
      bottomOut = true;
      targetY = chartArea.bottom - 10;
    }
    if (targetX - padding < chartArea.left) {
      isOutLeft = true;
      targetX = chartArea.left;
    }
    const bottomValue = bottomOut ? 10 : 0;

    let youTextPosition = null;
    let ownAnswerTextPosition = null;

    if (isOutLeft) {
      ctx.save();
      ctx.font = '500 12px Roboto Flex';
      ctx.fillStyle = '#B6730E';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('you', targetX + 12, targetY - 10 - bottomValue);
      ctx.restore();

      youTextPosition = {
        x: targetX + 12,
        y: targetY - 10 - bottomValue,
        width: ctx.measureText('you').width,
        height: 12
      };

      if (isOut) {
        const countPosition = targetY + 4;
        ctx.save();
        ctx.font = '500 12px Roboto Flex';
        ctx.fillStyle = '#B6730E';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const text = `${ownAnswer}${unitText}`;
        const textWidth = ctx.measureText(text).width;
        let adjustedTargetX = targetX;
        if (adjustedTargetX - textWidth / 2 < chartArea.left) {
          adjustedTargetX = chartArea.left + textWidth / 2;
        }
        ctx.fillText(text, adjustedTargetX, countPosition - bottomValue);
        ctx.restore();

        ownAnswerTextPosition = {
          x: adjustedTargetX,
          y: countPosition - bottomValue,
          width: textWidth,
          height: 12
        };
      }
      ctx.save();
      ctx.font = '500 16px Roboto Flex';
      ctx.fillStyle = '#B6730E';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('•', targetX + 3, targetY - (isOwnAnswerMoreThenMidPoint ? -15 : 20) - bottomValue);
      ctx.restore();
    } else {
      ctx.save();
      ctx.font = '500 12px Roboto Flex';
      ctx.fillStyle = '#B6730E';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('you', targetX, targetY - 10 - bottomValue);
      ctx.restore();

      youTextPosition = {
        x: targetX,
        y: targetY - 10 - bottomValue,
        width: ctx.measureText('you').width,
        height: 12
      };

      if (isOut) {
        const countPosition = targetY + 4;
        ctx.save();
        ctx.font = '500 12px Roboto Flex';
        ctx.fillStyle = '#B6730E';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${ownAnswer}${unitText}`, targetX, countPosition - bottomValue);
        ctx.restore();

        ownAnswerTextPosition = {
          x: targetX,
          y: countPosition - bottomValue,
          width: ctx.measureText(`${ownAnswer}${unitText}`).width,
          height: 12
        };
      }
      ctx.save();
      ctx.font = '500 16px Roboto Flex';
      ctx.fillStyle = '#B6730E';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('•', targetX, targetY - (isOwnAnswerMoreThenMidPoint ? -4 : 20));
      ctx.restore();
    }

    chart._youTextPosition = youTextPosition;
    chart._ownAnswerTextPosition = ownAnswerTextPosition;
  }
});

export const createAddTextPlugin = (
  ownAnswer: number,
  ownAnswerPosition: number,
  unitText: string,
  isOwnAnswerMoreThenMidPoint: boolean,
  lastAnswer: number,
  midPoint: number,
  bottomPoint: string,
  topPoint: string
) => ({
  id: 'addTextPlugin',
  afterDraw: (chart: any) => {
    const { ctx, chartArea, scales } = chart;
    if (!chartArea) return;

    const datasetMeta = chart.getDatasetMeta(0);
    if (!datasetMeta) return;

    const visiblePoints = datasetMeta.data.filter((point: any) => !point.skip);
    if (visiblePoints.length < 2) return;

    const firstPoint = visiblePoints[0];
    const secondPoint = visiblePoints[1];
    const stepX = secondPoint.x - firstPoint.x;

    let targetX = firstPoint.x + stepX * ownAnswerPosition;
    let targetY = scales.y.getPixelForValue(ownAnswer);

    const padding = 40;
    let isOut = false;
    let isOutLeft = false;
    let bottomOut = false;
    if (targetX + padding > chartArea.right) {
      targetX = chartArea.right - padding;
    }
    if (targetY - padding < chartArea.top) {
      isOut = true;
      targetY = chartArea.top + padding;
    }
    if (targetY + padding > chartArea.bottom) {
      isOut = true;
      bottomOut = true;
      targetY = chartArea.bottom - 10;
    }
    if (targetX - padding < chartArea.left) {
      isOutLeft = true;
      targetX = chartArea.left;
    }
    const bottomValue = bottomOut ? 10 : 0;

    ctx.font = '500 12px Roboto Flex';
    const youTextWidth = ctx.measureText('you').width;
    const youTextX = isOutLeft ? targetX + 12 : targetX;
    const youTextY = targetY - 10 - bottomValue;
    const youTextPosition = {
      x: youTextX - youTextWidth / 2,
      y: youTextY - 6,
      width: youTextWidth,
      height: 12
    };

    let ownAnswerTextPosition = null;
    if (isOut) {
      const countPosition = targetY + 4;
      ctx.font = '500 12px Roboto Flex';
      const text = `${ownAnswer}${unitText}`;
      const textWidth = ctx.measureText(text).width;
      let adjustedTargetX = targetX;
      if (adjustedTargetX - textWidth / 2 < chartArea.left) {
        adjustedTargetX = chartArea.left + textWidth / 2;
      }
      ownAnswerTextPosition = {
        x: adjustedTargetX - textWidth / 2,
        y: countPosition - bottomValue - 6,
        width: textWidth,
        height: 12
      };
    }

    if (!youTextPosition || (isOut && !ownAnswerTextPosition)) {
      return;
    }

    const lastPoint = visiblePoints[visiblePoints.length - 1];
    if (!lastPoint) return;

    const villanText = `Villan consensus: ${Number(lastAnswer)}${unitText}`;
    ctx.font = '400 12px Roboto Flex';
    const villanTextWidth = ctx.measureText(villanText).width;
    const villanPadding = 5;
    const backgroundColor = 'white';

    let x = lastPoint.x + 10;
    if (x + villanTextWidth + villanPadding * 2 > chartArea.right) {
      x = lastPoint.x - villanTextWidth - villanPadding * 2 - 10;
    }
    let y = lastPoint.y - 15;

    const textHeight = 14;
    if (y - textHeight < chartArea.top) {
      y = chartArea.top + textHeight;
    } else if (y > chartArea.bottom) {
      y = chartArea.bottom;
    }

    const villanTextPosition = {
      x,
      y: y - textHeight,
      width: villanTextWidth + 2 * villanPadding,
      height: textHeight
    };

    let isOverlapping = false;

    const checkOverlap = (rect1: any, rect2: any) =>
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y;

    if (youTextPosition && checkOverlap(villanTextPosition, youTextPosition)) {
      isOverlapping = true;
    }

    if (ownAnswerTextPosition && checkOverlap(villanTextPosition, ownAnswerTextPosition)) {
      isOverlapping = true;
    }

    const fontSize = 12;
    const bullText = `${topPoint}${unitText}`;
    const bearText = `${bottomPoint}${unitText}`;
    const paddingBullBear = 10;

    ctx.font = `300 ${fontSize}px Roboto Flex`;
    const bullTextWidth = ctx.measureText(bullText).width;
    const bullX = chartArea.right - paddingBullBear + 9 - bullTextWidth;
    const bullY = chartArea.top + paddingBullBear + 1;
    const bullTextPosition = {
      x: bullX,
      y: bullY,
      width: bullTextWidth,
      height: fontSize
    };

    const bearTextWidth = ctx.measureText(bearText).width;
    const bearX = chartArea.right - paddingBullBear + 9 - bearTextWidth;
    const bearY = chartArea.bottom - paddingBullBear + 1 - fontSize;
    const bearTextPosition = {
      x: bearX,
      y: bearY,
      width: bearTextWidth,
      height: fontSize
    };

    if (villanTextPosition && checkOverlap(villanTextPosition, bullTextPosition)) {
      isOverlapping = true;
    }
    if (villanTextPosition && checkOverlap(villanTextPosition, bearTextPosition)) {
      isOverlapping = true;
    }

    if (isOverlapping) {
      const newX = x - villanTextWidth - 20;
      if (newX >= chartArea.left) {
        x = newX;
      } else {
        let newY = y - textHeight - 10;
        if (newY - textHeight >= chartArea.top) {
          y = newY;
        } else {
          newY = y + textHeight + 20;
          if (newY + textHeight <= chartArea.bottom) {
            y = newY;
          }
        }
      }
      villanTextPosition.x = x;
      villanTextPosition.y = y - textHeight;
    }

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(villanTextPosition.x, villanTextPosition.y, villanTextPosition.width, villanTextPosition.height);

    ctx.fillStyle = lastAnswer >= midPoint ? '#00968D' : '#5A14CD';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'bottom';
    ctx.fillText(villanText, villanTextPosition.x + villanPadding, y);
    ctx.restore();
  }
});

export const createAddBullBearPlugin = ({
  bottomPoint,
  unitText,
  topPoint
}: {
  bottomPoint: string | number;
  unitText: string | number;
  topPoint: string | number;
}) => ({
  id: 'addBullBearPlugin',
  afterDraw: (chart: any) => {
    const { ctx, chartArea } = chart;

    if (!chartArea) return;

    const bullText = `${topPoint}${unitText}`;
    const bearText = `${bottomPoint}${unitText}`;
    const fontSize = 12;
    const padding = 10;

    ctx.save();
    ctx.font = `300 ${fontSize}px Roboto Flex`;
    ctx.fillStyle = '#0E5B57';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'top';

    const bullX = chartArea.right - padding + 9;
    const bullY = chartArea.top + padding + 1;
    ctx.fillText(bullText, bullX, bullY);
    ctx.restore();

    ctx.save();
    ctx.font = `300 ${fontSize}px Roboto Flex`;
    ctx.fillStyle = '#4E0EB6';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'bottom';

    const bearX = chartArea.right - padding + 9;
    const bearY = chartArea.bottom - padding + 1;
    ctx.fillText(bearText, bearX, bearY);
    ctx.restore();
  }
});
