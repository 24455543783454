import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAuthProfile, useAuth, useDebounce, getIsAnonUser, useGetFilters } from '@/common/hooks';
import * as Styled from './poll-page.styled';
import { quotePostModalWrapper } from '../profile/components/new-quote-modal/new-quote-modal-wrapper.component';
import { ReplyModalWrapper } from '../profile/components/reply-modal';
import { useGetAllPollFilter, useGetAvailablePollFilter } from '@/common/hooks/use-poll';
import { CreateModalComponent } from '../create-modal/create-modal.component';
import { CreateModalType } from '../create-modal/modal-type.const';
import AddPollIcon from '@/styles/icons/create-poll-plus.webp';
import FilterAvailableIcon from '@/styles/icons/poll-filter-available.webp';
import FilterDisabledIcon from '@/styles/icons/poll-filter-disabled.webp';
import FilterSelectedIcon from '@/styles/icons/poll-filter-selected.webp';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { SearchInputComponent } from '../memo/components/search-input';
import { FilterType } from '@/common/types/profile.type';
import { PollListComponent } from './components/poll-list';

const REGIONS_FILTER = [
  { label: 'n. america', value: 'North America' },
  { label: 'eur.', value: 'Europe' },
  { label: 'japan', value: 'Japan' },
  { label: 'china', value: 'China' },
  { label: 'india', value: 'India' },
  { label: 's + se asia', value: 'South and Southeast Asia' },
  { label: 'latam', value: 'Latin America' }
];

const SECTORS = [
  'consumer',
  'energy + utilities',
  'financials',
  'healthcare',
  'industrials',
  'materials',
  'r. estate',
  'tech',
  'telco + media'
];

export const ALL_PERIODS = [2024, 2025, 2026];

const CATEGORIES = ['equity', 'ig credit', 'levered loan', 'hy credit', 'distressed', 'em credit', 'early vc', 'growth vc'];

export const PollPageComponent = () => {
  const { isUserLoading } = useAuth();
  const { data: userProfileId } = useAuthProfile();
  const isAnon = getIsAnonUser();
  const [search, setSearch] = useState('');
  const [periods, setPeriods] = useState<number[]>([]);
  const [regions, setRegions] = useState<string[]>([]);
  const [sectors, setSectors] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState<() => {}>();
  const {
    sectors: deboucedSectors,
    regions: deboucedRegions,
    periods: deboucedPeriods,
    categories: deboucedCategories,
    search: debouncedSearch
  } = useDebounce(
    {
      sectors,
      regions,
      periods,
      categories,
      search
    },
    500
  );
  const { quotePostHandler, componentToView } = quotePostModalWrapper();
  const { openReplyModal, componentToView: replyModalComponentToView } = ReplyModalWrapper({});
  const leftColumnRef = useRef<null | HTMLDivElement>(null);

  const { data: allFilters, isLoading: isLoadingAllFilters } = useGetAllPollFilter();

  const { data: filters, isLoading: isLoadingFilters, isRefetching: isRefetchingFilter } = useGetFilters(FilterType.POLL);

  const {
    data: availableFilters,
    isFetching: isLoadingAvailableFilters,
    refetch: refetchAvailableFilters
  } = useGetAvailablePollFilter({
    regions: deboucedRegions,
    periods: deboucedPeriods,
    sectors: deboucedSectors,
    categories: deboucedCategories,
    search: debouncedSearch
  });
  const [openModal, setOpenModal] = useState(false);

  const addDebateClick = () => {
    setOpenModal(true);
  };

  const allPeriods = useMemo(
    () => (allFilters?.periods && allFilters?.periods?.length > 0 ? allFilters?.periods.sort((a, b) => a - b) : []),
    [allFilters?.periods]
  );

  const availablePeriods = useMemo(
    () => (availableFilters?.periods && availableFilters?.periods?.length > 0 ? availableFilters?.periods.sort((a, b) => a - b) : []),
    [availableFilters?.periods]
  );

  const availableRegions = useMemo(
    () => (availableFilters?.regions && availableFilters?.regions?.length > 0 ? availableFilters?.regions : []),
    [availableFilters?.regions]
  );

  const availableSectors = useMemo(
    () => (availableFilters?.sectors && availableFilters?.sectors?.length > 0 ? availableFilters?.sectors : []),
    [availableFilters?.sectors]
  );

  const availableCategories = useMemo(
    () => (availableFilters?.categories && availableFilters?.categories?.length > 0 ? availableFilters?.categories : []),
    [availableFilters?.categories]
  );

  useEffect(() => {
    if (!filters && !isLoadingFilters && !isRefetchingFilter) {
      setIsLoading(false);
    }
    if (filters && !isLoadingFilters && !isRefetchingFilter) {
      setSectors(filters.sectors ?? []);
      setRegions(filters.regions ?? []);
      setCategories(filters.categories ?? []);
      setPeriods(filters.periods?.map((it) => Number.parseInt(it, 10)) ?? []);
      setTimeout(() => setIsLoading(false), 550);
    }
  }, [filters, isLoadingFilters, isRefetchingFilter]);

  return (
    <Styled.PollPageWrapper
      contentLeftMargin="56px"
      isLoading={isUserLoading || isLoadingFilters || isRefetchingFilter || isLoading}
      sidebarActive="Polls"
      disableScroll
    >
      {componentToView}
      {replyModalComponentToView}
      {!!userProfileId && !isLoadingAllFilters && !isLoadingFilters ? (
        <AnimatePresence>
          <motion.div
            transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Styled.ColumnsWrapper>
              <Styled.LeftColumn ref={leftColumnRef}>
                <Styled.HeaderContainer>
                  <Styled.AddPollButton onClick={addDebateClick}>
                    <Styled.AddPolIcon src={AddPollIcon} alt="add-poll" />
                    <Styled.AddPolText>Create poll</Styled.AddPolText>
                  </Styled.AddPollButton>
                </Styled.HeaderContainer>
                <CreateModalComponent
                  open={openModal}
                  closeHandler={() => {
                    refetch?.();
                    refetchAvailableFilters();
                    setOpenModal(false);
                  }}
                  modalType={CreateModalType.POLL}
                />
                <PollListComponent
                  filters={{
                    sectors: deboucedSectors,
                    regions: deboucedRegions,
                    periods: deboucedPeriods,
                    categories: deboucedCategories,
                    search: debouncedSearch
                  }}
                  quotePostHandler={quotePostHandler}
                  openReplyModal={openReplyModal}
                  setRefetch={setRefetch}
                  userProfileId={userProfileId}
                />
              </Styled.LeftColumn>
              <Styled.RightColumn>
                <SearchInputComponent isAnon={isAnon} mb={40} value={search} onChange={setSearch} placeholder="search polls" />
                <Styled.PollFilterWrapper>
                  <Styled.PollFilterTitle>Filters</Styled.PollFilterTitle>
                  <Styled.PollFilterRowContainer>
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Sector</Styled.PollFilterRowTitle>
                      {SECTORS.map((sector) => {
                        const isActive = sectors.includes(sector);
                        const isDisabled = !availableSectors.includes(sector) && !sectors.includes(sector);
                        return (
                          <Styled.PollFilterRowItem
                            id={`${sector}-poll-sector`}
                            isActive={isActive}
                            isDisabled={isDisabled}
                            onClick={() => {
                              if (isLoadingAvailableFilters || isDisabled) return;
                              setSectors((prev) =>
                                prev.includes(sector) ? (prev.filter((it) => it !== sector) ?? []) : [...prev, sector]
                              );
                            }}
                          >
                            {sector}
                            <Styled.FilterButtonImage
                              src={isActive ? FilterSelectedIcon : isDisabled ? FilterDisabledIcon : FilterAvailableIcon}
                              alt="icon"
                            />
                          </Styled.PollFilterRowItem>
                        );
                      })}
                    </Styled.PollFilterRow>
                    <Styled.PollFilterLine />
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Period</Styled.PollFilterRowTitle>
                      {allPeriods &&
                        allPeriods.map((period) => {
                          const isActive = periods.includes(period);
                          const isDisabled = !availablePeriods.includes(period) && !periods.includes(period);
                          return (
                            <Styled.PollFilterRowItem
                              key={`period-${period}`}
                              isActive={isActive}
                              isDisabled={isDisabled}
                              onClick={() => {
                                if (isLoadingAvailableFilters || isDisabled) return;
                                setPeriods((prev) =>
                                  prev.includes(period) ? (prev.filter((it) => it !== period) ?? []) : [...prev, period]
                                );
                              }}
                            >
                              {period}
                              <Styled.FilterButtonImage
                                src={isActive ? FilterSelectedIcon : isDisabled ? FilterDisabledIcon : FilterAvailableIcon}
                                alt="icon"
                              />
                            </Styled.PollFilterRowItem>
                          );
                        })}
                    </Styled.PollFilterRow>
                    <Styled.PollFilterLine />
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Region</Styled.PollFilterRowTitle>
                      {REGIONS_FILTER.map((region) => {
                        const isActive = regions.includes(region.value);
                        const isDisabled = !availableRegions.includes(region.value) && !regions.includes(region.value);
                        return (
                          <Styled.PollFilterRowItem
                            onClick={() => {
                              if (isLoadingAvailableFilters || isDisabled) return;
                              setRegions((prev) =>
                                prev.includes(region.value) ? (prev.filter((it) => it !== region.value) ?? []) : [...prev, region.value]
                              );
                            }}
                            isActive={isActive}
                            isDisabled={isDisabled}
                          >
                            {region.label}
                            <Styled.FilterButtonImage
                              src={isActive ? FilterSelectedIcon : isDisabled ? FilterDisabledIcon : FilterAvailableIcon}
                              alt="icon"
                            />
                          </Styled.PollFilterRowItem>
                        );
                      })}
                    </Styled.PollFilterRow>
                    <Styled.PollFilterLine />
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Category</Styled.PollFilterRowTitle>
                      {CATEGORIES.map((category) => {
                        const isActive = categories.includes(category);
                        const isDisabled = !availableCategories.includes(category) && !categories.includes(category);
                        return (
                          <Styled.PollFilterRowItem
                            id={`${category}-poll-categories`}
                            isActive={isActive}
                            isDisabled={isDisabled}
                            onClick={() => {
                              if (isLoadingAvailableFilters || isDisabled) return;
                              setCategories((prev) =>
                                prev.includes(category) ? (prev.filter((it) => it !== category) ?? []) : [...prev, category]
                              );
                            }}
                          >
                            {category}
                            <Styled.FilterButtonImage
                              src={isActive ? FilterSelectedIcon : isDisabled ? FilterDisabledIcon : FilterAvailableIcon}
                              alt="icon"
                            />
                          </Styled.PollFilterRowItem>
                        );
                      })}
                    </Styled.PollFilterRow>
                  </Styled.PollFilterRowContainer>
                </Styled.PollFilterWrapper>
              </Styled.RightColumn>
            </Styled.ColumnsWrapper>
          </motion.div>
        </AnimatePresence>
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Styled.PollPageWrapper>
  );
};
