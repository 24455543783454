import React, { useEffect, useState } from 'react';
import { getRangeOfYearsStartPoll } from '@/common/components/onboard/helpers';
import * as Styled from './time-period.styled';
import { EPollQuartal } from '@/common/types/poll.types';

interface IProps {
  selectedYear?: number;
  setSelectedYear: (newYear?: number) => void;
  selectedQuartal?: EPollQuartal;
  setSelectedQuartal: (newQuartal?: EPollQuartal) => void;
}

const QUARTALS = ['Q1', 'Q2', 'Q3', 'Q4', 'HY', 'YE'];

export const TimePeriodComponent: React.FC<IProps> = ({ selectedYear, selectedQuartal, setSelectedYear, setSelectedQuartal }) => {
  const [saveYearStart, setSaveYearStart] = useState<number | undefined>(selectedYear || undefined);
  const [saveQuartal, setSaveQuartal] = useState<EPollQuartal | undefined>(selectedQuartal || undefined);

  const yearsStart = getRangeOfYearsStartPoll();

  useEffect(() => {
    if (selectedYear !== saveYearStart) {
      setSelectedYear(saveYearStart);
    } else {
      setSelectedYear(undefined);
    }
  }, [saveYearStart]);

  useEffect(() => {
    if (selectedQuartal !== saveQuartal) {
      setSelectedQuartal(saveQuartal);
    } else {
      setSelectedQuartal(undefined);
    }
  }, [saveQuartal]);

  const checkIsActiveYear = (year: number, saveYear?: number): boolean => {
    if (!saveYear) return false;
    if (saveYear === year) return true;
    return false;
  };

  const checkIsActiveQuartal = (quartal: string, saveQuartal?: string): boolean => {
    if (!saveQuartal) return false;
    if (saveQuartal === quartal) return true;
    return false;
  };

  const selectStartYear = (year: number) => {
    setSaveYearStart((prev) => (prev === year ? undefined : year));
  };

  const selectQuartal = (quartal: EPollQuartal) => {
    setSaveQuartal((prev) => (prev === quartal ? undefined : quartal));
  };

  return (
    <Styled.PublishDateWrapper>
      <Styled.YearRowWrapper>
        {yearsStart.map((item, index) => (
          <Styled.Item
            key={`year-${index}`}
            isActive={checkIsActiveYear(item, saveYearStart)}
            isDisabled={!!saveYearStart && !checkIsActiveYear(item, saveYearStart)}
            onClick={() => selectStartYear(item)}
          >
            {item}
          </Styled.Item>
        ))}
      </Styled.YearRowWrapper>
      <Styled.PublishDateWrapperDot>•</Styled.PublishDateWrapperDot>
      <Styled.YearRowWrapper>
        {QUARTALS.map((item, index) => (
          <Styled.Item
            key={`quartals-${index}`}
            isActive={checkIsActiveQuartal(item, saveQuartal)}
            isDisabled={!!saveQuartal && !checkIsActiveQuartal(item, saveQuartal)}
            onClick={() => selectQuartal(item as EPollQuartal)}
          >
            {item}
          </Styled.Item>
        ))}
      </Styled.YearRowWrapper>
    </Styled.PublishDateWrapper>
  );
};
