/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import PostOption from '@styles/icons/post-option.svg';
import ViewDetailsArrow from '@styles/icons/view-details-debate-arrow.webp';
import ViewDetailsArrowDisabled from '@styles/icons/view-details-debate-arrow-disabled.webp';
import HiddenDebateIcon from '@styles/icons/hidden-debate-icon.webp';
import Image from 'next/image';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import FinalPollIcon from '@styles/icons/final-poll-icon.webp';
import LatestPollIcon from '@styles/icons/latest-poll-icon.webp';
import CloseIcon from '@/styles/icons/cross-in-circle.webp';
import * as Styled from './poll-result.styled';
import { SentimentType } from './poll-result.const';
import { getSentimentType } from '@/common/utils/sentiment';
import { IPollPosts, IPostDebateResult, ISubPost } from '@/common/types/post.type';
import { useAddPollAnswer, useGetPollDetails, useQueryPollSentimentSwings } from '@/common/hooks';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { PollGraphComponent } from '../poll-graph';
import { PollTextComponent } from '../poll-text';
import { FlexComponent } from '@/common/components/flex';
import { PollAnswerInputComponent } from '../poll-answer-input';
import { PollOraclesComponent } from '../poll-oracles/poll-oracles.component';

interface IQuestionResultComponentProps {
  pollId?: string;
  ownerId?: string;
  isCurrentUserPost?: boolean;
  text?: string;
  type?: SentimentType;
  pollResult?: IPostDebateResult;
  ownerFullName?: string;
  isHidden?: boolean;
  isAnon?: boolean;
  isOwner?: boolean;
  userName: string;
  userAnswer: number;
  poll: IPollPosts;
  isQuote?: boolean;
  isFaded?: boolean;
  allowChange?: boolean;
  optionHandler?: () => void;
  answerCallback?: (answer: number) => void;
  className?: string;
  onClickByPostText?: () => void;
  openPostInModal?: () => void;
  updateQuestionInModal?: (post: ISubPost) => void;
  cb?: () => void;
  userProfileId?: string;
  allAnswer: number;
  highlight?: string;
}

export const PollResultComponent = ({
  pollId,
  ownerId,
  text,
  type,
  isCurrentUserPost,
  pollResult,
  allowChange,
  poll,
  ownerFullName,
  isHidden,
  isAnon,
  userName,
  userAnswer,
  isQuote,
  isFaded,
  allAnswer,
  optionHandler,
  className,
  openPostInModal,
  onClickByPostText,
  answerCallback,
  updateQuestionInModal,
  cb,
  userProfileId,
  highlight,
  isOwner
}: IQuestionResultComponentProps) => {
  const { push } = useRouter();
  const [currentAnswer, setCurrentAnswer] = useState<number | null>();
  const { mutateAsync, isLoading, data } = useGetPollDetails();
  const {
    mutateAsync: addQuestionAnswer,
    isLoading: addQuestionAnswerIsLoading,
    isSuccess
  } = useAddPollAnswer(userProfileId, cb, updateQuestionInModal, true);
  const [isClickOnViewDetails, setIsClickOnViewDetails] = useState(false);
  const [isClickedOnChangeEntry, setIsClickedOnChangeEntry] = useState<boolean>(false);
  const [isAnswerSuccess, setIsAnswerSuccess] = useState<boolean>(false);

  const getUserName = () => {
    if (isCurrentUserPost) return 'You';
    return userName?.split(' ')[0];
  };

  const onClickedOnChangeEntry = () => {
    setIsClickedOnChangeEntry((prev) => !prev);
  };

  const onClickByText = () => {
    if (onClickByPostText) {
      onClickByPostText();
    }
  };

  const onClickByViewDetails = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsClickOnViewDetails((it) => !it);
    if (!data && !isLoading && pollId) {
      mutateAsync(pollId);
    }
  };

  const clickConfirmHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newAnswer: number) => {
    try {
      if (isQuote) return;
      event.preventDefault();
      event.stopPropagation();

      if (currentAnswer === undefined) {
        return;
      }
      if (((!!currentAnswer || currentAnswer === 0) && !allowChange) || isLoading) {
        return;
      }
      const newAnswerToSend = newAnswer;
      setCurrentAnswer(newAnswerToSend);
      if (answerCallback) {
        answerCallback(newAnswerToSend);
      } else if (pollId && newAnswerToSend) {
        await addQuestionAnswer({ pollId, answer: newAnswerToSend });
        setIsAnswerSuccess(true);
      }
    } catch {
      setIsAnswerSuccess(false);
    }
  };

  const isFinal = new Date() >= new Date(poll.endAt);
  const isNewAnswerDisabled = isAnswerSuccess || !allowChange;

  const getResultView = () => <PollGraphComponent isFinal={isFinal} highlight={highlight} item={poll} />;
  const getResultViewInQuotePost = () => <PollTextComponent isQuote={isQuote} highlight={highlight} poll={poll} />;

  useEffect(() => {
    if (isSuccess) {
      setIsClickedOnChangeEntry(false);
      setIsAnswerSuccess(true);
    }
  }, [isSuccess]);

  const getDetailsTextView = () => {
    if (!pollResult) return;
    return (
      <>
        <FlexComponent gap="5px" mt="8px">
          <Styled.UsersYouFollowText>Users you follow vs your entry:</Styled.UsersYouFollowText>
          <Styled.DetailsViewProcentage>
            <Styled.DetailsViewMoreNegativeProcentage>{pollResult.moreNegative}% are lower</Styled.DetailsViewMoreNegativeProcentage>
            <Styled.DetailsViewDot>•</Styled.DetailsViewDot>{' '}
            <Styled.DetailsViewAlignmentText>{pollResult.aligned}% are in line</Styled.DetailsViewAlignmentText>{' '}
            <Styled.DetailsViewDot>•</Styled.DetailsViewDot>{' '}
            <Styled.DetailsViewMorePositiveProcentage>{pollResult.morePositive}% are higher</Styled.DetailsViewMorePositiveProcentage>
          </Styled.DetailsViewProcentage>
        </FlexComponent>
        {!isFinal && (
          <Styled.DetailsViewRowWrapper>
            <button type="button" disabled={isNewAnswerDisabled} onClick={onClickedOnChangeEntry}>
              <Image
                src={isNewAnswerDisabled ? ViewDetailsArrowDisabled : ViewDetailsArrow}
                alt="View Details Arrow"
                width={6}
                height={6}
              />
              change your entry (only possible 30 days after your previous entry)
            </button>
            {isClickedOnChangeEntry && (
              <Styled.CancelButton onClick={onClickedOnChangeEntry}>
                cancel <Styled.CancelButtonIcon src={CloseIcon} alt="View Details Arrow" />
              </Styled.CancelButton>
            )}
          </Styled.DetailsViewRowWrapper>
        )}
      </>
    );
  };

  const onClickByCreator = () => {
    if (!ownerId) return;
    push(`${ROUTER_KEYS.PROFILE}?id=${ownerId}`);
  };

  return (
    <Styled.QuestionResultWrapper className={className}>
      {!!text && !!ownerFullName && (
        <Styled.ResultTitleWrapper isQuote={isQuote} isClicable={!!ownerId}>
          {!isQuote && (
            <Styled.PollIcon>
              <Image src={isFinal ? FinalPollIcon : LatestPollIcon} alt="Icon" width={isFinal ? 20 : 22} height={isFinal ? 20 : 22} />
            </Styled.PollIcon>
          )}
          {isFinal ? 'Poll Final' : 'Poll Latest'}
          {!isQuote && (
            <>
              <Styled.ConsensusDot>•</Styled.ConsensusDot> Villan consensus over time
            </>
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClickByCreator();
            }}
            type="button"
          >
            created by {ownerFullName}
          </button>
        </Styled.ResultTitleWrapper>
      )}

      {!!optionHandler && <Styled.OptionIcon src={PostOption} alt="option" />}
      {isHidden && (
        <>
          {getResultViewInQuotePost()}
          <Styled.HiddenAnswerBlockWrapper>
            <AnimatePresence>
              <Styled.DebateResultWrapper
                transition={{ ease: 'backInOut', delay: 2, duration: 1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {getDetailsTextView()}
              </Styled.DebateResultWrapper>
            </AnimatePresence>
            <Styled.HiddenAnswerBlock>
              <Image src={HiddenDebateIcon} alt="Hidden Debate Icon" width={14} height={14} />
              <p>Poll results only available to those who contributed</p>
            </Styled.HiddenAnswerBlock>
          </Styled.HiddenAnswerBlockWrapper>
        </>
      )}
      {!isHidden && !isQuote && getResultView()}

      {isQuote && getResultViewInQuotePost()}

      {!!text && pollResult && !isHidden && !isQuote && (
        <>
          {getDetailsTextView()}
          {isClickedOnChangeEntry && (
            <PollAnswerInputComponent
              answer={currentAnswer!}
              setAnswer={setCurrentAnswer}
              clickConfirmHandler={clickConfirmHandler}
              unit={poll.unit}
              mt="12px"
              isDisabled={addQuestionAnswerIsLoading}
            />
          )}
          {isFinal && <PollOraclesComponent items={poll?.oracles} othersValue={poll?.oraclesCount} />}
        </>
      )}
    </Styled.QuestionResultWrapper>
  );
};
