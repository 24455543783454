import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useTreadByPostId } from './use-post';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { setPostStatistics } from '../utils/post-statistics';
import { IAddPollAnswerPayload, IPollFilterPayload, IPollPayload, IPollProfileResponse } from '../types/poll.types';
import { pollService, postService } from '../services';
import { PAGE_LIMIT } from '../consts/poll.conts';
import { IPost, ISubPost } from '../types/post.type';

export const useCreatePoll = () => {
  const queryClient = useQueryClient();
  // const { setNewPosts } = useAllProfilePosts();
  // const { setNewPosts: setNewHomePostsFollowing } = useAllHomePosts(false);
  // const { setNewPosts: setNewHomePosts } = useAllHomePosts(true);

  return useMutation({
    mutationKey: [QUERY_KEYS.POLL_QUERIES],
    mutationFn: (data: IPollPayload) => pollService.createQuestion(data),
    onSuccess: (data) => {
      if (!data) return;

      // setNewPosts([data], data.profileId, true);
      // setNewHomePostsFollowing([data], data.profileId, true);
      // setNewHomePosts([data], data.profileId, true);
      setPostStatistics(data, queryClient);
      queryClient.invalidateQueries([QUERY_KEYS.POLL_SENTIMENT_SWINGS]);

      if (data.question && data.question.ownerProfileId) {
        const sentimentsData = queryClient.getQueryData<IPollProfileResponse>([
          QUERY_KEYS.POLL_QUERIES,
          'sentimentProfile',
          data.question.ownerProfileId
        ]);
        if (!sentimentsData) return;
        const newSentimentData = {
          ...sentimentsData,
          awaitingForResult: [
            ...(sentimentsData.awaitingForResult ?? []),
            {
              id: data.question.id,
              user: data.question.user,
              text: data.question.text,
              postId: data.id,
              post: { ...data },
              postProfileId: data.profileId,
              averageUsers: data.question.averageAnswer,
              answer: data.question.ownAnswer,
              isResult: data.question.isResult,
              type: data.question.type
            }
          ]
        };
        queryClient.setQueryData([QUERY_KEYS.POLL_QUERIES, 'sentimentProfile', data.question.ownerProfileId], newSentimentData);
      }
    }
  });
};

export const useAddPollAnswer = (
  userProfileId?: string,
  cb?: () => void,
  updateQuestionInModal?: (post: ISubPost) => void,
  isExecuteCb: boolean = false
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS.QUESTION_QUERIES],
    mutationFn: (data: IAddPollAnswerPayload) => pollService.addQueryAnswer(data),
    onSuccess: (data) => {
      if (cb !== undefined && isExecuteCb) {
        cb();
      }

      if (!data || !data.post) return;
      setPostStatistics(data.post, queryClient);
      queryClient.invalidateQueries([QUERY_KEYS.ADORATIONS]);
      queryClient.invalidateQueries(QUERY_KEYS.POLL_SENTIMENT_SWINGS);
    }
  });
};

export const useQueryPollSentimentSwings = ({
  search,
  periods,
  regions,
  sectors,
  categories
}: Omit<IPollFilterPayload, 'limit' | 'page'>) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isRefetching } = useInfiniteQuery(
    [QUERY_KEYS.POLL_SENTIMENT_SWINGS, search, periods, regions, sectors, categories],
    ({ pageParam = 0 }) =>
      pollService.getSentimentSwings({ page: pageParam + 1, limit: PAGE_LIMIT, search, periods, regions, sectors, categories }),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length === lastPage?.maxPage) return;
        return allPages.length;
      }
    }
  );

  const { setTread } = useTreadByPostId();
  const queryClient = useQueryClient();
  const polls = data?.pages.reduce((arr, curr) => arr.concat(curr?.polls ?? ([] as IPost[])), [] as IPost[]);

  polls?.forEach((post) => {
    setTread(post.tread, post.id);
    post.tread.replies.forEach((reply) => {
      setPostStatistics(reply, queryClient);
    });
    setPostStatistics(post, queryClient);
    if (post.post) {
      setPostStatistics(post.post, queryClient);
    }
    if (post.originalPost) {
      setPostStatistics(post.originalPost, queryClient);
    }
  });

  const isPageRefetching = isRefetching && !isFetchingNextPage;

  return {
    polls: polls ?? [],
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    refetch,
    isRefetching: isPageRefetching
  };
};

export const useGetPollDetails = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.GET_DEBATE_DETAILS],
    mutationFn: (questionId: string) => postService.getPollDetails(questionId)
  });

export const useGetAvailablePollFilter = ({ search, periods, regions, sectors, categories }: Omit<IPollFilterPayload, 'limit' | 'page'>) =>
  useQuery({
    queryKey: [QUERY_KEYS.GET_AVAILABLE_POLL_FILTER, search, periods, regions, sectors, categories],
    queryFn: () => pollService.getAvailableFilters({ search, periods, regions, sectors, categories }),
    staleTime: 2000
  });

export const useGetAllPollFilter = () =>
  useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_POLL_FILTER],
    queryFn: () => pollService.getAllFilters()
  });

export const useGetPreselectedFilters = () =>
  useQuery({
    queryKey: [QUERY_KEYS.GET_PRESELECTED_POLL_FILTER],
    queryFn: () => pollService.getPreselectedFilters()
  });
