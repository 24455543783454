import NotificationNotActive from '@styles/icons/notification-not-active.svg';
import NotificationActive from '@styles/icons/notification.webp';
import NotificationActiveAnon from '@styles/icons/notification-anon-active.webp';
import PollsNotActive from '@styles/icons/polls-sidebar-icon.svg';
import PollsActiveAnon from '@styles/icons/polls-active-anon.webp';
import HomeNotActive from '@styles/icons/home-not-active.svg';
import Home from '@styles/icons/home.webp';
import HomeAnon from '@styles/icons/home-anon.webp';
import UserNotActive from '@styles/icons/profile-not-active.svg';
import User from '@styles/icons/user.webp';
import UserAnon from '@styles/icons/user-anon.webp';
import DirectMessagesNotActive from '@styles/icons/inbox-not-active.svg';
import DirectMessagesActive from '@styles/icons/direct-message-active.webp';
import DirectMessagesActiveAnon from '@styles/icons/direct-message-active-anon.webp';
import SearchNotActive from '@styles/icons/search-not-active.svg';
import SearchActive from '@styles/icons/search-active.webp';
import SearchActiveAnon from '@styles/icons/search-active-anon.webp';
import Feather from '@styles/icons/feather-not-active.svg';
import FeatherActive from '@styles/icons/featherActive.webp';
import FeatherActiveAnon from '@styles/icons/featherActiveAnon.webp';
import PollsActive from '@styles/icons/polls-sidebar-active-icon.webp';
import { StaticImageData } from 'next/image';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

export interface ISidebarListItem {
  value: StaticImageData;
  valueActive: StaticImageData;
  valueAnon: StaticImageData;
  valueActiveAnon: StaticImageData;
  name: string;
  link: string;
  marginBottom?: string;
}

export const sidebarList: ISidebarListItem[] = [
  {
    value: HomeNotActive,
    valueActive: Home,
    valueAnon: HomeNotActive,
    valueActiveAnon: Home,
    name: 'Home',
    link: `${ROUTER_KEYS.HOME}`
  },
  {
    value: Feather,
    valueActive: FeatherActive,
    valueAnon: Feather,
    valueActiveAnon: FeatherActive,
    name: 'Ideas',
    link: `${ROUTER_KEYS.MEMO}`
  },
  {
    value: PollsNotActive,
    valueActive: PollsActive,
    valueAnon: PollsNotActive,
    valueActiveAnon: PollsActive,
    name: 'Polls',
    link: `${ROUTER_KEYS.POLLS}`,
    marginBottom: '40px'
  },
  {
    value: SearchNotActive,
    valueActive: SearchActive,
    valueAnon: SearchNotActive,
    valueActiveAnon: SearchActive,
    name: 'Search',
    link: ''
  },
  {
    value: DirectMessagesNotActive,
    valueActive: DirectMessagesActive,
    valueAnon: DirectMessagesNotActive,
    valueActiveAnon: DirectMessagesActive,
    name: 'Inbox',
    link: `${ROUTER_KEYS.MESSAGES}`
  },
  {
    value: NotificationNotActive,
    valueActive: NotificationActive,
    valueAnon: NotificationNotActive,
    valueActiveAnon: NotificationActive,
    name: 'Notific.',
    link: `${ROUTER_KEYS.NOTIFICATIONS}`
  },
  {
    value: UserNotActive,
    valueActive: User,
    valueAnon: UserNotActive,
    valueActiveAnon: User,
    name: 'Profile',
    link: `${ROUTER_KEYS.PROFILE}`
  }
];
