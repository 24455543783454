import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Styled from './anonymity.form.styled';
import { useCheckReservetion, useCreateSimplifiedAnonUser } from '@/common/hooks';
import { ActionType, FormType, IReservetionProps } from '@/common/types/anon.types';
import { toEveryoneFromSchema } from './schemas/anonymity-forms-validate-shemas';
import { Stepper } from '@/common/components/onboard-new/components/stepper';
import { ConfirmFormActions } from '../../../ui/confirm-form-actions/confirm-form-actions';
import { VerticalRoleModalComponent } from '@/common/components/onboard-new/components/vertical-role-modal';
import { Loader } from '@/common/components/loader';
import { ModalWrapper } from '../../modal-default.styled';
import { ActivateAnonInformationBlockComponent } from '../../../information-blocks/anon-information-block/anon-information-block.component';
import { ReservedUsernameInputComponent } from '../../../reserved-username-input/reserved-username-input.component';

interface IFormData {
  username: string;
}

interface IErrors {
  username?: string;
}

export const AnonymityToEveryoneForm = ({
  setIsFormValid,
  setIsUsernameReserved,
  setIsUsernameConfirmed,
  setUsernameToCheck,
  isUsernameConfirmed,
  isUsernameReserved,
  linkClicked,
  formType,
  resetLinkClicked,
  handleLinkClick,
  isFormValid,
  usernameToCheck,
  onClose,
  onBack
}: {
  isFormValid: boolean;
  onBack: any;
  usernameToCheck: string;
  isUsernameReserved: boolean;
  isUsernameConfirmed: boolean;
  handleLinkClick: any;
  onClose: any;
  formType: FormType;
  setIsFormValid: (valid: boolean) => void;
  linkClicked?: boolean;
  resetLinkClicked?: () => void;
} & IReservetionProps) => {
  const [formData, setFormData] = useState<IFormData>({
    username: ''
  });

  const defaultEmployer: any = {
    companies: [],
    createdAt: '',
    description: '',
    employer: '',
    endDate: '',
    id: '',
    isCompaniesSuccess: false,
    isDescriptionFilled: false,
    isDescriptionSuccess: false,
    isLocationSuccess: false,
    isMain: true,
    isPrevious: '',
    isPulled: false,
    isRegionSuccess: false,
    isSectorSuccess: false,
    isStartDateSuccess: false,
    isSubSectorSuccess: false,
    isVerticalSuccess: false,
    location: '',
    locationId: '',
    locationRegion: '',
    locationRegionId: '',
    profileId: '',
    regions: [],
    role: '',
    sectors: [],
    startDate: '',
    subSector: '',
    subSectorId: '',
    subSectors: [],
    updatedAt: '',
    vertical: ''
  };

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<IErrors>({});
  const [activeStep, setActiveStep] = useState(1);
  const [employer, setEmployer] = useState(defaultEmployer);
  const [isSelectorSelector, setIsSectorSelected] = useState<boolean>(false);
  const [current, setIsCurrent] = useState<boolean>(false);
  const [isRoleSelected, setIsRoleSelected] = useState<boolean>(false);
  const [isIndustry, setIsIndustry] = useState<boolean>(false);
  const [isCableTelecoms, setIsCableTelecoms] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); 
  const [createAnonVertical, setCreateAnonVertical] = useState<string>('');
  const [subSectorId, setSubSectorId] = useState<string | null>(null);

  const { mutateAsync: createSimplifiedAnon } = useCreateSimplifiedAnonUser();

  const goToAccountCreate = () => {
    setLoading(true); 
    createSimplifiedAnon({ username: formData.username, vertical: createAnonVertical, subSectorId })
      .then(() => {
          onClose();  
          setLoading(false);
      })
  };
  
  useEffect(() => {
    if (linkClicked) {
      setFormData({ username: '' });
      if (resetLinkClicked) resetLinkClicked();
      setIsFormValid(false);
      if (usernameInputRef.current) {
        usernameInputRef.current.focus();
      }
    }
  }, [linkClicked, resetLinkClicked]);

  useCheckReservetion({
    setIsUsernameReserved,
    setIsUsernameConfirmed,
    setUsernameToCheck,
    username: formData.username,
    usernameError: errors.username
  });

  const validateForm = (updatedFormData: IFormData) => {
    const { error } = toEveryoneFromSchema.validate(updatedFormData, { abortEarly: false });

    if (error) {
      const fieldErrors: IErrors = {};
      error.details.forEach((detail) => {
        fieldErrors[detail.context?.key as keyof IFormData] = detail.message;
      });
      setErrors(fieldErrors);
      setIsFormValid(false);
    } else {
      setErrors({});
      setActiveStep(activeStep + 1);
       setIsFormValid(updatedFormData.username !== '' && isIndustry);
    }
  };

  useEffect(() => {
    if (formData.username !== '' && formData.username.length >= 5 ) {
      validateForm(formData);
    }
  }, [formData, isIndustry]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    if (name === 'username') {
      setUsernameToCheck(value);
      setIsUsernameConfirmed(false);
    }
  
    const updatedFormData = {
      ...formData,
      [name]: value
    };
    setFormData(updatedFormData);
    if (name === 'username') {
      validateForm(updatedFormData);
    }
  };
  

  const steps = [
    {
      value: 1,
      lineHeight: '75px'
    },
    {
      value: '✓'
    }
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ModalWrapper formType={formType}>
          <ActivateAnonInformationBlockComponent everyone={formType} handleClose={onClose} />
          <Styled.ModalDivider />
          <Styled.EveryoneFormContainer>
            <Stepper steps={steps} step={activeStep} />
            <Styled.AnonymityForm>
              <Styled.AnonymityDataSection>
                <Styled.AnonymityDataTitle>
                  Username
                  <Styled.AnonymityDataSubtitles>
                    <Styled.AnonymityDataSubtitle>5-25 characters</Styled.AnonymityDataSubtitle>
                    <Styled.AnonymityDataSubtitle>Use numbers, letters, dashes, but no spaces</Styled.AnonymityDataSubtitle>
                  </Styled.AnonymityDataSubtitles>
                </Styled.AnonymityDataTitle>
                <Styled.AnonymityValidateInput>
                  <Styled.AnonymityDataInput
                    ref={usernameInputRef}
                    value={formData.username}
                    name="username"
                    onChange={handleChange}
                    type="text"
                    placeholder="Type here ..."
                  />
                  {errors.username && <Styled.ErrorMessage>{errors.username}</Styled.ErrorMessage>}
                </Styled.AnonymityValidateInput>
              </Styled.AnonymityDataSection>
              <Styled.AnonymityDataIndustrySection>
                <Styled.AnonymityDataTitle>Select your industry</Styled.AnonymityDataTitle>
                <Styled.IndustryDataSection isCableTelecoms={isCableTelecoms}>
                  <VerticalRoleModalComponent
                    employer={employer}
                    isAnon={false}
                    setIsCableTelecoms={setIsCableTelecoms}
                    isCreateAnonVertical
                    setEmployer={setEmployer}
                    setIsCurrent={setIsCurrent}
                    setCreateAnonVertical={setCreateAnonVertical}
                    setSubSectorId={setSubSectorId}
                    setIsSectorSelected={setIsSectorSelected}
                    setIsRoleSelected={setIsRoleSelected}
                    //setActiveCallback={setActiveCallback}
                    setIsIndustry={setIsIndustry}
                    //activeCallback={goToAccountCreate}
                  />
                </Styled.IndustryDataSection>
              </Styled.AnonymityDataIndustrySection>
              {isUsernameReserved && !isUsernameConfirmed && (
                <ReservedUsernameInputComponent
                  username={usernameToCheck}
                  setIsUsernameConfirmed={setIsUsernameConfirmed}
                  onResetUsername={handleLinkClick}
                />
              )}
              <ConfirmFormActions 
              isActive={isFormValid && isUsernameConfirmed} 
              activeCallback={goToAccountCreate}
              onBack={onBack}
              actionType={ActionType.CONFIRM}
              />
            </Styled.AnonymityForm>
          </Styled.EveryoneFormContainer>
        </ModalWrapper>
      )}
    </>
  );
};