import React from 'react';
import { useRouter } from 'next/router';
import LockIcon from '@styles/icons/lock-icon-test.webp';
import LockHoveredIcon from '@styles/icons/lock-icon-hovered.webp';
import Image from 'next/image';
import * as Styled from './new-post-basic-info.styled';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { findOutputVertical } from '@/common/utils/vertical-output';
import { DateTimeOutput } from '../date';
import { ISubPost } from '@/common/types/post.type';
import { InsightBasicInfoComponent } from './insight-basic-info/insight-basic-info.component';
import AnonMemoIcon from '@/styles/icons/anon-memo-icon.png';
import LockedMemberIcon from '@/styles/icons/locked-member-icon.png';

interface IProp {
  isAnon?: boolean;
  isTrending?: boolean;
  imageUrl?: string;
  profileId?: string;
  fullName: string;
  vertical: string;
  role: string;
  isInsight?: boolean;
  isImageHidden?: boolean;
  isHidden?: boolean;
  isQuote?: boolean;
  isLatestCoverage?: boolean;
  date?: Date;
  isFaded?: boolean;
  isOriginalPostFaded?: boolean;
  isInCoverageLatest?: boolean;
  className?: string;
  deletedAt?: Date;
  isNotification?: boolean;
  closeModal?: () => void;
  post?: ISubPost;
  typePost?: string;
  isMemoAnon?: boolean;
  isMemoAnonInModal?: boolean;
}

export const NewPostBasicInfoComponent = (props: IProp) => {
  const {
    isInsight,
    isAnon,
    isMemoAnonInModal,
    isTrending,
    imageUrl,
    isNotification,
    typePost,
    profileId,
    fullName,
    vertical,
    role,
    post,
    isImageHidden,
    isHidden,
    isQuote,
    isLatestCoverage,
    date,
    isFaded,
    isOriginalPostFaded,
    isInCoverageLatest,
    className,
    deletedAt,
    isMemoAnon,
    closeModal
  } = props;
  const { push } = useRouter();
  const onClickBy = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isTrending) return;
    if (profileId && !isHidden && !isQuote) {
      event?.stopPropagation();
      push(`${ROUTER_KEYS.PROFILE}?id=${profileId}`);
      if (closeModal) closeModal();
    }
  };
  const [hoverPosition, setHoverPosition] = React.useState<'top' | 'right'>('right');
  React.useEffect(() => {
    const text = `${findOutputVertical(vertical)} ${role} `;
    const textLength = text.replace(/\s/g, '').length;
    const fullNameWidth = fullName ? fullName.replace(/\s/g, '').length * 8 : 0;
    const maxWidth = textLength + fullNameWidth;

    if (maxWidth > 160) {
      setHoverPosition('top');
    } else {
      setHoverPosition('right');
    }
  }, [fullName, vertical, role]);

  const isInsightPost = post?.insight || isInsight;
  if (isInsightPost) {
    return <InsightBasicInfoComponent onClickBy={onClickBy} {...props} hoverPosition={hoverPosition} />;
  }

  return (
    <Styled.Wrapper
      onClick={!isMemoAnon ? onClickBy : undefined}
      isHidden={isHidden}
      isLatestCoverage={isLatestCoverage}
      className={className}
    >
      {!isQuote && !isMemoAnon! && (
        <Styled.ImageWrapper
          typePost={typePost}
          onClick={onClickBy}
          isReply={false}
          imageUrl={imageUrl}
          profileId={undefined}
          borderRadius={isLatestCoverage ? '20px' : '30px'}
          isImageHidden={isImageHidden}
          isReplyHidden={isHidden}
          isFaded={isOriginalPostFaded}
          isGrey={isFaded}
          isNotification={isNotification}
          isLatestCoverage={isLatestCoverage}
          isTreanding={isTrending}
        />
      )}

      {post?.profile.fullName === 'Locked Member' && !isQuote  ? (
        <Styled.LockedMemberIcon src={LockedMemberIcon} alt="locked-member-icon" />
      ) : isMemoAnon  && !isQuote ? (
        <Styled.AnonMemoIcon src={AnonMemoIcon} alt="anon-memo-icon" />
      ) : null}

      <Styled.InfoWrapper typePost={typePost} isQuote={isQuote} isInCoverageLatest={isInCoverageLatest}>
        {post?.profile.fullName === 'Locked Member' ? (
          <Styled.AnonymousTitle>Locked Member</Styled.AnonymousTitle>
        ) : isMemoAnon ? (
          <Styled.AnonymousTitle>{isQuote ? "quoting Anonymous" : "Anonymous"}</Styled.AnonymousTitle>
        ) : (
          <>
            <Styled.FullNameWrapper isTrending={!!isTrending} isAnon={!!isAnon} isQuote={isQuote} isFaded={isFaded || isOriginalPostFaded}>
              {isQuote && !deletedAt && 'quoting '}
              {fullName}
            </Styled.FullNameWrapper>
            {!isQuote && !isHidden && !isMemoAnonInModal && (
              <Styled.DetaileInfo
                isTrending={!!isTrending}
                isFaded={isFaded || isOriginalPostFaded}
                fullNameWidth={fullName ? fullName.length * 8 : 0}
              >
                {findOutputVertical(vertical)} {role}
              </Styled.DetaileInfo>
            )}
          </>
        )}

        {post && post.isPrivate && (
          <Styled.LockHoverContainer>
            <Styled.LockIconContainer>
              <Styled.LockIconStyled src={LockIcon} alt="lock-icon" width={16} height={12} />
            </Styled.LockIconContainer>
            <Styled.HoverContent hoverPosition={hoverPosition}>
              <Image src={LockHoveredIcon} width={16} height={16} alt="hovered-lock-icon" />
              <Styled.StyledIconHoverText>Only visible to you</Styled.StyledIconHoverText>
            </Styled.HoverContent>
          </Styled.LockHoverContainer>
        )}
        {isQuote && !!date && <DateTimeOutput isQuote={isQuote} date={date} />}
      </Styled.InfoWrapper>
    </Styled.Wrapper>
  );
};
