import React, { useState, ReactElement } from 'react';
import { Stack } from '@mui/material';
import * as Styled from './post-insight.styled';
import { IInsightFeed, sourceType } from '@/common/types/insight.types';
import { FlexComponent } from '@/common/components/flex';
import { TagListComponent } from '../post-basic-info/insight-basic-info/tag-list/tag-list.component';
import arrow from '@/styles/icons/arrow-top-right.svg';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IProps extends IInsightFeed {
  isQuotePost?: boolean;
  isNotification?: boolean;
}

export const PostInsightComponent = ({
  title,
  securities,
  id,
  isRssInsight,
  link,
  isFile,
  isNotification,
  source
}: IProps): ReactElement => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleLinkClick = (): void => {
    if (isFile) {
      const fileUrl = `${ROUTER_KEYS.PDF_VIEW}?file=${link}`;
      window.open(fileUrl, '_blank');
    } else {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };

  const rssLinkText = source?.sourceType === sourceType.NEWSLETTER ? 'read newsletter' : 'full transcript';
  const postTitle = isFile ? title?.replace(/^[\d.]+-/, '') : title;

  if (isNotification) {
    return (
      <Stack gap="8px">
        <FlexComponent alignItems="baseline" gap="6px">
          <Styled.NotificationFundTitle>Fund Materials</Styled.NotificationFundTitle>
          <Styled.Username>shared by you</Styled.Username>
        </FlexComponent>
        <Styled.NotificationTitle>{postTitle}</Styled.NotificationTitle>
        {securities?.length === 0 ? (
          <Styled.EmptySecurity>no companies found</Styled.EmptySecurity>
        ) : (
          <TagListComponent isOpen={showAll} toggleShowAll={() => setShowAll(!showAll)} tagsList={securities as unknown as string[]} />
        )}
      </Stack>
    );
  }

  return (
    <Styled.Header>
      <FlexComponent>
        <Styled.Title>{isRssInsight ? title : postTitle}</Styled.Title>
      </FlexComponent>
      <FlexComponent gap="2px" justifyContent="space-between">
        {securities?.length === 0 ? (
          <Styled.EmptySecurity>no companies found</Styled.EmptySecurity>
        ) : (
          <TagListComponent isOpen={showAll} toggleShowAll={() => setShowAll(!showAll)} tagsList={securities as unknown as string[]} />
        )}
        <Styled.Link onClick={handleLinkClick}>
          <Styled.Arrow src={arrow.src} alt="arrow-top" />
          {isRssInsight ? rssLinkText : 'read fund letter'}
        </Styled.Link>
      </FlexComponent>
    </Styled.Header>
  );
};
