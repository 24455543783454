import Image from 'next/image';
import React, { useEffect } from 'react';
import * as Styled from './post-form-action.styled';
import Checkmark from '@/styles/icons/ok-checkmark.svg';
import PressEnterArrow from '@/styles/icons/press-enter-arrow-disabled.webp';
import { useDetectOS } from '@/common/hooks';

interface IPostFormActions {
  isActive: boolean;
  activeCallback: () => void;
  title?: string;
  mt?: number;
  isDisabled?: boolean;
  minW?: number;
}

export const PostFormActions = ({ isActive, minW, isDisabled, title = 'post', activeCallback, mt }: IPostFormActions) => {
  const os = useDetectOS();
  const isWindows = os === 'Windows';
  const key = isWindows ? 'ctrl' : 'cmd';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isActive && event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !isDisabled) {
        event.preventDefault();
        activeCallback();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive, activeCallback]);

  return (
    <Styled.PostFormConfirmBtn minW={minW} disabled={isDisabled} mt={mt} isActive={isActive} onClick={activeCallback}>
      <Styled.PostPostBtnContent>
        {title}
        <Styled.CheckIcon src={Checkmark} width={12} height={12} alt="checkmark" />
      </Styled.PostPostBtnContent>
      <Styled.PostPressEnter>
        press {key} + enter
        <Styled.ArrowIcon>
          <Image width={14} height={14} src={PressEnterArrow} alt="arrow" />
        </Styled.ArrowIcon>
      </Styled.PostPressEnter>
    </Styled.PostFormConfirmBtn>
  );
};
