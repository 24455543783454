/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Image from 'next/image';
import { QUERY_KEYS } from '@/common/consts/app-keys.const';
import { coverageService } from '@/common/services';
import { useSubSectorsBySector, useUpdateSetupStep } from '@/common/hooks';
import DropdownArrow from '@/styles/icons/dropdown-arrow.webp';
import { IEmployer } from '@/common/types/current-employer.type';
import {
  nonFinanceOrder,
  nonFinanceSubSectorShortName,
  OTHER_ROLE,
  RolesArrays,
  RoleToShortened,
  VerticalArrays,
  VerticalToShortened
} from './vertical-role-modal.const';
import * as Styled from './vertical-role-modal.styled';
import { ISector } from '@/common/types/coverage.type';
import PressEnterArrow from '@/styles/icons/press-enter-arrow.svg';
import PressEnterArrow10Opacity from '@/styles/icons/press-enter-arrow-disabled-10.svg';
import JoinVillanArrow from '@/styles/icons/join-villan-arrow.svg';
import { ICurrentStatus } from '@/common/types/profile.type';

export const VerticalRoleModalComponent = ({
  employer,
  setEmployer,
  isCurrent,
  onboardStep,
  setIsCurrent,
  isAnon,
  setIsSectorSelected,
  setIsRoleSelected,
  setCurrentStep,
  isCreateAnonVertical,
  setIsIndustry,
  setCreateAnonVertical,
  setSubSectorId,
  setActiveCallback,
  activeCallback,
  setIsCableTelecoms,
  insertAnswers
}: {
  setCreateAnonVertical?: any;
  setSubSectorId?: any;
  isCurrent?: boolean;
  activeCallback?: any;
  setIsCableTelecoms?: any;
  setActiveCallback?: any;
  setCurrentStep?: (step: number, currentStatusInfo?: Partial<IEmployer>) => void;
  employer: Partial<IEmployer>;
  setEmployer: (employer: Partial<IEmployer>) => void;
  setIsCurrent: React.Dispatch<React.SetStateAction<boolean>>;
  onboardStep?: number;
  isAnon?: boolean;
  setIsIndustry?: any;
  isCreateAnonVertical?: any;
  setIsSectorSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRoleSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  insertAnswers?: any;
}) => {
  const groupN = VerticalArrays.find((item) => employer?.vertical?.startsWith(item.name))?.name;
  const [group, setGroup] = useState<string | null>(groupN ?? null);
  const secutNonFinInput = useRef<null | HTMLInputElement>(null);
  const verticals = useMemo(() => VerticalArrays.find((item) => item.name === group)?.verticals, [group]);
  const [vertical, setVertical] = useState<string | null>(
    (verticals && [...verticals]?.sort((a, b) => b.length - a.length)?.find((item) => employer?.vertical?.endsWith(item))) ?? null
  );
  const [sector, setSector] = useState<string | null>(employer?.subSector ? (employer.subSector.sectorId ?? null) : null);
  const [sectorName, setSectorName] = useState<string>('');
  const [subSector, setSubSector] = useState<string | null>(employer?.subSectorId ? employer.subSectorId : null);
  const [role, setRole] = useState<string | null>(employer?.role ?? null);
  const [showGroup, setShowGroup] = useState<boolean>(false);
  const [showVertical, setShowVertical] = useState<boolean>(false);
  const [showRoles, setShowRoles] = useState<boolean>(false);
  const [showGroupOther, setShowGroupOther] = useState<boolean>(false);
  const [showVerticalOther, setShowVerticalOther] = useState<boolean>(false);
  const [showRolesOther, setShowRolesOther] = useState<boolean>(false);

  const groupDropdown = useRef<HTMLDivElement>(null);
  const groupOtherDropdown = useRef<HTMLDivElement>(null);
  const verticalDropdown = useRef<HTMLDivElement>(null);
  const verticalOtherDropdown = useRef<HTMLDivElement>(null);
  const roleDropdown = useRef<HTMLDivElement>(null);
  const roleOtherDropdown = useRef<HTMLDivElement>(null);
  const roleNonFinanceDropdown = useRef<HTMLDivElement>(null);
  const verticalNonFinanceDropdown = useRef<HTMLDivElement>(null);

  const dropdownRefs = useMemo(
    () => [
      groupDropdown,
      groupOtherDropdown,
      verticalDropdown,
      verticalOtherDropdown,
      roleDropdown,
      roleOtherDropdown,
      roleNonFinanceDropdown,
      verticalNonFinanceDropdown
    ],
    [
      groupDropdown,
      groupOtherDropdown,
      verticalDropdown,
      verticalOtherDropdown,
      roleDropdown,
      roleOtherDropdown,
      roleNonFinanceDropdown,
      verticalNonFinanceDropdown
    ]
  );

  const [dropdownIsShow, setDropdownIsShow] = useState(false);

  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDropdownIsShow(showGroup || showVertical || showRoles || showGroupOther || showGroupOther || showVerticalOther || showRolesOther);
    }, 100);

    return () => clearTimeout(timer);
  }, [showGroup || showVertical || showRoles || showGroupOther || showGroupOther || showVerticalOther || showRolesOther]);

  const hideAllDropdowns = () => {
    setShowGroup(false);
    setShowVertical(false);
    setShowRoles(false);
    setShowGroupOther(false);
    setShowVerticalOther(false);
    setShowRolesOther(false);
  };

  useEffect(() => {
    if (setCreateAnonVertical) {
      setCreateAnonVertical(vertical ? `${group} ${vertical}` : (group ?? ''));
    }
    if (setSubSectorId) {
      setSubSectorId(subSector);
    }

    if (setActiveCallback) {
      setActiveCallback(() => () => activeCallback(vertical ? `${group} ${vertical}` : (group ?? ''), subSector));
    }

    if (setIsIndustry) {
      if (group !== 'Non-Finance') {
        setIsIndustry(!!(group && vertical));
      } else {
        setIsIndustry(!!(group && sector && subSector));
      }
    }
  }, [group, vertical, sector, subSector, setIsIndustry, setActiveCallback]);

  const switchShowGroup = useCallback(() => {
    hideAllDropdowns();
    setShowGroup(!showGroup);
  }, [showGroup]);

  const switchShowVertical = useCallback(() => {
    hideAllDropdowns();
    setShowVertical(!showVertical);
  }, [showVertical]);

  const switchShowRoles = useCallback(() => {
    hideAllDropdowns();
    setShowRoles(!showRoles);
  }, [showRoles]);

  const switchShowGroupOther = useCallback(() => {
    hideAllDropdowns();
    setShowGroupOther(!showGroupOther);
  }, [showGroupOther]);

  const switchShowVerticalOther = useCallback(() => {
    hideAllDropdowns();
    setShowVerticalOther(!showVerticalOther);
  }, [showVerticalOther]);

  const switchShowRolesOther = useCallback(() => {
    hideAllDropdowns();
    setShowRolesOther(!showRolesOther);
  }, [showRolesOther]);

  // useEffect(() => {
  //   if (vertical) {
  //     const r = RolesArrays.find((item) => item.relation.includes(`${group} ${vertical}`))?.roles;
  //     if (!r?.includes(employer?.role!) || !employer?.role) {
  //       setRole(OTHER_ROLE);
  //       setEmployer({ ...employer, vertical: `${group} ${vertical}`, role: OTHER_ROLE, subSectorId: undefined });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (employer?.role) {
      setIsRoleSelected!(true);
    }
    if (employer?.vertical?.split(' ')[1]) {
      setIsSectorSelected!(true);
    }
  }, []);

  const onClickByGroup = (groupName: string) => {
    const prevGroup = group;
    setGroup((prev) => (groupName === prev ? null : groupName));
    if (
      groupName !== prevGroup &&
      groupName === 'Non-Finance' &&
      !!employer?.isLocationSuccess &&
      !!employer?.isStartDateSuccess &&
      onboardStep === 2
    ) {
      setIsCurrent(true);
    }
    if (groupName !== prevGroup && groupName === 'Non-Finance') {
      setEmployer({ ...employer, vertical: 'Non-Finance', role: '', subSectorId: undefined, sectorId: '' });
    } else {
      setEmployer({ ...employer, vertical: '', role: '', subSectorId: undefined, sectorId: '' });
    }
    setVertical(null);
    setRole(null);
    setSector(null);
    setSubSector(null);
  };

  useEffect(() => {
    if (onboardStep && onboardStep > 2 && employer?.isDescriptionFilled && !employer?.isDescriptionSuccess) {
      setIsCurrent(false);
    }
  }, [onboardStep]);

  const onClickByVertical = (verticalName: string) => {
    setVertical((prev) => (verticalName === prev ? null : verticalName));
    setIsSectorSelected!(vertical !== null);

    setEmployer({ ...employer, role: undefined, subSectorId: undefined });
    setRole(null);
    setIsRoleSelected!(false);

    const r = RolesArrays.find((item) => item.relation.includes(`${group} ${verticalName}`))?.roles;
    if (isAnon) {
      setIsSectorSelected!(true);
      setEmployer({ ...employer, vertical: `${group} ${verticalName}` });
    }
    if (!r) {
      setEmployer({ ...employer, vertical: `${group} ${verticalName}` });
    }
    if (r && role && r.includes(role)) {
      setIsSectorSelected!(true);
      setEmployer({ ...employer, vertical: `${group} ${verticalName}` });
    }
  };

  useEffect(() => {
    if (isAnon) {
      if (vertical === null) {
        setIsSectorSelected!(false);
        setIsRoleSelected!(true);

        setEmployer({ ...employer, vertical: group, role: '', subSectorId: undefined });
      }
    }
  }, [vertical, group, role]);

  const onClickByRole = (roleName: string) => {
    setRole((prev) => (roleName === prev ? null : roleName));
    if (roleName !== role) {
      setEmployer({ ...employer, vertical: `${group} ${vertical}`, role: roleName, subSectorId: undefined });
      setIsRoleSelected!(true);
      setIsSectorSelected!(vertical !== null);
    } else {
      setIsRoleSelected!(false);
      setEmployer({ ...employer, vertical: `${group} ${vertical}`, role: undefined, subSectorId: undefined });
    }
  };

  const roles = useMemo(
    () => group && vertical && RolesArrays.find((item) => item.relation.includes(`${group} ${vertical}`))?.roles,
    [group, vertical]
  );

  const onClickBySector = ({ id: sectorId, name }: ISector) => {
    const prevSector = sector;
    setSector((prev) => (sectorId === prev ? null : sectorId));
    setSectorName(name);
    setSubSector(null);
    setRole(null);
    setEmployer({ ...employer, role: '', subSectorId: undefined, sectorId: prevSector === sectorId ? undefined : sectorId });
  };

  const onClickBySubSector = (subSectorId: string, name?: string) => {
    const prevSub = subSector;
    setSubSector((prev) => (subSectorId === prev ? null : subSectorId));
    if (name && name === 'Cable, Satellite, Telecoms') {
      setIsCableTelecoms(true);
    }
    setRole(null);
    if (prevSub !== subSectorId) {
      setEmployer({ ...employer, role: '', subSectorId });
    }
  };

  const { data: sectors } = useQuery({
    queryKey: [QUERY_KEYS.SECTORS],
    queryFn: () => coverageService.getSectors()
  });

  const { data: subSectors, mutateAsync: mutateSubSectors } = useSubSectorsBySector();

  const sortedSubSectors = useMemo(() => {
    if (group === 'Non-Finance') {
      return subSectors?.sort((a, b) => a.name.localeCompare(b.name));
    }
    return subSectors;
  }, [subSectors, vertical]);

  useEffect(() => {
    if (sector) {
      mutateSubSectors(sector);
    }
  }, [sector]);

  useEffect(() => {
    if (group === 'Non-Finance' && subSector && subSectors) {
      const findSubSector = subSectors.find((it) => it.id === subSector);
      if (!findSubSector) return;
      const findSector = sectors?.find((it) => it.id === findSubSector?.sectorId);
      setEmployer({
        ...employer,
        vertical: 'Non-Finance',
        subSectorId: subSector,
        subSector: {
          ...findSubSector,
          sector: findSector
        },
        role: role ?? ''
      });
      if (role) {
        setIsRoleSelected!(true);
      } else {
        setIsRoleSelected!(false);
      }
    }
  }, [role]);

  const filteredSectors = useMemo(
    () =>
      sectors
        ?.filter((item) => item.jobVerticals.includes('Non-Finance'))
        .sort((a, b) => nonFinanceOrder.indexOf(a.name) - nonFinanceOrder.indexOf(b.name)),
    [sectors, nonFinanceOrder]
  );

  useEffect(() => {
    if (role?.trim().length && role && group !== 'Non-Finance' && employer?.vertical) setIsCurrent(false);
  }, [employer, role]);

  useEffect(() => {
    if (employer?.vertical === 'Publishing Sole Publisher') setIsCurrent(false);
  }, [employer, vertical]);

  useEffect(() => {
    if (group === 'Non-Finance' && sector && subSector && secutNonFinInput) secutNonFinInput.current?.focus();
  }, [subSector]);

  const checkOnboardStep = () => {
    if (!setCurrentStep) return;
    if (isAnon && onboardStep !== 1 && onboardStep !== 5 && group === 'Non-Finance') {
      updateSetupStep(2);
      return;
    }
    if (isAnon && onboardStep !== 1 && onboardStep === 2 && employer?.vertical && vertical) {
      setCurrentStep(3, employer);
      return;
    }
    if (isAnon && onboardStep !== 1 && onboardStep !== 5 && !vertical && !employer?.subSectorId && onboardStep !== 2) {
      updateSetupStep(2);
      return;
    }
    if (!isAnon && onboardStep !== 5 && group === 'Non-Finance') {
      updateSetupStep(3);
      return;
    }
    if (!isAnon && onboardStep === 3 && ((vertical && role) || vertical === 'Sole Publisher')) {
      setCurrentStep(4, employer);
      return;
    }
    if (!isAnon && group !== 'Non-Finance' && (!vertical || (!role && vertical !== 'Sole Publisher')) && onboardStep !== 3) {
      updateSetupStep(3);
    }
  };

  useEffect(() => {
    const timer = setTimeout(checkOnboardStep, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [isAnon, employer, onboardStep, vertical, role]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownIsShow &&
        dropdownRefs.map((dropdown) => dropdown.current && !dropdown.current.contains(event.target as Node)).includes(true)
      ) {
        hideAllDropdowns();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRefs, dropdownIsShow]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && insertAnswers) {
        if (group === 'Non-Finance' && ((!isAnon && role) || (isAnon && subSector))) insertAnswers();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [group, role]);

  return (
    <>
      <Styled.Row isVertical isCreateAnonVertical isNonFinenceSubVertical={group === 'Non-Finance'}>
        {VerticalArrays.filter((item) => !((!!group && group !== item.name) || item.name === group))
          .slice(0, 6)
          .map((item, index) => (
            <Styled.Item
              key={`group-${index}`}
              isDisabled={!!group && group !== item.name}
              isActive={item.name === group}
              onClick={() => onClickByGroup(item.name)}
            >
              {item.shortName ?? item.name}
            </Styled.Item>
          ))}
        {VerticalArrays.filter((item) => !((!!group && group !== item.name) || item.name === group)).slice(7).length >= 1 && (
          <Styled.Item isDisabled={false} isActive={false} onClick={() => switchShowGroupOther()}>
            Other
            {showGroupOther && (
              <Styled.Dropdown ref={groupOtherDropdown}>
                {VerticalArrays.filter((item) => !((!!group && group !== item.name) || item.name === group))
                  .slice(6)
                  .map((dropdownItem) => (
                    <Styled.DropdownItem
                      onClick={() => {
                        hideAllDropdowns();
                        onClickByGroup(dropdownItem.name);
                      }}
                    >
                      {dropdownItem.name}
                    </Styled.DropdownItem>
                  ))}
              </Styled.Dropdown>
            )}
          </Styled.Item>
        )}

        {VerticalArrays.filter((item) => item.name === group).map((item, index) => (
          <>
            <Styled.Item
              isNonFinance={item.name === 'Non-Finance'}
              key={`group-${index}`}
              isDisabled={!!group && group !== item.name}
              isActive={item.name === group}
              onClick={() => switchShowGroup()}
            >
              {item.shortName ?? item.name}
              <Image src={DropdownArrow} width={16} height={16} alt="arrow" />
            </Styled.Item>
            {showGroup && (
              <Styled.Dropdown ref={groupDropdown}>
                {VerticalArrays.map((dropdownItem) => (
                  <Styled.DropdownItem
                    onClick={() => {
                      hideAllDropdowns();
                      onClickByGroup(dropdownItem.name);
                    }}
                  >
                    {dropdownItem.name}
                  </Styled.DropdownItem>
                ))}
              </Styled.Dropdown>
            )}
          </>
        ))}
      </Styled.Row>
      {verticals && group !== 'Non-Finance' && (
        <Styled.Row isSubVertical isCreateAnonVertical>
          {verticals
            .filter((item) => !((!!vertical && vertical !== item) || vertical === item))
            .slice(0, group === 'Macro' ? 5 : 4)
            .map((item, index) => (
              <Styled.Item
                key={`vertical-${index}`}
                isDisabled={!!vertical && vertical !== item}
                isActive={vertical === item}
                onClick={() => onClickByVertical(item)}
              >
                {VerticalToShortened[item] ? VerticalToShortened[item] : item}
              </Styled.Item>
            ))}
          {verticals.filter((item) => !((!!vertical && vertical !== item) || vertical === item)).slice(4).length >= 1 && (
            <Styled.Item isDisabled={false} isActive={false} onClick={() => switchShowVerticalOther()}>
              Other
              {showVerticalOther && (
                <Styled.Dropdown ref={verticalOtherDropdown}>
                  {verticals
                    .filter((item) => !((!!vertical && vertical !== item) || vertical === item))
                    .slice(group === 'Macro' ? 5 : 4)
                    .map((dropdownItem) => (
                      <Styled.DropdownItem
                        onClick={() => {
                          hideAllDropdowns();
                          onClickByVertical(dropdownItem);
                        }}
                      >
                        {dropdownItem}
                      </Styled.DropdownItem>
                    ))}
                </Styled.Dropdown>
              )}
            </Styled.Item>
          )}
          {verticals
            .filter((item) => vertical === item)
            .map((item, index) => (
              <>
                <Styled.Item
                  key={`vertical-${index}`}
                  isDisabled={!!vertical && vertical !== item}
                  isActive={vertical === item}
                  onClick={() => switchShowVertical()}
                >
                  {item}
                  <Image width={16} height={16} src={DropdownArrow} alt="arrow" />
                </Styled.Item>
                {showVertical && (
                  <Styled.Dropdown ref={verticalDropdown}>
                    {verticals.map((dropdownItem) => (
                      <Styled.DropdownItem
                        onClick={() => {
                          hideAllDropdowns();
                          onClickByVertical(dropdownItem);
                        }}
                      >
                        {dropdownItem}
                      </Styled.DropdownItem>
                    ))}
                  </Styled.Dropdown>
                )}
              </>
            ))}
        </Styled.Row>
      )}
      {roles && !isAnon && !isCreateAnonVertical && (
        <Styled.Row isRoles>
          {roles
            .filter((item) => !((!!role && role !== item) || role === item))
            .slice(0, 5)
            .map((item, index) => (
              <Styled.Item
                key={`role-${index}`}
                isDisabled={!!role && role !== item}
                isActive={role === item}
                onClick={() => onClickByRole(item)}
              >
                {RoleToShortened[item] ? RoleToShortened[item] : item}
              </Styled.Item>
            ))}
          {roles.filter((item) => !((!!role && role !== item) || role === item)).slice(5).length >= 1 && (
            <Styled.Item isDisabled={false} isActive={false} onClick={() => switchShowRolesOther()}>
              Other
              {showRolesOther && (
                <Styled.Dropdown ref={roleOtherDropdown}>
                  {roles
                    .filter((item) => !((!!role && role !== item) || role === item))
                    .slice(5)
                    .map((dropdownItem) => (
                      <Styled.DropdownItem
                        onClick={() => {
                          hideAllDropdowns();
                          onClickByRole(dropdownItem);
                        }}
                      >
                        {RoleToShortened[dropdownItem] ? RoleToShortened[dropdownItem] : dropdownItem}
                      </Styled.DropdownItem>
                    ))}
                </Styled.Dropdown>
              )}
            </Styled.Item>
          )}
          {roles
            .filter((item) => role === item)
            .map((item, index) => (
              <>
                <Styled.Item
                  key={`role-${index}`}
                  isDisabled={!!role && role !== item}
                  isActive={role === item}
                  onClick={() => switchShowRoles()}
                >
                  {item} <Image width={16} height={16} src={DropdownArrow} alt="arrow" />
                </Styled.Item>
                {showRoles && (
                  <Styled.Dropdown ref={roleDropdown}>
                    {roles.map((dropdownItem) => (
                      <Styled.DropdownItem
                        onClick={() => {
                          hideAllDropdowns();
                          onClickByRole(dropdownItem);
                        }}
                      >
                        {dropdownItem}
                      </Styled.DropdownItem>
                    ))}
                  </Styled.Dropdown>
                )}
              </>
            ))}
        </Styled.Row>
      )}
      {group === 'Non-Finance' && (
        <>
          <Styled.Row isNonFinenceSubVertical isCreateAnonVertical>
            {!!filteredSectors &&
              filteredSectors
                .filter((item) => !((!!sector && sector !== item.id) || sector === item.id))
                .map((item) => (
                  <Styled.Item
                    isCableTelecoms={item.name === 'Cable, Satellite, Telecoms'}
                    key={`sector-in-verticl-${item.id}`}
                    isDisabled={false}
                    isActive={sector === item.id}
                    onClick={() => onClickBySector(item)}
                  >
                    {item.name}
                  </Styled.Item>
                ))}
            {!!filteredSectors &&
              filteredSectors
                .filter((item) => sector === item.id)
                .map((item) => (
                  <Styled.Item
                    isTelecomsMedia={item.name === 'Telecoms + Media'}
                    key={`sector-in-verticl-${item.id}`}
                    isDisabled={false}
                    isActive={sector === item.id}
                    onClick={() => switchShowVertical()}
                  >
                    {item.name} <Image src={DropdownArrow} width={16} height={16} style={{ marginLeft: -1 }} alt="arrow" />
                    {showVertical && (
                      <Styled.Dropdown ref={verticalNonFinanceDropdown}>
                        {filteredSectors
                          .filter((dropdownItem) => !!sector)
                          .map((dropdownItem) => (
                            <Styled.DropdownItem
                              onClick={() => {
                                hideAllDropdowns();
                                onClickBySector(dropdownItem);
                              }}
                            >
                              {dropdownItem.name}
                            </Styled.DropdownItem>
                          ))}
                      </Styled.Dropdown>
                    )}
                  </Styled.Item>
                ))}
          </Styled.Row>
          {sector && (
            <Styled.Row isNonFinenceSubVertical isCreateAnonVertical>
              {!!sortedSubSectors &&
                sortedSubSectors
                  .filter((item) => !((!!subSector && subSector !== item.id) || subSector === item.id))
                  .map((item) => (
                    <Styled.Item
                      isCableTelecoms={item.name === 'Cable, Satellite, Telecoms'}
                      key={`sub-sector-in-vertical-${item.id}`}
                      isDisabled={!!subSector && subSector !== item.id}
                      isActive={subSector === item.id}
                      onClick={() => onClickBySubSector(item.id, item.name)}
                    >
                      {nonFinanceSubSectorShortName[item.name] ? nonFinanceSubSectorShortName[item.name] : item.name}
                    </Styled.Item>
                  ))}
              {!!sortedSubSectors &&
                sortedSubSectors
                  .filter((item) => subSector === item.id)
                  .map((item) => {
                    const isCableTelecoms = item.name === 'Cable, Satellite, Telecoms';
                    return (
                      <Styled.Item
                        isCableTelecoms={isCableTelecoms}
                        key={`sub-sector-in-vertical-${item.id}`}
                        isDisabled={!!subSector && subSector !== item.id}
                        isActive={subSector === item.id}
                        onClick={() => switchShowRoles()}
                      >
                        {nonFinanceSubSectorShortName[item.name] ? nonFinanceSubSectorShortName[item.name] : item.name}{' '}
                        <Image width={16} height={16} src={DropdownArrow} alt="arrow" />
                        {showRoles && (
                          <Styled.Dropdown ref={roleNonFinanceDropdown}>
                            {sortedSubSectors.map((dropdownItem) => (
                              <Styled.DropdownItem
                                onClick={() => {
                                  hideAllDropdowns();
                                  onClickBySubSector(dropdownItem.id);
                                }}
                              >
                                {dropdownItem.name}
                              </Styled.DropdownItem>
                            ))}
                          </Styled.Dropdown>
                        )}
                      </Styled.Item>
                    );
                  })}
            </Styled.Row>
          )}
          {!isAnon && subSector && !isCreateAnonVertical && (
            <Styled.Input
              ref={secutNonFinInput}
              value={role ?? ''}
              // onKeyDown={onEnter}
              onChange={(e) => setRole(e.target.value)}
              placeholder="Type in your job role here"
            />
          )}

          {subSector && !isCreateAnonVertical && (
            <Styled.JoinVillanButtonSection>
              <Styled.JoinVillanButton
                disabled={(!role && !isAnon) || (!!isAnon && !subSector)}
                onClick={() => {
                  insertAnswers();
                }}
              >
                Join Villan <Image src={JoinVillanArrow} alt="arrow" />
              </Styled.JoinVillanButton>
              <Styled.JoinVillanPressEnter disabled={(!role && !isAnon) || (!!isAnon && !subSector)}>
                press enter{' '}
                <Image src={(!role && !isAnon) || (isAnon && !subSector) ? PressEnterArrow10Opacity : PressEnterArrow} alt="arrow" />
              </Styled.JoinVillanPressEnter>
            </Styled.JoinVillanButtonSection>
          )}
        </>
      )}
    </>
  );
};
