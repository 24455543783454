import React, { ReactElement } from 'react';
import * as Styled from './tag-list.styled';
import { Chip } from '@/common/components/chip';
import { OthersText } from '../../../post-insight/post-insight.styled';

interface IProps {
  listTitle?: string;
  feedId?: string;
  tagsList: string[];
  isOpen?: boolean;
  toggleShowAll?: () => void;
}

const maxSecuritiesToShow = 7;

export const TagListComponent = ({ listTitle, tagsList, feedId, isOpen, toggleShowAll }: IProps): ReactElement => {
  const displaySecurities = isOpen ? tagsList : tagsList.slice(0, maxSecuritiesToShow);
  const remainingSecuritiesCount = tagsList.length - maxSecuritiesToShow;

  const othersText = isOpen
    ? '— show fewer'
    : remainingSecuritiesCount === 1
    ? '+ 1 other'
    : remainingSecuritiesCount > 1
    ? `+ ${remainingSecuritiesCount} others`
    : null;

  return (
    <Styled.Container>
      {listTitle && <Styled.Title>{listTitle}</Styled.Title>}
      {displaySecurities?.map((tag) => <Chip label={tag} color="primary" key={`${feedId}-${tag}`} />)}
      {othersText && (
        <OthersText onClick={toggleShowAll} style={{ cursor: 'pointer' }}>
          {othersText}
        </OthersText>
      )}
    </Styled.Container>
  );
};
