import styled from 'styled-components';
import { ActionType } from '@/common/types/anon.types';

export const AnonymityFormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AnonymityFormConfirmBtn = styled.button<{isActive: boolean, actionType:ActionType}>`
  display: flex;
  width: ${({ actionType }) => (actionType === ActionType.POST ? '80px' : '102px')};
  height: 36px;
  padding: 3px 10px 2px 7px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 10px; 
  background: ${({isActive}) => (isActive ? '#00a880;' : 'rgba(0, 168, 128, 0.20);')};
  color: #fff; 
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border: none;
`;
export const AnonymityPressEnter = styled.div<{isActive: boolean}>`
padding: 3px 0;
  color: ${(props) => (props.isActive ? '#00a880;' : 'rgba(0, 168, 128, 0.20);')};
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 14px;
`;
export const AnonymityConfirmActions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
`;
export const AnonymityBackActions = styled.div`
  color: #797979;
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 3px 0;
  line-height: 14px;
  cursor: pointer;
`;
