import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';

import { COLORS, FONTS } from '@/theme';

export const QuotePostWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const QuotePostIcon = styled(Image)`
  margin: 0 7.5px;
  width: 15px;
  height: 15px;
`;

export const PostWrapper = styled.div<{
  isReply?: boolean;
  isQuote?: boolean;
  isOwnReply?: boolean;
  isNotDisaplay?: boolean;
  isClickable?: boolean;
  isPublish?: boolean;
}>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'};
  display: flex;
  flex-direction: column;
  row-gap: ${({ isPublish }) => (isPublish ? '0' : '12px')};
  ${({ isReply }) => isReply && 'max-height: 74px;'};
  row-gap: 12px;
  overflow: visible;
  ${({ isOwnReply }) => isOwnReply && 'padding-left: 30px;'}
  ${({ isNotDisaplay }) => isNotDisaplay && 'display: none;'}
  ${({ isQuote }) => isQuote && 'padding-left: 15px;gap: 5px;'}
  width: 100%;
`;

export const RepostBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PostBodyWrapper = styled.div<{ isQuote?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ isQuote }) => isQuote && 'gap: 5px;'}
  position: relative;
`;

export const PostBodyWrapperWithoutDelete = styled.article<{ ondelete?: boolean }>`
  display: flex;
  gap: 5px;
  position: relative;
  ${({ ondelete }) => ondelete !== undefined && 'filter: blur(1.5px);'}
`;

export const RepliedPostWrapper = styled.div`
  margin-bottom: -4px;
`;

export const PostInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeletedPostWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: end;
`;

export const Trash = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const TrashWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.trashWraperBackground};
`;

export const DeletedPostText = styled.div<{ isQuote?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  line-height: 13px;
  font-size: 13px;
  cursor: pointer;
  font-weight: ${FONTS.WEIGHTS.normal};
  color: ${({ isQuote }) => (isQuote ? COLORS.repliedPostText : COLORS.fullnamePostInfoColor)};
  ${({ isQuote }) =>
    isQuote &&
    `font-style: italic;font-size: ${FONTS.SIZES.m};font-weight: ${FONTS.WEIGHTS.light};line-height: 20px;padding-bottom: 0px; 
    cursor: text;`}
`;

export const DeleteBlock = styled.div<{ ondelete?: boolean; isInModal?: boolean }>`
  background: ${({ ondelete, isInModal }) =>
    isInModal
      ? ondelete
        ? COLORS.secondClickOnDeletePostButtonInModal
        : COLORS.firstClickOnDeletePostButtonInModal
      : ondelete
      ? COLORS.secondClickOnDeletePostButton
      : COLORS.firstClickOnDeletePostButton};
  position: absolute;
  top: 0;
  left: 0;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  backdrop-filter: blur(2.5px);
  display: ${({ ondelete }) => (typeof ondelete === 'undefined' ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const HideTextBlock = styled.div<{ isQuote?: boolean }>`
  color: rgba(0, 0, 0, 0.2);
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: 20px;
  ${({ isQuote }) => isQuote && 'font-style: italic;'}
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

export const DeleteButton = styled(Image as AnyStyledComponent)`
  cursor: pointer;
  width: 22px;
  height: 22px;
`;

export const PostImageWrapper = styled.div`
  height: 84px;
  width: 84px;
  border-radius: 7px;
  overflow: hidden;
`;

export const PostImageScreensaver = styled.div`
  height: 84px;
  width: 84px;
  border-radius: 7px;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background: linear-gradient(225deg, #fff 50.29%, rgba(231, 231, 231, 0.75) 100%);
`;

export const PostImage = styled.img`
  height: 84px;
  width: 84px;
  object-fit: cover;
  border-radius: 7px;
  overflow: hidden;
`;

export const VoteWrapper = styled.div`
  width: 385px;
`;

export const PostContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  gap: 12px;
`;

export const PostTextWrapper = styled.div<{ isHidden?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ isHidden }) =>
    isHidden &&
    ` 
      user-select: none !important;
      background: rgba(253, 253, 253, 0.8);
      filter: blur(2.5px);
    `}
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  min-height: 22px;
  & > p {
    display: flex;
    padding: 0px 10px;
    height: 22px;
    word-wrap: nowrap;
    align-items: center;
    color: #707070;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    background-color: ${COLORS.newsfeedTags};
    border-radius: 8px;
    border: 1px solid ${COLORS.newsfeedTagsBorder};
  }
`;

export const PostHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  gap: 5px;
  align-items: flex-end;
`;

export const NewsFeedContanWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;

export const SourceIcon = styled.img`
  border-radius: 100px;
  object-fit: cover;
`;

export const FeedIcon = styled.img`
  border-radius: 3px;
  object-fit: cover;
`;

export const SourceIconWrapper = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${COLORS.black};
  border-radius: 100px;
`;

export const SourceName = styled.p`
  color: ${COLORS.fullnamePostInfoColor};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
`;

export const HeaderActionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3px;
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 3px;
`;

export const RSSText = styled(SourceName)`
  color: #a0a0a0;
  font-size: 12px;
`;

export const PostDate = styled.p`
  color: ${COLORS.lastDividerColorInVisibilitySettings};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
`;

export const PostOptionIcon = styled(Image)`
  cursor: pointer;
  width: 16px;
  height: 4px;
  margin-bottom: 1px;
`;

export const PostTitle = styled.h4`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
  width: 411px;
`;

export const PostDescription = styled.p`
  color: ${COLORS.newsfeedDescription};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  min-height: 40px;
  height: fit-content;
  width: 411px;
`;

export const PostActionsWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 10px;
`;

export const PostActionItem = styled.div<{ hoverColor?: keyof typeof COLORS }>`
  cursor: pointer;
  color: ${COLORS.authInputTextColor};
  display: flex;
  gap: 5px;
  align-items: flex-end;
  :hover {
    color: ${({ hoverColor }) => hoverColor && COLORS[hoverColor]};
  }
`;

export const PostActionIcon = styled(Image)`
  height: 20px;
`;

export const PostMarkActionIcon = styled(Image)<{ isShow?: boolean }>`
  position: absolute;
  height: 20px;
  z-index: -100;
  visibility: hidden;
  ${({ isShow }) => isShow && 'z-index: 1; visibility: visible;'}
`;

export const EmptyDiv = styled.div`
  width: 20px;
  height: 20px;
`;

export const EmptyDivSave = styled.div`
  width: 16px;
  height: 20px;
`;

export const PostActionText = styled.p`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;
