export const OTHER_ROLE = 'Other';

export const RoleToShortened: Record<string, string> = {
  'Portfolio Manager': 'PM',
  'Senior Analyst': 'Snr Analyst'
};

export const VerticalToShortened: Record<string, string> = {
  'Investment Grade': 'IG'
};

export const nonFinanceOrder = [
  'Consumer',
  'Technology',
  'Telecoms + Media',
  'Healthcare',
  'Financials',
  'Industrials',
  'Materials',
  'Real Estate',
  'Energy + Utilities'
];

export const RolesArrays = [
  {
    roles: [
      'Portfolio Manager',
      'Partner',
      'Senior Analyst',
      'Analyst',
      'Private Investor',
      'Senior Trader',
      'Trader',
      'Management',
      'Intern',
      'Other'
    ],
    relation: [
      'Equity Long / Short',
      'Equity Long Only',
      'Equity Short Only',
      'Equity Crossover',
      'Equity Market Neutral',
      'Credit Investment Grade',
      'Credit HY + Distressed',
      'Credit EM',
      'Macro Cross-Asset',
      'Macro FX',
      'Macro Fixed Income',
      'Macro Equities',
      'Macro Energy',
      'Macro Metals',
      'Macro Agriculture',
      'Cross-Asset Quant',
      'Cross-Asset Event-Driven'
    ]
  },
  {
    roles: ['Sector Head', 'Partner', 'Senior Analyst', 'Analyst', 'Associate', 'Management', 'Intern', 'Other'],
    relation: ['Equity Research']
  },
  {
    roles: ['Senior Partner', 'Partner', 'Principal', 'Associate', 'Analyst', 'Private Investor', 'Management', 'Intern', 'Other'],
    relation: [
      'Venture Full Cycle',
      'Venture Early Stage',
      'Venture Growth',
      'Venture Crypto',
      'Real Assets Infrastructure',
      'Real Assets Real Estate',
      'Real Assets Agriculture'
    ]
  },

  {
    roles: ['Senior Partner', 'Partner', 'Senior Principal', 'Principal', 'Associate', 'Analyst', 'Management', 'Intern', 'Other'],
    relation: ['Private Equity Buyouts']
  },
  {
    roles: ['Partner', 'Principal', 'Associate', 'Analyst', 'Management', 'Intern', 'Other'],
    relation: ['Private Equity Search Fund', 'Private Equity Rights']
  },
  {
    roles: ['Sector Head', 'Senior Analyst', 'Analyst', 'Associate', 'Management', 'Intern', 'Other'],
    relation: ['Credit Research', 'Macro Research']
  },
  {
    roles: [
      'Partner',
      'Managing Director',
      'Executive Director',
      'Vice President',
      'Associate',
      'Analyst',
      'Management',
      'Intern',
      'Other'
    ],
    relation: ['Equity Sales / Trading', 'Advisory Investment Banking']
  },
  {
    roles: ['Partner', 'Associate / Junior Partner', 'Principal', 'Manager', 'Team Leader', 'Consultant', 'Associate', 'Analyst'],
    relation: ['Advisory Consulting']
  },
  {
    roles: ['Owner', 'Editor-in-Chief', 'Editor', 'Associate Editor', 'Staff Writer', 'Columnist', 'Contributor'],
    relation: ['Publishing Financial Media']
  },
  {
    roles: ['Senior Partner', 'Partner', 'Principal', 'Associate', 'Analyst', 'Management', 'Intern', 'Other'],
    relation: [
      'Allocation Endowment',
      'Allocation Pension Fund',
      'Allocation Family Office',
      'Allocation Private Wealth',
      'Allocation Insurance',
      'Allocation Fund of Funds'
    ]
  }
];

export const VerticalArrays: { name: string; shortName?: string; verticals?: string[] }[] = [
  {
    name: 'Equity',
    verticals: ['Long / Short', 'Market Neutral', 'Long Only', 'Crossover', 'Short Only', 'Research', 'Sales / Trading']
  },
  {
    name: 'Venture',
    shortName: 'VC',
    verticals: ['Full Cycle', 'Early Stage', 'Growth', 'Crypto']
  },
  {
    name: 'Private Equity',
    shortName: 'PE',
    verticals: ['Buyouts', 'Search Fund', 'Rights']
  },
  {
    name: 'Credit',
    verticals: ['Investment Grade', 'HY + Distressed', 'EM', 'Research']
  },
  {
    name: 'Macro',
    verticals: ['Cross-Asset', 'FX', 'Fixed Income', 'Energy', 'Metals', 'Equities', 'Agriculture', 'Research']
  },
  {
    name: 'Allocation',
    verticals: ['Endowment', 'Family Office', 'Fund of Funds', 'Pension', 'Private Wealth', 'Insurance']
  },
  {
    name: 'Real Assets',
    verticals: ['Infrastructure', 'Real Estate', 'Agriculture']
  },
  {
    name: 'Cross-Asset',
    verticals: ['Quant', 'Event-Driven']
  },
  {
    name: 'Advisory',
    verticals: ['Investment Banking', 'Consulting']
  },
  {
    name: 'Publishing',
    verticals: ['Financial Media', 'Sole Publisher']
  },
  {
    name: 'Non-Finance'
  }
];

export const jobVerticalNotShow = [
  'Private Equity Search Fund',
  'Private Equity Rights',
  'Real Assets Infrastructure',
  'Real Assets Real Estate',
  'Real Assets Agriculture',
  'Cross-Asset Quant',
  'Allocation Pension Fund',
  'Allocation Insurance',
  'Allocation Fund of Funds',
  'Non-Finance'
];

export const jobVerticalMin = [
  'Equity Long / Short',
  'Equity Long Only',
  'Equity Short Only',
  'Equity Crossover',
  'Equity Research',
  'Equity Sales / Trading',
  'Equity Market Neutral'
];

export const subSectorNotShowDependsOnJobVertical = [
  'Venture Crypto',
  'Private Equity Rights',
  'Credit EM',
  'Macro Cross-Asset',
  'Macro FX',
  'Macro Fixed Income',
  'Macro Equities',
  'Macro Energy',
  'Macro Metals',
  'Macro Agriculture',
  'Macro Research',
  'Real Assets Infrastructure',
  'Real Assets Agriculture',
  'Cross-Asset Quant',
  'Cross-Asset Event-Driven',
  'Allocation Endowment',
  'Allocation Pension Fund',
  'Allocation Family Office',
  'Allocation Private Wealth',
  'Allocation Insurance',
  'Allocation Fund of Funds',
  'Publishing Financial Media',
  'Publishing Sole Publisher',
  'Non-Finance'
];

export const sectorsNotShowDependsOnJobVertical = [
  'Private Equity Rights',
  'Credit EM',
  'Macro Cross-Asset',
  'Macro FX',
  'Macro Fixed Income',
  'Macro Equities',
  'Macro Research',
  'Cross-Asset Quant',
  'Cross-Asset Event-Driven',
  'Non-Finance'
];

export const nonFinanceSubSectorShortName: Record<string, string> = {
  'Medical Equipment': 'Medical Equip.'
};

export const subSectorsWidths: Record<string, string> = {
  Consumer: '610px',

  Technology: '737px',

  'Telecoms + Media': '698px',

  Financials: '705px',

  Industrials: '803px',

  Healthcare: '708px',

  Materials: '735px',

  'Real Estate': '381px',

  'Energy + Utilities': '507px',

  'Residential Real Estate': '257px',

  'Commercial Real Estate': '430px'
};

export const subSectorsWidthsForAnonOnboarding: Record<string, string> = {
  Consumer: '470px',

  Technology: '450px',

  'Telecoms + Media': '450px',

  Financials: '400px',

  Industrials: '590px',

  Healthcare: '400px',

  Materials: '450px',

  'Real Estate': '590px',

  'Energy + Utilities': '590px',

  'Residential Real Estate': '257px',

  'Commercial Real Estate': '430px'
};
