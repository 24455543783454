import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { motion } from 'framer-motion';
import LineLeft from '@/styles/icons/line-left.webp';
import LineRight from '@/styles/icons/line-right.webp';
import LineLeftD from '@/styles/icons/line-left-disabled.webp';
import LineRightD from '@/styles/icons/line-right-disabled.webp';
import { COLORS, FONTS } from '@/theme';
import { SentimentType } from './poll-result.const';
import { UserImageComponent } from '@/common/components/user-image';
import { FAMILIES } from '@/theme/fonts.const';

type SideType = 'left' | 'right';
type DirectionType = 'top' | 'bottom';

interface IAnswerLineProps {
  side: SideType;
  direction: DirectionType;
  isAligned: boolean;
  isQuote?: boolean;
  isFaded?: boolean;
  isConsensus?: boolean;
}

interface IAnswerLineEndTextProps {
  direction: DirectionType;
  type: SentimentType;
  answer: number;
  isQuote?: boolean;
  isFaded?: boolean;
  isConsensus?: boolean;
}

export const QuestionResultWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PollIcon = styled.div`
  background: #e0f2e0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  position: absolute;
  left: -40px;
  top: 0;
`;

export const ConsensusDot = styled.p`
  color: #8d8d8d;
`;

export const ResultTitleWrapper = styled.span<{ isClicable?: boolean; isQuote?: boolean }>`
  width: 100%;
  margin-bottom: 8px;
  color: #2e2e2e;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.semiBold};
  ${({ isQuote }) =>
    isQuote &&
    css`
      color: #2e2e2e;
      margin-bottom: 4px;
    `}
  line-height: 13px;
  display: flex;
  gap: 8px;
  align-items: baseline;
  & > p {
    color: #b9b9b9;
    font-size: 13px;
    line-height: ${FONTS.SIZES.s};
  }

  & > ${ConsensusDot} {
    color: #8d8d8d;
  }

  & > button {
    border: none;
    background: transparent;
    padding-top: 2px;
    color: #b9b9b9;
    text-align: right;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.s};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.semiBold};
    line-height: ${FONTS.SIZES.s};
    ${({ isClicable }) =>
      isClicable &&
      css`
        cursor: pointer;
        &:hover {
          color: #0016d8;
        }
      `}
  }
`;

export const QuestionResultTitleContainer = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  display: flex;
  gap: 5px;
`;

export const QuestionResultTitleCaption = styled.div<{ isQuote?: boolean; isFaded?: boolean; isClickable: boolean }>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  color: #7c7c7c;
  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  ${({ isFaded }) => isFaded && 'color: #858585;'}
`;

export const QuestionResultTitle = styled.div<{
  type: SentimentType;
  optionBtn?: boolean;
  isQuote?: boolean;
  isFaded?: boolean;
  isClickable: boolean;
  isAnon: boolean;
}>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  color: #000;
  font-weight: ${FONTS.WEIGHTS.normal};
  ${({ isAnon }) => isAnon && 'color: #fff;'}
  ${({ optionBtn }) => (optionBtn ? 'max-width: calc(100% - 20px);' : '')}
  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  ${({ isFaded }) => isFaded && 'color: #858585;'}
`;

export const DebateCapture = styled.span`
  color: #7c7c7c;
  margin-right: 7px;
`;

export const OptionIcon = styled(Image as AnyStyledComponent)`
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  cursor: pointer;
`;

export const DetailsViewRowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 4px;
  margin-top: 8px;

  & > p {
    flex: 1 0 0;
    color: #7c7c7c;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.xxl};
  }

  & > button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #0016d9;
    background: transparent;
    border: none;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 12px;
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.xxl};
    letter-spacing: 0.1px;

    &:disabled {
      color: #acacac;
      pointer: cursor;
    }
  }
`;

export const DetailsViewProcentage = styled.span`
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
  display: flex;
  gap: 4px;
`;

export const DetailsViewDot = styled.p`
  color: #cbcbcb;
  margin: 0 2px;
`;

export const DetailsViewMoreNegativeProcentage = styled.p`
  color: #732bea;
`;

export const DetailsViewAlignmentText = styled.p`
  color: #5f5f5f;
`;

export const DetailsViewMorePositiveProcentage = styled.p`
  color: #12847d;
`;

export const UsersYouFollowText = styled.p`
  color: #343434;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const ViewDetailsTitle = styled.span`
  padding: 8px 0px 4px 0px;
  color: #7c7c7c;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
`;

export const UserImageComponentWrapper = styled(UserImageComponent)<{ isAlone: boolean; index?: number }>`
  max-width: 34px;
  min-width: 34px;
  width: 34px;
  max-height: 34px;
  min-height: 34px;
  height: 34px;
  ${({ index }) => index && `z-index: ${index};`}
  ${({ isAlone }) =>
    isAlone &&
    `
    max-width: 30px;
    min-width: 30px;
    width: 30px;
    max-height: 30px;
    min-height: 30px;
    height: 30px;
    `}
  margin-right: -4px;
`;

export const ViewDetailsItemWrapper = styled.div<{ isReversed?: boolean; isOtherText?: boolean }>`
  padding: 2px 0px;
  display: flex;
  gap: 5px;
  & > article {
    display: flex;
    width: 60px;
    padding: 2px 5px;
    justify-content: start;
    ${({ isReversed }) => isReversed && 'justify-content: end;'}
    & > p {
      width: fit-content;
      color: #4e0eb6;
      ${({ isReversed }) => isReversed && 'color: #0E5B57;'}
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.s};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.light};
      line-height: ${FONTS.SIZES.m};
    }
  }

  & > div {
    & > p {
      color: #646464;
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.s};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.light};
      line-height: ${FONTS.SIZES.s};
      ${({ isOtherText, isReversed }) => {
        if (!isOtherText) return;
        if (isReversed) return 'padding-right: 10px;';
        return 'padding-left: 10px;';
      }}
    }
    display: flex;
    align-items: center;
    align-content: center;
    ${({ isReversed }) => isReversed && 'justify-content: flex-end;'}
    flex: 1 0 0;
  }
`;

export const HiddenAnswerBlockWrapper = styled.div`
  width: 100%;
  height: 313px;
  position: relative;
  margin-bottom: -6px;
`;

export const DebateResultWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HiddenAnswerBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: calc(100% + 10px);
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  & > p {
    padding-top: 5px;
    color: #a50000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 13px;
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.m};
    padding-right: 20px;
  }
`;

export const AnswerLineWrapper = styled.div`
  height: 47px;
  display: flex;
  align-items: center;
`;

export const AnswerLineContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  height: 3px;
  width: 100%;
`;

const AnswerLineBgStyle = css`
  height: 100%;
  width: 50%;
  position: relative;
`;

export const AnswerLineLeftBg = styled.div`
  ${AnswerLineBgStyle}
  border-radius: 2px 0 0 2px;
  // border-right: 1px solid rgba(137, 137, 137, 1);
`;

export const AnswerLineRightBg = styled.div`
  ${AnswerLineBgStyle}
  border-radius: 0 2px 2px 0;
  // border-left: 1px solid rgba(137, 137, 137, 1);
`;

export const GreyDot = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 1.5px);
  z-index: 1;
  border-radius: 3px;
  height: 3px;
  width: 3px;
  background: #969696;
`;

export const AnswerLineBgStyleCss = css`
  position: absolute;
  top: 0;
  // filter: blur(0.5px);
  width: 100%;
  height: 100%;
`;

export const AnswerLineRightBgStyle = styled.div<{ isFaded?: boolean; isQuote?: boolean }>`
  ${AnswerLineBgStyleCss}
  background-image:url(${({ isFaded, isQuote }) => (isFaded || isQuote ? LineRightD.src : LineRight.src)});
  background-size: cover;
  background-position: left;
  left: 0;
`;

export const AnswerLineLeftBgStyle = styled.div<{ isFaded?: boolean; isQuote?: boolean }>`
  ${AnswerLineBgStyleCss}
  background-image: url(${({ isFaded, isQuote }) => (isFaded || isQuote ? LineLeftD.src : LineLeft.src)});
  background-position: right;
  background-size: cover;
  right: 0;
`;

export const AnswerLineLength = styled.div<{ side: SideType }>`
  position: absolute;
  top: 0;
  left: ${({ side }) => (side === 'left' ? '3px' : 0)};
  right: ${({ side }) => (side === 'right' ? '3px' : 0)};
  bottom: 0;
`;

const AnswerLineStyle = css<{ left: number; right: number }>`
  height: 100%;
  top: 0;
  position: absolute;
  left: ${({ left }) => `calc(${left}%)`};
  right: ${({ right }) => `calc(${right}%)`};
`;

export const AnswerLineLeft = styled.div<{ left: number; right: number; isAligned: boolean; isQuote?: boolean; isFaded?: boolean }>`
  ${AnswerLineStyle}
`;

export const AnswerLineRight = styled.div<{ left: number; right: number; isAligned: boolean; isQuote?: boolean; isFaded?: boolean }>`
  ${AnswerLineStyle}
`;

const AnswerLineEndStyle = css<IAnswerLineProps>`
  position: absolute;
  top: -2px;
  ${({ side }) => (side === 'left' ? 'left: -4px' : 'right: -4px')};
  z-index: 2;
  // ${({ direction }) => (direction === 'top' ? 'bottom: 5px' : 'top: 5px')};
  width: 9px;
  height: 9px;
  border-radius: 9px;
`;

export const AnswerLeftLineEnd = styled.div<IAnswerLineProps>`
  ${AnswerLineEndStyle}
  background: #732BEA;
  ${({ isConsensus }) => isConsensus && 'background: rgba(115, 43, 234, 0.75);'}
  ${({ isQuote, isFaded, isConsensus }) => {
    if ((isQuote || isFaded) && !isConsensus) return 'background: #B7B7B7;';
    if ((isQuote || isFaded) && isConsensus) return 'background: rgba(183, 183, 183, 0.75);';
  }}
`;

export const AnswerRightLineEnd = styled.div<IAnswerLineProps>`
  ${AnswerLineEndStyle}
  background: rgb(18, 132, 125);
  ${({ isConsensus }) => isConsensus && 'background: rgba(18, 132, 125, 0.75);'}
  ${({ isQuote, isFaded, isConsensus }) => {
    if ((isQuote || isFaded) && !isConsensus) return 'background: #B7B7B7;';
    if ((isQuote || isFaded) && isConsensus) return 'background: rgba(183, 183, 183, 0.75);';
  }}
`;

export const AnswerLineEndTextStyle = css<IAnswerLineEndTextProps>`
  position: absolute;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${({ direction }) => (direction === 'top' ? FONTS.WEIGHTS.medium : FONTS.WEIGHTS.normal)};
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  ${({ answer }) => (answer <= 5.9 && answer >= 1.1 ? 'transform: translateX(calc(-50% + 4px))' : '')};
  ${({ answer }) => (answer > 5.9 ? 'right: 0' : '')};
  ${({ direction }) => (direction === 'top' ? 'top: -17px' : 'bottom: -17px')};
`;

export const AnswerLeftLineEndText = styled.div<IAnswerLineEndTextProps>`
  ${AnswerLineEndTextStyle}
  color: #732BEA;
  ${({ isConsensus }) => isConsensus && 'color: rgba(115, 43, 234, 0.75);'}
  ${({ isQuote, isConsensus }) => {
    if (isQuote && !isConsensus) return 'color: #B7B7B7;';
    if (isQuote && isConsensus) return 'color: rgba(183, 183, 183, 0.75);';
  }}
  ${({ isFaded }) => isFaded && 'color: #A8A8A8;'}
`;

export const AnswRightLineEndText = styled.div<IAnswerLineEndTextProps>`
  ${AnswerLineEndTextStyle}
  color: rgb(18, 132, 125);
  ${({ isConsensus }) => isConsensus && 'color: rgba(18, 132, 125, 0.75);'}
  ${({ isQuote, isConsensus }) => {
    if (isQuote && !isConsensus) return 'color: #B7B7B7;';
    if (isQuote && isConsensus) return 'color: rgba(183, 183, 183, 0.75);';
  }}
  ${({ isFaded }) => isFaded && 'color: #A8A8A8;'}
`;

export const CancelButton = styled.button`
  margin-left: auto;
  color: #be0000 !important;
  gap: 5px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 20px !important;
`;

export const CancelButtonIcon = styled(Image)`
  width: 14px;
  height: 14px;
`;
