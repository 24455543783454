import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import CloseBlack from '@styles/icons/modal-close-black.webp';
import CloseGrey from '@styles/icons/modal-close-grey.webp';
import AddReplyIconBlack from '@styles/icons/add-reply-black.svg';
import AddReplyIconGrey from '@styles/icons/add-reply-grey.svg';
import AttachPhoto from '@styles/icons/reply-repost-attach-photo.webp';
import * as Styled from './new-musing-modal.styled';
import { useCheckedBasicPostInfo } from '@/common/hooks';
import { POST } from '@/common/consts';
import { IProfile, OnDropErrorEnum } from '@/common/types/profile.type';
import { INewPostReply } from './new-musing-modal.types';
import { getBase64 } from '../onboard/helpers';
import { NewPostReplyComponent } from './new-post-reply.component';
import { PostButtonComponent } from '../modal/components/post-button';
import { pasteBuffer } from '@/common/utils/copy-past-in-input';
import { ImagePreviewComponent } from '../post/components/image-preview';
import { LinkPreviewForNewPost } from '../post/components/link-preview-for-new-post';
import { handleDrop } from '@/common/utils/on-drop-in-input';
import { ModalErrorOnDragAndDropComponent } from '../modal-error-on-drag-and-drop';
import NormalPostIcon from '@/styles/icons/normal-post.png';
import PrivatePostIcon from '@/styles/icons/private-post.png';
import { PostDivider } from '../profile/components/post-row/post-row.styled';
import { PostFormActions } from '../create-modal/components/insight/components/post-form-actions';

interface IProps {
  profile?: IProfile;
  isLoading: boolean;
  open: boolean;
  isSuccess: boolean;
  reset: () => void;
  closeHandler: () => void;
  createClick: (text: string, image: ArrayBuffer | string | null, replies: INewPostReply[], isPrivate: boolean) => void;
  setIsScrollDisabled?: (it: boolean) => void;
  isInNewQuote?: boolean;
  isCreateModal?: boolean;
}

export const NewMusingInputsComponent = ({
  profile,
  isCreateModal,
  isLoading,
  open,
  isSuccess,
  reset,
  closeHandler,
  createClick,
  setIsScrollDisabled,
  isInNewQuote
}: IProps) => {
  const [text, setText] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [image, setImage] = useState<ArrayBuffer | string | null>(null);
  const [replies, setReplies] = useState<INewPostReply[]>([]);
  const [isMoreThanMaxLength, setIsMoreThanMaxLength] = useState(false);
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [isPostDisabled, setIsPostDisabled] = useState(true);

  const { info } = useCheckedBasicPostInfo();

  const textAreaAutoresize = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    textAreaAutoresize();
  }, [text]);

  useEffect(() => {
    if ((text || image) && replies.every((reply) => reply.text || reply.image) && !isMoreThanMaxLength) {
      setIsPostDisabled(false);
    } else {
      setIsPostDisabled(true);
    }
  }, [text, image, replies, isMoreThanMaxLength]);

  const uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const img = await getBase64(e.target.files[0]);
      setImage(img);
      e.target.value = '';
    }
  };

  const resetFields = () => {
    reset();
    setText('');
    setImage(null);
    setReplies([]);
    setIsPrivate(false);
  };

  useEffect(() => {
    if (isSuccess) {
      closeHandler();
      resetFields();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!open) {
      resetFields();
    }
  }, [open]);

  const addReplyClick = () => {
    setReplies([...replies, { id: v4(), text: '', image: null }]);
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    pasteBuffer(e, setImage);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrop(e, setImage, setOnDropError);
  };

  const createPost = () => {
    if (isPostDisabled || isLoading) return;
    createClick(text, image, replies, isPrivate);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      createPost();
    }
  };

  const isSomeReplyEmpty = replies.some((el) => !el.text.length);

  const actions = isCreateModal ? (
    <Styled.ModalActionsContainer gap={5} mt={13}>
      <PostFormActions
        minW={392}
        title={replies.length ? 'post all' : 'post'}
        isDisabled={isLoading || isSuccess}
        isActive={Boolean(text) && !isSomeReplyEmpty}
        activeCallback={createPost}
      />
      <Styled.AddReplyButtonNew disabled={isPostDisabled} onClick={addReplyClick} isFirst={!replies.length}>
        add reply
        <Styled.AddReplyButtonImage src={isPostDisabled ? AddReplyIconGrey : AddReplyIconBlack} alt="send" />
      </Styled.AddReplyButtonNew>
    </Styled.ModalActionsContainer>
  ) : (
    <Styled.ModalActionsContainer>
      <PostButtonComponent
        isInNewQuote={isInNewQuote}
        isDisabled={isPostDisabled}
        onClick={createPost}
        isLoading={isLoading}
        text={isInNewQuote ? 'quote' : replies.length ? 'post all' : 'post'}
        borderRadius={8}
      />
      <Styled.AddReplyButton disabled={isPostDisabled} onClick={addReplyClick} isFirst={!replies.length}>
        <Styled.AddReplyButtonImage src={isPostDisabled ? AddReplyIconGrey : AddReplyIconBlack} alt="send" />
        {replies.length ? 'add reply' : 'add reply to create thread'}
      </Styled.AddReplyButton>
      <Styled.PrivatePostButton onClick={() => setIsPrivate((prev) => !prev)} isPrivate={isPrivate}>
        <Styled.PrivatePostButtonImage src={isPrivate ? PrivatePostIcon : NormalPostIcon} alt="private-status" />
        {isPrivate ? 'only you' : 'normal'}
      </Styled.PrivatePostButton>
    </Styled.ModalActionsContainer>
  );

  return (
    <>
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      {!!info && (
        <Styled.PostBasicInfo
          fullName={info.fullName}
          role={info.role}
          vertical={info.vertical}
          imageUrl={info.imageUrl}
          profileId={info.id}
        />
      )}
      <Styled.TextAndImageWrapper>
        <Styled.InputArea
          isInModal
          placeholder={isInNewQuote || isCreateModal ? 'Type here ..' : 'Type here'}
          setIsMoreThanMaxLength={(it: boolean) => setIsMoreThanMaxLength(it)}
          onChange={(value) => setText(value)}
          value={text}
          maxLength={POST.MAX_LENGTH}
          onShowDropdown={() => setIsScrollDisabled && setIsScrollDisabled(true)}
          onHideDropdown={() => setIsScrollDisabled && setIsScrollDisabled(false)}
          onPaste={onPaste}
          onDrop={onDrop}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <LinkPreviewForNewPost text={text} />
        {image && (
          <ImagePreviewComponent
            img={typeof image === 'string' ? image : URL.createObjectURL(new Blob([image]))}
            deleteHandler={() => setImage(null)}
          />
        )}
      </Styled.TextAndImageWrapper>
      <Styled.PostActionsContainer>
        <Styled.AttachImage src={AttachPhoto} alt="attach" onClick={() => uploadInputRef.current && uploadInputRef.current.click()} />
        <input ref={uploadInputRef} accept="image/*" onChange={uploadPhoto} style={{ display: 'none' }} type="file" />
        {/* <Styled.CloseImage src={replies.length ? CloseGrey : CloseBlack} alt="attach" onClick={closeHandler} /> */}
      </Styled.PostActionsContainer>
      {!!replies.length && <Styled.PostActionsDivider />}
      {!!info && !!replies?.length && (
        <Styled.RepliesContainer>
          {replies.map((reply, index) => (
            <NewPostReplyComponent
              setIsMoreThanMaxLength={setIsMoreThanMaxLength}
              reply={reply}
              setReplies={setReplies}
              onKeyDown={handleKeyDown}
              fullName={info.fullName}
              profileId={info.id}
              imageUrl={info.imageUrl}
              role={info.role}
              vertical={info.vertical}
              key={reply.id}
              isLast={index === replies.length - 1}
            />
          ))}
        </Styled.RepliesContainer>
      )}
      {actions}
    </>
  );
};
