import React, { useState } from 'react';
import PostOption from '@styles/icons/post-option.webp';
import BlackReplyToReplyIcon from '@styles/icons/black-reply-to-reply-icon.webp';
import BlackReplyIcon from '@styles/icons/black-reply-icon.webp';
import BlackQuotePostIcon from '@styles/icons/black-quote-post-icon.webp';
import BlackRepostIcon from '@styles/icons/black-repost-icon.webp';
import BlackLikeIcon from '@styles/icons/black-like-icon.webp';
import BlackBookmarkIcon from '@styles/icons/black-bookmark-icon.webp';
import BlackOptionIcon from '@styles/icons/black-option-icon.webp';
import GrayReplyToReplyIcon from '@styles/icons/gray-reply-to-reply-icon.webp';
import GreyReplyIcon from '@styles/icons/grey-reply-icon.webp';
import GreyQuotePostIcon from '@styles/icons/grey-quote-post-icon.webp';
import GreyRepostIcon from '@styles/icons/grey-repost-icon.webp';
import GreyLikeIcon from '@styles/icons/grey-like-icon.webp';
import GreyBookmarkIcon from '@styles/icons/grey-bookmark-icon.webp';
import GreyOptionIcon from '@styles/icons/grey-option-icon.webp';
import ReplyZero from '@styles/icons/reply-post-icon.webp';
import ReplyLIU from '@styles/icons/reply-post-active-icon.webp';
import ReplyZeroAnon from '@styles/icons/reply-zero-anon.webp';
import ReplyLIUAnon from '@styles/icons/reply-LIU-anon.webp';
import QuoteZero from '@styles/icons/quote-post-icon.webp';
import QuoteLIU from '@styles/icons/quote-post-active-icon.webp';
import QuoteZeroAnon from '@styles/icons/quote-post-zero-anon.webp';
import QuoteLIUAnon from '@styles/icons/quote-post-LIU-anon.webp';
import ReplyToReplyActive from '@styles/icons/reply-to-reply-icon-active.webp';
import RepostZero from '@styles/icons/repost-post-icon.webp';
import RepostZeroAnon from '@styles/icons/repost-zero-anon.webp';
import RepostLIU from '@styles/icons/repost-post-active-icon.webp';
import RepostLIUAnon from '@styles/icons/repost-LIU-anon.webp';
import BookmarkZero from '@styles/icons/bookmart-post-icon.webp';
import BookmarkZeroAnon from '@styles/icons/message-bookmark-anon.webp';
import BookmarkLIU from '@styles/icons/bookmart-post-active-icon.webp';
import BookmarkLIUAnon from '@styles/icons/message-bookmark-active-anon.webp';
import LikeZero from '@styles/icons/like-post-icon.webp';
import LikeZeroAnon from '@styles/icons/like-zero-anon.webp';
import LikeLIU from '@styles/icons/like-post-active-icon.svg';
import LikeLIUAnon from '@styles/icons/like-LIU-anon.webp';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as Styled from './post-stats.styled';
import { useCreateRepost, usePostBookmark, usePostStatistic } from '@/common/hooks';
import { useLike } from '@/common/hooks/use-like';
import { LikesClapsPraisesModalComponent } from '../likes-claps-praises-modal';
import { formatNumber } from '@/common/utils/post-statistics';
import { DateTimeOutput } from '../date';
import { IFilters, NewsfeedNavigation } from '@/common/types/newsfeed.type';
import { IMemoSection } from '@/common/types/post.type';
import { MemoDateTimeOutput } from '../date/memo-section-date-output';
import { PostStatsIdeaModal } from './post-stats-idea-modal/post-stats-idea-modal';

interface IPostStatsComponentProps {
  postId: string;
  isAnon?: boolean;
  isShortView?: boolean;
  profileId?: string;
  hideBookmarks?: boolean;
  date?: Date;
  isReply: boolean;
  optionHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  replyHandler?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  replyCountHandler?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  quotePostHandler?: (postId: string) => void;
  likeSuccessCustomCallback?: () => void;
  clapSuccessCustomCallback?: () => void;
  className?: string;
  isPublish?: boolean;
  activeTab?: NewsfeedNavigation;
  currentFilter?: IFilters;
  isBottonsDisabled?: boolean;
  memoSection?: IMemoSection;
  isMemoPost?: boolean;
  isIdeaModal?: boolean;
  memosSection?: any;
  isInModal?: boolean;
}

export const PostStatsComponent = ({
  postId,
  isAnon,
  isMemoPost,
  memosSection,
  isShortView,
  profileId,
  hideBookmarks,
  isInModal,
  date,
  isReply,
  optionHandler,
  replyHandler,
  replyCountHandler,
  quotePostHandler,
  likeSuccessCustomCallback,
  isIdeaModal,
  className,
  activeTab,
  currentFilter,
  isBottonsDisabled,
  memoSection
}: IPostStatsComponentProps) => {
  const [likesClapRepostModal, setLikesClapRepostsModal] = useState(false);
  const [isOptionsClicked, setIsOptionsClicked] = useState(false);
  const router = useRouter();
  const disablePathClick = router.pathname === '/idea/publish-idea/[id]';
  const { data: statistic } = usePostStatistic(postId);

  const { mutateAsync: actionLike } = useLike(likeSuccessCustomCallback);
  const { mutateAsync: actionBookmark } = usePostBookmark(activeTab, currentFilter);
  const { mutateAsync: createRepost, isLoading } = useCreateRepost(profileId);

  const repostClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (isBottonsDisabled) return;
    event.stopPropagation();
    if (!isLoading) {
      createRepost({ postId, image: null, isRepost: true, text: '', isPrivate: false });
    }
  };

  const optionClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isBottonsDisabled) return;
    event.stopPropagation();
    if (optionHandler) {
      setIsOptionsClicked(true);
      optionHandler(event);
      setTimeout(() => setIsOptionsClicked(false), 500);
    }
  };

  const likeClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (isBottonsDisabled) return;
    event.stopPropagation();
    if (postId) {
      actionLike({ postId });
    }
  };

  const bookmarkClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (isBottonsDisabled) return;
    event.stopPropagation();
    if (postId) {
      actionBookmark({ postId });
    }
  };

  const openLikesClapRepostsModal = () => {
    if (isBottonsDisabled) return;
    setLikesClapRepostsModal(true);
  };

  const onClickByQuote = () => !isShortView && !isBottonsDisabled && quotePostHandler && quotePostHandler(postId);

  if (isIdeaModal) {
    return (
      <PostStatsIdeaModal
        postId={postId}
        likesClapRepostModal={likesClapRepostModal}
        replyCountHandler={replyCountHandler}
        statistic={statistic}
        setLikesClapRepostsModal={setLikesClapRepostsModal}
        replyHandler={replyHandler}
        isAnon={isAnon}
        isReply={isReply}
        openLikesClapRepostsModal={openLikesClapRepostsModal}
        likeClick={likeClick}
        onClickByQuote={onClickByQuote}
        repostClick={repostClick}
        hideBookmarks={hideBookmarks}
        bookmarkClick={bookmarkClick}
        className={className}
        isMemoPost={isMemoPost}
      />
    );
  }

  return (
    <Styled.PostStatsWrapper className={className} isMemoPost={isMemoPost}>
      <LikesClapsPraisesModalComponent postId={postId} open={likesClapRepostModal} closeHandler={() => setLikesClapRepostsModal(false)} />
      <Styled.ButtonsContainerWrapper>
        <Styled.ButtonsContainer>
          <Styled.StatContainer>
            {isReply ? (
              isAnon ? (
                <>
                  <Styled.StatImage
                    src={ReplyToReplyActive}
                    alt="reply"
                    onClick={replyHandler}
                    style={{ visibility: statistic?.isReplied ? 'visible' : 'hidden' }}
                  />
                  <Styled.StatImage
                    src={ReplyZero}
                    alt="reply"
                    onClick={replyHandler}
                    style={{ visibility: statistic?.isReplied ? 'hidden' : 'visible' }}
                  />
                </>
              ) : (
                <>
                  <Styled.StatImage
                    src={ReplyLIU}
                    alt="reply"
                    onClick={replyHandler}
                    style={{ visibility: statistic?.isReplied ? 'visible' : 'hidden' }}
                  />
                  <Styled.StatImage
                    src={ReplyZero}
                    alt="reply"
                    onClick={replyHandler}
                    style={{ visibility: statistic?.isReplied ? 'hidden' : 'visible' }}
                  />
                </>
              )
            ) : isAnon ? (
              <>
                <Styled.StatImage
                  src={ReplyLIUAnon}
                  alt="reply"
                  onClick={replyHandler}
                  style={{ visibility: statistic?.isReplied ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={ReplyZeroAnon}
                  alt="reply"
                  onClick={replyHandler}
                  style={{ visibility: statistic?.isReplied ? 'hidden' : 'visible' }}
                />
              </>
            ) : (
              <>
                <Styled.StatImage
                  src={ReplyLIU}
                  alt="reply"
                  onClick={replyHandler}
                  style={{ visibility: statistic?.isReplied ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={ReplyZero}
                  alt="reply"
                  onClick={replyHandler}
                  style={{ visibility: statistic?.isReplied ? 'hidden' : 'visible' }}
                />
              </>
            )}
            {statistic?.replyCount ? (
              <Styled.StatCount isAnon={!!isAnon} onClick={replyCountHandler} isActive={statistic?.isReplied}>
                {formatNumber(statistic.replyCount)}
              </Styled.StatCount>
            ) : (
              <Styled.ZeroCountPlaceholder />
            )}
            <Styled.LabelItemOnHover isAnon={!!isAnon}>
              <Image
                src={isReply ? (isAnon ? GrayReplyToReplyIcon : BlackReplyToReplyIcon) : isAnon ? GreyReplyIcon : BlackReplyIcon}
                alt="Reply icon in explanation bubble"
                width={18}
                height={16}
              />
              <p>{isReply ? 'Reply to this reply' : 'Reply to this post'}</p>
            </Styled.LabelItemOnHover>
          </Styled.StatContainer>
          <Styled.StatContainer>
            {isAnon ? (
              <>
                <Styled.StatImage
                  src={LikeLIUAnon}
                  alt="like"
                  onClick={likeClick}
                  style={{ visibility: statistic?.isLiked ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={LikeZeroAnon}
                  alt="like"
                  onClick={likeClick}
                  style={{ visibility: !statistic?.isLiked ? 'visible' : 'hidden' }}
                />
              </>
            ) : (
              <>
                <Styled.StatImage
                  src={LikeLIU}
                  alt="like"
                  onClick={likeClick}
                  style={{ visibility: statistic?.isLiked ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={LikeZero}
                  alt="like"
                  onClick={likeClick}
                  style={{ visibility: !statistic?.isLiked ? 'visible' : 'hidden' }}
                />
              </>
            )}
            {statistic?.likeCount ? (
              <Styled.StatCount isAnon={!!isAnon} onClick={openLikesClapRepostsModal} isActive={statistic?.isLiked} isReposLikeOrClap>
                {formatNumber(statistic.likeCount)}
              </Styled.StatCount>
            ) : (
              <Styled.ZeroCountPlaceholder />
            )}

            <Styled.LabelItemOnHover isAnon={!!isAnon}>
              <Image src={isAnon ? GreyLikeIcon : BlackLikeIcon} alt="Like icon in explanation bubble" width={18} height={16} />
              <p>Like this post</p>
            </Styled.LabelItemOnHover>
          </Styled.StatContainer>
          <Styled.StatContainer>
            {isAnon ? (
              <>
                <Styled.StatImage
                  src={QuoteLIUAnon}
                  alt="quote post"
                  quote
                  onClick={onClickByQuote}
                  style={{ visibility: statistic?.isQuotePosted ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={QuoteZeroAnon}
                  alt="quote post"
                  quote
                  onClick={onClickByQuote}
                  style={{ visibility: statistic?.isQuotePosted ? 'hidden' : 'visible' }}
                />
              </>
            ) : (
              <>
                <Styled.StatImage
                  src={QuoteLIU}
                  alt="quote post"
                  quote
                  onClick={onClickByQuote}
                  style={{ visibility: statistic?.isQuotePosted ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={QuoteZero}
                  alt="quote post"
                  quote
                  onClick={onClickByQuote}
                  style={{ visibility: statistic?.isQuotePosted ? 'hidden' : 'visible' }}
                />
              </>
            )}
            {statistic?.quotePostCount ? (
              <Styled.StatCount isAnon={!!isAnon} onClick={onClickByQuote} isActive={statistic?.isQuotePosted}>
                {formatNumber(statistic.quotePostCount)}
              </Styled.StatCount>
            ) : (
              <Styled.ZeroCountPlaceholder />
            )}
            <Styled.LabelItemOnHover isAnon={!!isAnon}>
              <Image
                src={isAnon ? GreyQuotePostIcon : BlackQuotePostIcon}
                alt="Quote post icon in explanation bubble"
                width={18}
                height={16}
              />
              <p>Quote this post</p>
            </Styled.LabelItemOnHover>
          </Styled.StatContainer>
        </Styled.ButtonsContainer>
        <Styled.ButtonsContainer>
          <Styled.StatContainer>
            {isAnon ? (
              <>
                <Styled.StatImage
                  src={RepostLIUAnon}
                  alt="repost"
                  repost
                  onClick={repostClick}
                  style={{ visibility: statistic?.isReposted ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={RepostZeroAnon}
                  alt="repost"
                  repost
                  onClick={repostClick}
                  style={{ visibility: statistic?.isReposted ? 'hidden' : 'visible' }}
                />
              </>
            ) : (
              <>
                <Styled.StatImage
                  src={RepostLIU}
                  alt="repost"
                  repost
                  onClick={repostClick}
                  style={{ visibility: statistic?.isReposted ? 'visible' : 'hidden' }}
                />
                <Styled.StatImage
                  src={RepostZero}
                  alt="repost"
                  repost
                  onClick={repostClick}
                  style={{ visibility: statistic?.isReposted ? 'hidden' : 'visible' }}
                />
              </>
            )}
            {statistic?.repostCount ? (
              <Styled.StatCount isAnon={!!isAnon} onClick={openLikesClapRepostsModal} isActive={statistic?.isReposted} isReposLikeOrClap>
                {formatNumber(statistic.repostCount)}
              </Styled.StatCount>
            ) : (
              <Styled.ZeroCountPlaceholder />
            )}
            <Styled.LabelItemOnHover isAnon={!!isAnon}>
              <Image src={isAnon ? GreyRepostIcon : BlackRepostIcon} alt="Repost icon in explanation bubble" width={18} height={16} />
              <p>Re-post this post / Re-click to remove</p>
            </Styled.LabelItemOnHover>
          </Styled.StatContainer>

          {!hideBookmarks && (
            <Styled.StatContainer bookmark>
              {isAnon ? (
                <>
                  <Styled.StatImage
                    bookmark
                    src={BookmarkLIUAnon}
                    alt="bookmark"
                    onClick={bookmarkClick}
                    style={{ visibility: statistic?.isBookmarked ? 'visible' : 'hidden' }}
                  />
                  <Styled.StatImage
                    bookmark
                    src={BookmarkZeroAnon}
                    alt="bookmark"
                    onClick={bookmarkClick}
                    style={{ visibility: statistic?.isBookmarked ? 'hidden' : 'visible' }}
                  />
                </>
              ) : (
                <>
                  <Styled.StatImage
                    bookmark
                    src={BookmarkLIU}
                    alt="bookmark"
                    onClick={bookmarkClick}
                    style={{ visibility: statistic?.isBookmarked ? 'visible' : 'hidden' }}
                  />
                  <Styled.StatImage
                    bookmark
                    src={BookmarkZero}
                    alt="bookmark"
                    onClick={bookmarkClick}
                    style={{ visibility: statistic?.isBookmarked ? 'hidden' : 'visible' }}
                  />
                </>
              )}
              {statistic?.bookmarkCount ? (
                <Styled.StatCount isAnon={!!isAnon} isActive={statistic?.isBookmarked} isReposLikeOrClap>
                  {formatNumber(statistic.bookmarkCount)}
                </Styled.StatCount>
              ) : (
                <Styled.ZeroCountPlaceholder />
              )}

              <Styled.LabelItemOnHover isAnon={!!isAnon}>
                <Image
                  src={isAnon ? GreyBookmarkIcon : BlackBookmarkIcon}
                  alt="Bookmark icon in explanation bubble"
                  width={13}
                  height={16}
                />
                <p>Bookmark this post</p>
              </Styled.LabelItemOnHover>
            </Styled.StatContainer>
          )}
        </Styled.ButtonsContainer>
      </Styled.ButtonsContainerWrapper>
      {!isIdeaModal ? (
        <Styled.PostOptionWrapper>
          {date && !memoSection?.memo?.backDateToggle && <DateTimeOutput date={date} />}
          {date && memoSection?.memo?.backDateToggle && (
            <MemoDateTimeOutput summaryTooltipPosition={!!memosSection} date={date} memoSection={memoSection} isInModal={isInModal} />
          )}

          {date && !memoSection?.memo?.backDateToggle && (
            <Styled.PostOptionIconWrapper
              onClick={disablePathClick ? undefined : optionClick}
              style={{ cursor: disablePathClick ? 'default' : 'pointer' }}
            >
              <Styled.PostOptionIcon src={PostOption} alt="option" />
              {!isOptionsClicked && (
                <Styled.LabelItemOnHover isAnon={!!isAnon}>
                  <p>Options</p>
                  <Image
                    src={isAnon ? GreyOptionIcon : BlackOptionIcon}
                    alt="Options icon in explanation bubble"
                    width={16}
                    height={16}
                    style={{ padding: '6px 0' }}
                  />
                </Styled.LabelItemOnHover>
              )}
            </Styled.PostOptionIconWrapper>
          )}
        </Styled.PostOptionWrapper>
      ) : (
        <Styled.PostOptionWrapper>
          {memosSection?.[0].memoSection.memo.backDateToggle ? (
            <MemoDateTimeOutput isBackDate isInModal={isInModal} memoSection={memosSection} />
          ) : (
            date && (
              <>
                <DateTimeOutput date={date} />
                <Styled.PostOptionIconWrapper
                  onClick={disablePathClick ? undefined : optionClick}
                  style={{ cursor: disablePathClick ? 'default' : 'pointer' }}
                >
                  <Styled.PostOptionIcon src={PostOption} alt="option" />
                  {!isOptionsClicked && (
                    <Styled.LabelItemOnHover isAnon={!!isAnon}>
                      <p>Options</p>
                      <Image
                        src={isAnon ? GreyOptionIcon : BlackOptionIcon}
                        alt="Options icon in explanation bubble"
                        width={16}
                        height={16}
                        style={{ padding: '6px 0' }}
                      />
                    </Styled.LabelItemOnHover>
                  )}
                </Styled.PostOptionIconWrapper>
              </>
            )
          )}
        </Styled.PostOptionWrapper>
      )}
    </Styled.PostStatsWrapper>
  );
};
