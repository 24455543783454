import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { BEAR_ANSWER, BULL_ANSWER } from '@/common/consts/idea.const';

export const PostIdeaWrapper = styled.div``;

export const IdeaTextAndHorizon = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
`;

export const IdeaTextTitle = styled.div<{ isQuote?: boolean; isFaded?: boolean; isClicable: boolean }>`
  ${({ isClicable }) => isClicable && 'cursor: pointer;'}
  color: #7c7c7c;
  ${({ isQuote }) => isQuote && 'color: rgba(124, 124, 124, 0.75);'}
  ${({ isFaded }) => isFaded && 'color: rgba(124, 124, 124, 0.40);'}
`;

const COVERAGE_TEXT_WIDTH = 60;
const HORIZON_TEXT_WIDTH = 135;

export const IdeaText = styled.div<{ priority: number; isQuote?: boolean; isFaded?: boolean; isClicable: boolean; isHasHirazen: boolean }>`
  ${({ isClicable }) => isClicable && 'cursor: pointer;'}
  color: ${COLORS.black};
  ${({ priority }) => (priority === BEAR_ANSWER ? 'color: rgba(77, 14, 180, 1);' : '')}
  ${({ priority }) => (priority === BULL_ANSWER ? 'color: rgba(0, 146, 137, 1);' : '')}
  ${({ isQuote }) => isQuote && 'color: rgba(0, 0, 0, 0.75);'}
  ${({ priority, isQuote }) => priority === BEAR_ANSWER && isQuote && 'color: rgba(77, 14, 180, 0.75);'}
  ${({ priority, isQuote }) => priority === BULL_ANSWER && isQuote && 'color: rgba(0, 146, 137, 0.75);'}

  ${({ isFaded }) => isFaded && 'color: rgba(0, 0, 0, 0.4);'}
  ${({ priority, isFaded }) => priority === BEAR_ANSWER && isFaded && 'color: rgba(77, 14, 180, 0.4);'}
  ${({ priority, isFaded }) => priority === BULL_ANSWER && isFaded && 'color: rgba(0, 146, 137, 0.4);'}
  max-width: ${({ isHasHirazen }) =>
    isHasHirazen ? `calc(100% - ${COVERAGE_TEXT_WIDTH}px - ${HORIZON_TEXT_WIDTH}px)` : `calc(100% - ${COVERAGE_TEXT_WIDTH}px)`};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const IdeaDot = styled.div`
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #cfcfcf;
`;

export const IdeaHorizon = styled.div<{ isQuote?: boolean; isFaded?: boolean }>`
  color: #616161;
  ${({ isQuote }) => isQuote && 'color: rgba(97, 97, 97, 0.75);'}
  ${({ isFaded }) => isFaded && 'color: rgba(97, 97, 97, 0.4);'}
`;

export const ReasonsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 5px;
`;

export const IdeaOutputText = styled.div<{ isFaded?: boolean; isAnon: boolean }>`
  margin-top: 5px;
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
  ${({ isFaded }) => isFaded && 'color: rgba(0, 0, 0, 0.4);'}
  ${({ isAnon }) => isAnon && 'color: #fff;'}

  word-wrap: normal;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const ReasonItem = styled.div<{ priority: number; isQuote?: boolean; isFaded?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  padding: 2px;
  border-radius: 3px;
  background: ${COLORS.gray95};
  color: ${COLORS.black};
  ${({ priority }) => (priority === BEAR_ANSWER ? 'background: rgba(77, 14, 180, 0.03); color: rgba(77, 14, 180, 0.7);' : '')}
  ${({ priority }) => (priority === BULL_ANSWER ? 'background: rgba(0, 146, 137, 0.03); color: rgba(0, 146, 137, 0.7);' : '')}

  ${({ isQuote }) => isQuote && 'background: rgba(0, 0, 0, 0.02); color: rgba(0, 0, 0, 0.35);'}
  ${({ priority, isQuote }) =>
    priority === BEAR_ANSWER && isQuote && 'background: rgba(77, 14, 180, 0.02); color: rgba(77, 14, 180, 0.35);'}
  ${({ priority, isQuote }) =>
    priority === BULL_ANSWER && isQuote && 'background: rgba(0, 146, 137, 0.02); color: rgba(0, 146, 137, 0.35);'}

  ${({ isFaded }) => isFaded && 'background: rgba(0, 0, 0, 0.03); color: rgba(0, 0, 0, 0.4);'}
  ${({ priority, isFaded }) => priority === BEAR_ANSWER && isFaded && 'background: rgba(77, 14, 180, 0.03); color: rgba(77, 14, 180, 0.4);'}
  ${({ priority, isFaded }) => priority === BULL_ANSWER && isFaded && 'background: rgba(0, 146, 137, 0.03); color: rgba(0, 146, 137, 0.4);'}
`;
