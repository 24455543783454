import styled from 'styled-components';
import { COLORS, SPACES } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import { subSectorsWidths } from './vertical-role-modal.const';

export const Wrapper = styled.div`
  width: 770px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  font-weight: ${WEIGHTS.light};
  border-radius: 10px 10px 0px 10px;
`;

export const HeadRow = styled.div`
  margin: 10px 0;
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-size: 12px;
  line-height: 12px;
  color: ${COLORS.headTextColorInModal};
`;

interface IRowProps {
  isVertical?: boolean;
  isSubVertical?: boolean;
  isRoles?: boolean;
  isNonFinenceSubVertical?: boolean;
  isNonFinenceSubSector?: string;
  isOnEditPage?: boolean;
  isCreateAnonVertical?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getSubSectorWidth = (sector: string): string => subSectorsWidths[`${sector}`];

export const Row = styled.div<IRowProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  line-height: 14px;
  align-items: start;
  justify-content: center;
  color: ${COLORS.secondLightGray};
  width: max-content;
  ${({ isCreateAnonVertical }) =>
    isCreateAnonVertical &&
    `
   justify-content: start;
`}
  ${({ isNonFinenceSubVertical }) =>
    isNonFinenceSubVertical &&
    `
  max-width: 500px;
    align-items: start;
  justify-content: start;
  margin-top: -2px;
  `}
  ${({ isOnEditPage }) =>
    isOnEditPage &&
    `
      align-items: flex-start;
      justify-content: flex-start;
      max-width:920px;
      gap:3px;`}
`;

export const Dropdown = styled.div`
  margin-top: 5px;
  width: max-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 30px;
  background-color: #fdfdfd;
  gap: 5px;
  padding: 5px;
  left: -5px;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  z-index: 11;
  opacity: 100%;
`;

export const DropdownItem = styled.button`
  cursor: pointer;
  width: fit-content;
  text-align: start;
  padding: 5px 12px;
  background-color: #fafafa;
  border: none;
  height: 30px;
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.light};
  font-size: 14px;
  color: #3f3f3f;
  border-radius: 5px;
  line-height: 20px;
`;

export const Item = styled.button<{
  isActive: boolean;
  isDisabled: boolean;
  isOnEditPage?: boolean;
  isNonFinance?: boolean;
  isTelecomsMedia?: boolean;
  isCableTelecoms?: boolean;
}>`
  position: relative;
  display: flex;
  gap: 3px;
  align-items: center;
  cursor: pointer;
  background: ${COLORS.opacityZero};
  color: #3f3f3f;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 20px;
  border: 0;
  background: #fafafa;
  ${({ isDisabled }) => isDisabled && 'color: rgba(0, 0, 0, 0.25);'}
  ${({ isDisabled }) => isDisabled && 'background: rgba(255, 255, 255, 0.50);'}
  border-radius: 5px;
  padding: 5px 12px;
  display: flex;
  ${({ isActive }) => isActive && 'background-color: #EFF1FF; color: #0016D8;'}
  ${({ isNonFinance, isActive }) => {
    if (isNonFinance && isActive) {
      return `
        width: 122px;
        background-color: #EFF1FF;
        color: #0016D8;
      `;
    }
  }}
  ${({ isCableTelecoms }) => {
    if (isCableTelecoms) {
      return `
        width: 182px;
      `;
    }
  }}
  ${({ isCableTelecoms, isActive }) => {
    if (isCableTelecoms && isActive) {
      return `
        width:  201px;
        background-color: #EFF1FF;
        color: #0016D8;
      `;
    }
  }}
  ${({ isTelecomsMedia, isActive }) => {
    if (isTelecomsMedia && isActive) {
      return `
        width: 154px;
        background-color: #EFF1FF;
        color: #0016D8;
      `;
    }
  }}
  ${({ isOnEditPage, isDisabled, isActive }) =>
    isOnEditPage &&
    `
  font-weight: 300;
    padding:3px 10px;
    border:1px solid ${isDisabled ? 'rgba(239, 239, 239, 1)' : isActive ? 'rgba(80, 80, 80, 1)' : 'rgba(239, 239, 239, 1)'};
  `}
`;

export const JoinVillanButtonSection = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 25px;
  grid-column: span 3;
  align-self: start;
`;

export const JoinVillanButton = styled.button<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#00a8801a' : '#00A880')};
  color: white;
  width: 118px;
  height: 36px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
`;

export const JoinVillanPressEnter = styled.button<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#00a8801a' : '#00A880')};
  background-color: transparent;
  font-size: 14px;
  height: 36px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Input = styled.input<{ isOnEditPage?: boolean }>`
  grid-column: span 3;
  flex-basis: 60%;
  margin-top: 3px;
  margin-left: -1px;
  ${({ isOnEditPage }) =>
    isOnEditPage
      ? `
    :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  margin: ${SPACES.xs} 0;
  border: none;
  width: 100%;
  color: ${COLORS.black};
  letter-spacing: ${SPACES.mediumLetterSpacing};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SPACES.m};
  line-height: ${SPACES.m};
  letter-spacing: 0rem;
  outline: none;

  ::placeholder {
    color: ${COLORS.secondLightGray};
  }
    margin: 0px;
  padding: 7px 5px 3px 5px;
  font-size: 14px;
  color: #000;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  ::placeholder {
    color: #000;
    opacity: 1;
  }
  `
      : `

  display: flex;
  padding: 10px;
  align-items: center;


  border-radius: 10px;
  border: 1px solid #F5F5F5;
  background-color: #FBFBFB;

  color: #000;

  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  ::placeholder {
    color: #000;
    opacity: 1;
    font-weight: ${WEIGHTS.light};
  }

  :focus-visible {
    outline: 0;
  }`}
`;
