import { Menu } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const SourceButton = styled.button<{ isNotSelected: boolean }>`
  color: #000;
  ${({ isNotSelected }) => isNotSelected && 'color: #CCC;'}
  text-align: start;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 10px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: 20px;
  border: none;
  background-color: transparent;
  padding: 6px 0 4px 0;
  width: 60px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const DropdownMenu = styled(Menu)`
  & > div {
    border: none;
    margin-left: -5px;
    box-shadow: none;
    > ul {
      margin-top: 10px;
      border: 1px solid #f4f4f4;
      border-radius: 10px;
      display: inline-flex;
      padding: 5px;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      background-color: ${COLORS.white};
      gap: 5px;
      width: 104px;
      > button {
        color: #3f3f3f;
        font-family: ${FONTS.FAMILIES.robotoFlex};
        font-size: 13px;
        font-style: normal;
        font-weight: ${FONTS.WEIGHTS.light};
        line-height: 20px;
        display: flex;
        padding: 5px 12px;
        justify-content: start;
        align-items: center;
        gap: 5px;
        border-radius: 5px;
        border: 1px solid #f6f6f6;
        background: #fafafa;
        width: 100%;
        cursor: pointer;
        > span {
          text-align: start;
          width: 45px;
          color: #008666;
        }
        > p {
          text-align: start;
          width: 45px;
          color: #e10000;
        }
      }
    }
  }
`;
