import styled from 'styled-components';
import { WEIGHTS } from '@/theme/fonts.const';
import Image from 'next/image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  color: white;
  font-family: Roboto Flex;
  font-size: 12px;
  font-style: normal;
  font-weight: ${WEIGHTS.semiBold};
  line-height: 12px;
  white-space: pre-wrap;
`;

export const Point = styled.div<{ isActive: boolean, isDotted: boolean, isFirst: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#80d3bf' : '#EFEFEF')};
  padding: 4px 6.5px;
  border-radius: 7px;
  height: ${({ isDotted, isFirst }) => (isDotted && isFirst ? '17px' : '20px')};
  width: ${({ isDotted, isFirst }) => (isDotted && isFirst ? '18px' : '20px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div<{ height: string; isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#80d3bf' : '#EFEFEF')};
  height: ${({ height }) => height};
  width: 2px;
`;

export const DottedLine = styled(Image)`

`