import { useEffect, useState } from 'react';

export const useSidebarWidth = (): number => {
  const [sidebarWidth, setSidebarWidth] = useState<number>(0);

  useEffect(() => {
    let sidebarElement: Element | null = document.querySelector('[data-id="sidebar"]');

    const handleResize = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      setSidebarWidth(entry.contentRect.width);
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (sidebarElement) {
      resizeObserver.observe(sidebarElement);
    }

    const handleMutation = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          sidebarElement = document.querySelector('[data-id="sidebar"]');
          if (sidebarElement) {
            resizeObserver.observe(sidebarElement);
            setSidebarWidth(sidebarElement.getBoundingClientRect().width); // Устанавливаем начальную ширину
          } else {
            setSidebarWidth(0); // Сайдбар исчез
          }
        }
      }
    };

    const mutationObserver = new MutationObserver(handleMutation);

    // Наблюдаем за изменениями в DOM, чтобы отследить появление/исчезновение сайдбара
    mutationObserver.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (sidebarElement) {
        resizeObserver.unobserve(sidebarElement);
      }
      mutationObserver.disconnect();
    };
  }, []);

  return sidebarWidth;
};
