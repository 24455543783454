import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { PageWrapperComponent } from '../page-wrapper';
import { BoxAroundSection } from '../page-wrapper/components/box-around-section';
import { FAMILIES } from '@/theme/fonts.const';
import { RightColumnStyle } from '../page-wrapper/components/right-column/right-column.styled';

export const PollPageWrapper = styled(PageWrapperComponent as AnyStyledComponent)``;

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 40px;
`;

export const LeftColumn = styled.div`
  ${RightColumnStyle}

  max-width: none;
  padding: 0 0 0 0;
  width: 590px;

  background-color: transparent;
`;

export const CmdIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const KeyText = styled.p`
  color: #0094d3;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

export const RightColumn = styled.div`
  ${RightColumnStyle}
  width: 400px;
  background-color: transparent;
  padding: 15px 0 0 0;
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  border: none;
`;

export const SearchWrapper = styled.div<{ marginTop?: boolean }>`
  display: flex;
  padding: 8px;
  height: 49px;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
  align-self: stretch;
  border-radius: 20px;
  border: 1.5px solid rgba(0, 148, 211, 0.75);
  background: rgba(246, 246, 246, 0.5);
`;

export const SearchIcon = styled(Image)`
  width: 30px;
  height: 30px;
`;

export const SearchInput = styled.input`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  &::placeholder {
    opacity: 1;
    color: #4d4d4d;
  }
`;

export const PollFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: #fbfbfb;
`;

export const PollFilterTitle = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.28px;
`;

export const PollFilterRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const PollFilterRow = styled.div`
  display: flex;
  width: 350px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
`;

export const PollFilterLine = styled.div`
  border: 1px solid #f6f6f6;
  width: 100%;
`;

export const PollFilterDivider = styled.div`
  width: 350px;
  height: 1px;
  background-color: #f6f6f6;
`;

export const PollFilterRowTitle = styled.div`
  display: flex;
  padding: 7px 5px 7px 0px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  color: #2c67ff;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const PollFilterRowItem = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  display: flex;
  padding: ${({ isActive, isDisabled }) => (!isActive && !isDisabled ? '6px 12px' : '7px 13px')};
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  ${({ isActive, isDisabled }) => !isActive && !isDisabled && 'border: 1px solid rgba(228, 228, 228, 0.75);'}
  background-color: ${({ isActive, isDisabled }) =>
    isDisabled ? 'rgba(228, 228, 228, 0.2)' : isActive ? '#d4e0ff' : 'rgba(228, 228, 228, 0.4)'};
  color: ${({ isActive, isDisabled }) => (isDisabled ? '#C5C5C5' : isActive ? '#003FE1' : '#717171')};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
`;

export const FilterButtonImage = styled(Image)`
  width: 10px;
  height: 10px;
`;

export const SectionDivider = styled.div`
  width: 324px;
  height: 1px;
  background: #e8e8e8;
  margin-left: 46px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 0.7px;
`;

export const HomePageDivider = styled.div`
  background-color: ${COLORS.dividerColor};
  margin: 2.5rem 7.5rem 2.5rem 7rem;
  height: 3px;
`;

export const titleSectionStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  letter-spacing: 0.3px;
`;

export const descriptionSectionStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.descriptionColorInBlock};
`;

export const AddButton = styled.button`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  color: #0018f1;
  background: #e9eaf5;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddButtonIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

export const SentimentSwingsWrapper = styled(BoxAroundSection)``;

export const HeaderContainer = styled.div`
  padding: 15px 0;
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: -46px;
    width: 1px;
    height: 101vh;
    background-color: #efefef;
    z-index: 2;
  }
`;

export const Title = styled.div<{ isAnon?: boolean }>`
  ${titleSectionStyle}
  color: ${({ isAnon }) => (isAnon ? '#7400CF' : COLORS.black)};
  font-size: 15px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  width: fit-content;
`;

export const AddPollButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 556px;
  height: 49px;
  margin: 0 17px;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1.5px solid #9aa4f9;
  background: rgba(209, 213, 255, 0.35);
`;

export const AddPolText = styled.p`
  color: #0018f1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const AddPolIcon = styled(Image)`
  width: 30px;
  height: 30px;
`;
