export const getRangeOfYearsEnd = (counter: number, date?: Date | string | null, saveDate?: Date | null) => {
  const n = 10;
  let years: number[] = [];
  const dateLocal = typeof date === 'string' ? new Date(date) : date;
  if (dateLocal) {
    const minYear = dateLocal.getFullYear();
    const maxYear = new Date().getFullYear();
    let from = maxYear - n * counter;
    const to = from - n;
    while (from > to) {
      if (from < minYear) {
        break;
      }
      years.push(from);
      from -= 1;
    }
  } else {
    const minYear = 1940;
    const maxYear = new Date().getFullYear();
    let from = maxYear - n * counter;
    const to = from - n;

    while (from > to) {
      if (from < minYear) {
        break;
      }
      years.push(from);
      from -= 1;
    }
  }
  return years;
};
