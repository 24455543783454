import React, { useRef, useEffect, FormEvent, useState, useMemo } from 'react';
import { ClickAwayListener } from '@mui/material';
import { debounce } from 'lodash';
import { IMentionOption, MentionType } from '@/common/types/post.type';
import { useMentionSearch } from '@/common/hooks/use-algolia';
import { COLORS } from '@/theme';
import * as Styled from './mention-input.styled';
import { coverageService } from '@/common/services';

declare global {
  interface Window {
    InstallTrigger: any;
  }
}

interface IProps {
  maxLength: number;
  value: string;
  isInModal?: boolean;
  disabled?: boolean;
  autoFocus?: number | boolean; // if number and more than zero then focus
  setIsMoreThanMaxLength?: (it: boolean) => void;
  onChange: (value: string) => void;
  onPaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  placeholder: string;
  onShowDropdown?: () => void;
  onHideDropdown?: () => void;
  className?: string;
}

const trigger = '@';

const mentionPrefix = {
  [MentionType.USER]: 'member',
  [MentionType.EMPLOYER]: 'employer',
  [MentionType.SECURITY]: 'securities',
  [MentionType.SECTOR]: 'sector',
  [MentionType.SUB_SECTOR]: 'sub-sector',
  [MentionType.PRIVATE]: 'privates'
};

export const MentionInputComponent = ({
  maxLength,
  placeholder,
  value,
  isInModal,
  autoFocus,
  disabled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsMoreThanMaxLength,
  onChange,
  onPaste,
  onDrop,
  onKeyDown,
  onShowDropdown,
  onHideDropdown,
  className
}: IProps) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<{ left: number; top: number } | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [lastCaretPosition, setLastCaretPosition] = useState(0);
  const [lastTriggerPosition, setLastTriggerPosition] = useState(0);
  const [inputText, setInputText] = useState(value);
  const [searchText, setSearchText] = useState('');
  const mentionsRef = useRef<IMentionOption[]>([]);
  const [mentionsOptions, setMentionOptions] = useState<IMentionOption[]>([]);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(0);
  const { data: mentionData, mutateAsync: mentionSearch } = useMentionSearch();

  useEffect(() => {
    if (!value.length) {
      setInputText(value);
      if (editorRef?.current?.innerHTML) {
        editorRef.current.innerHTML = value;
      }
    }
  }, [value]);

  useEffect(() => {
    if (!showDropdown) {
      setDropdownPosition(null);
      return;
    }

    if (showDropdown && editorRef.current?.innerHTML && window) {
      const element = document.getElementById('dropdownRef');
      if (!element) return;
      setDropdownPosition({ left: element.offsetLeft, top: element.offsetTop });
    }
  }, [editorRef.current?.innerHTML, showDropdown]);

  useEffect(() => {
    if (showDropdown && onShowDropdown) {
      onShowDropdown();
    }

    if (!showDropdown && onHideDropdown) {
      onHideDropdown();
    }
  }, [showDropdown]);

  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      if (!searchText.trim()) return;
      await mentionSearch(searchText.trim());
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText]);

  useEffect(() => {
    if (mentionData) {
      setActiveOptionIndex(0);
      setMentionOptions(mentionData);
    }
  }, [mentionData]);

  useEffect(() => {
    if (dropdownRef.current) {
      const selected = dropdownRef?.current?.querySelector('.active');
      selected?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [activeOptionIndex]);

  useEffect(() => {
    if ((typeof autoFocus === 'boolean' && autoFocus) || (typeof autoFocus === 'number' && (autoFocus ?? 0) > 0)) {
      editorRef.current?.focus();
    }
  }, [autoFocus]);

  const typeWidth = useMemo(() => {
    const types = mentionsOptions.map((item) => item.type);

    if (types.includes(MentionType.SUB_SECTOR)) {
      return 73;
    }
    if (types.includes(MentionType.SECURITY)) {
      return 67;
    }
    if (types.includes(MentionType.EMPLOYER)) {
      return 65;
    }
    if (types.includes(MentionType.USER)) {
      return 60;
    }

    return 48;
  }, [mentionsOptions]);

  const getTextSegments = (element: HTMLElement): { text: string; node: Node }[] => {
    const textSegments: { text: string; node: Node }[] = [];
    Array.from(element.childNodes).forEach((node) => {
      switch (node.nodeType) {
        case Node.TEXT_NODE:
          textSegments.push({ text: node.nodeValue || '', node });
          break;
        case Node.ELEMENT_NODE:
          textSegments.splice(textSegments.length, 0, ...getTextSegments(node as HTMLElement));
          break;
      }
    });
    return textSegments;
  };

  const restoreSelection = (absoluteAnchorIndex: number | null, absoluteFocusIndex: number | null) => {
    const sel = window.getSelection();
    if (!editorRef.current || !sel) return;
    const textSegments = getTextSegments(editorRef.current);
    let anchorNode: HTMLDivElement | Node = editorRef.current;
    let anchorIndex = 0;
    let focusNode: HTMLDivElement | Node = editorRef.current;
    let focusIndex = 0;
    let currentIndex = 0;
    textSegments.forEach(({ text, node }) => {
      const startIndexOfNode = currentIndex;
      const endIndexOfNode = startIndexOfNode + text.length;
      if (startIndexOfNode <= absoluteAnchorIndex! && absoluteAnchorIndex! <= endIndexOfNode) {
        anchorNode = node;
        anchorIndex = absoluteAnchorIndex! - startIndexOfNode;
      }
      if (startIndexOfNode <= absoluteFocusIndex! && absoluteFocusIndex! <= endIndexOfNode) {
        focusNode = node;
        focusIndex = absoluteFocusIndex! - startIndexOfNode;
      }
      currentIndex += text.length;
    });
    sel?.setBaseAndExtent(anchorNode, anchorIndex, focusNode, focusIndex);
  };

  const removeHtmlTags = (input: string) => {
    const regex = /<[^>]*>/g;
    return input.replace(regex, '');
  };

  // const splitTextWithTags = (inputTextData: string) => {
  //   const regex = /(<[^>]*>|\b[^<>\s]+\b|\s)/g;

  //   const matches = inputTextData.match(regex);

  //   console.log(matches, 'ma');

  //   const resultArray = matches?.map((match) => ({
  //     type: match.startsWith('<') && match.endsWith('>') ? 'tag' : 'text',
  //     content: match
  //   }));
  //   if (!resultArray) return inputTextData;
  //   let isTagOpen = false;
  //   let isMoreThenMax = false;
  //   let letterCount = 0;

  //   for (let i = 0; i < resultArray.length; i += 1) {
  //     const it = resultArray[i];
  //     if (it.type === 'tag' && !isTagOpen) {
  //       isTagOpen = true;
  //     } else if (it.type === 'tag' && isTagOpen) {
  //       isTagOpen = false;
  //     } else if (it.type === 'text') {
  //       if (letterCount + it.content.length > maxLength && isTagOpen) {
  //         resultArray[i - 1].content = `<span style="color: red">${resultArray[i - 1].content}`;
  //         isMoreThenMax = true;
  //         break;
  //       } else if (letterCount + it.content.length === maxLength && i < resultArray.length) {
  //         resultArray[i].content = `${resultArray[i].content}<span style="color: red">`;
  //         isMoreThenMax = true;
  //         break;
  //       } else if (letterCount + it.content.length > maxLength) {
  //         const splitCount = maxLength - letterCount;
  //         resultArray[i].content = `${resultArray[i].content.slice(0, splitCount - 1)}<span style="color: red">${resultArray[
  //           i
  //         ].content.slice(splitCount - 1)}`;
  //         isMoreThenMax = true;
  //         break;
  //       }
  //       letterCount += it.content.length;
  //     }
  //   }

  //   if (isMoreThenMax) {
  //     resultArray.push({ type: 'text', content: '</span>' });
  //   }

  //   return resultArray.map((it) => it.content).join('');
  // };

  // function highlightText(inputText) {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(inputText, 'text/html');

  //   const resultArray = [];

  //   function processNode(node) {
  //     if (node.nodeType === Node.ELEMENT_NODE) {
  //       console.log(node, 'node');
  //       for (const childNode of node.childNodes) {
  //         console.log(childNode, 'childNode');
  //         resultArray.push(childNode);
  //       }
  //     }
  //   }

  //   processNode(doc.body);

  //   // Повертаємо відформатований HTML
  //   return doc.documentElement.outerHTML;
  // }
  const renderText = (text: string, caretPosition: number): string => {
    let newText = text;
    const isMaxExceeded = false;
    // if (text.length > maxLength) {
    //   // newText = `${newText.slice(0, maxLength)}<span style="color: ${maxLengthColor}">${newText.slice(maxLength)}</span>`;
    //   isMaxExceeded = true;
    //   setIsMoreThanMaxLength(true);
    // } else {
    //   setIsMoreThanMaxLength(false);
    // }

    const color = COLORS.mention;
    const mentionColorMoreThanMax = COLORS.purple;

    if (text[caretPosition - 1] === trigger) {
      newText = `${newText.slice(0, caretPosition)}<span id='dropdownRef' />${newText.slice(caretPosition)}`;
      newText = `${newText.slice(0, caretPosition - 1)}<span style="color: ${
        isMaxExceeded ? mentionColorMoreThanMax : color
      }">${trigger}</span>${newText.slice(caretPosition)}`;
      setShowDropdown(true);
    } else if (showDropdown) {
      newText = `${newText.slice(0, lastTriggerPosition - 1)}<span style="color: ${
        isMaxExceeded ? mentionColorMoreThanMax : color
      }">${newText.slice(lastTriggerPosition - 1, caretPosition + 1)}</span>${newText.slice(caretPosition + 1)}`;
    }

    let searchIndex = 0;
    mentionsRef.current.forEach((item) => {
      const index = newText.indexOf(trigger + item.label, searchIndex);
      searchIndex = index + item.label.length + 1;
      const textWithoutTags = removeHtmlTags(newText).slice(0, searchIndex);

      const textTemp = `${newText.slice(0, index)}<span style="color: ${
        textWithoutTags.length + item.label.length > maxLength ? mentionColorMoreThanMax : color
      }">${`${trigger}${item.label}`}</span>${newText.slice(searchIndex)}`;
      searchIndex += textTemp.length - newText.length;
      newText = textTemp;
    });

    return newText;
  };

  const handleOnChange = (text: string) => {
    let textWithData = text;
    let searchIndex = 0;
    mentionsRef.current.forEach((item) => {
      const index = textWithData.indexOf(trigger + item.label, searchIndex);
      searchIndex = index + item.label.length + 1;
      const textTemp = `${textWithData.slice(0, index)}![${`${trigger}${item.label}`}{${item.type}|${item.value}}]${textWithData.slice(
        searchIndex
      )}`;
      searchIndex += textTemp.length - textWithData.length;
      textWithData = textTemp;
    });

    onChange(textWithData);
  };

  const inputHandler = (e?: FormEvent<HTMLDivElement>, valueFocusIndex?: number) => {
    const sel = window.getSelection();
    if (!editorRef.current || !sel) return;
    const textSegments = getTextSegments(editorRef.current);
    let textContent = textSegments.map(({ text }) => text).join('');

    if (textContent.length > maxLength) {
      textContent = textContent.slice(0, maxLength);
      valueFocusIndex = maxLength;
    }

    setInputText(textContent);

    let anchorIndex: number | null = null;
    let focusIndex: number | null = null;
    let currentIndex = 0;
    textSegments.forEach(({ text, node }) => {
      if (node === sel.anchorNode) {
        anchorIndex = currentIndex + sel.anchorOffset;
      }
      if (node === sel.focusNode) {
        focusIndex = currentIndex + sel.focusOffset;
      }
      currentIndex += text.length;
    });

    const caretPosition = valueFocusIndex || anchorIndex || focusIndex || 0;
    setLastCaretPosition(caretPosition || 0);

    if (caretPosition !== null) {
      if (textContent[caretPosition - 1] === trigger) {
        setShowDropdown(true);
        setLastTriggerPosition(caretPosition);
      }
    }

    if (showDropdown && textContent[lastTriggerPosition - 1] !== trigger) {
      setShowDropdown(false);
    } else if (showDropdown && textContent[caretPosition - 1] === ' ' && textContent[caretPosition - 2] === trigger) {
      setShowDropdown(false);
    } else if (showDropdown) {
      setSearchText(textContent.slice(lastTriggerPosition, caretPosition + 1));
    }

    const textTriggers = textContent.split(trigger);
    const mentions = [...mentionsRef.current];
    const newMentions: IMentionOption[] = [];
    textTriggers.shift();

    let index = 0;
    textTriggers.forEach((item) => {
      const isMention = mentions.some((mentionValue) => item.slice(0, mentionValue.label.length) === mentionValue.label);
      if (!isMention) return;

      const check = () => {
        if (index >= mentions.length) return;
        if (item.slice(0, mentions[index].label.length) === mentions[index].label) {
          newMentions.push(mentions[index]);
          return;
        }
        index += 1;
        check();
      };

      check();

      index += 1;
    });

    mentionsRef.current = newMentions;
    if (textContent) {
      editorRef.current.innerHTML = renderText(textContent, caretPosition);
    }
    restoreSelection(valueFocusIndex || anchorIndex, valueFocusIndex || focusIndex);

    handleOnChange(textContent);
  };

  const backspaceHandler = (e?: FormEvent<HTMLDivElement>, valueFocusIndex?: number) => {
    const sel = window.getSelection();
    if (!editorRef.current || !sel) return;
    const textSegments = getTextSegments(editorRef.current);

    let anchorIndex: number | null = null;
    let focusIndex: number | null = null;
    let currentIndex = 0;
    textSegments.forEach(({ text, node }) => {
      if (node === sel.anchorNode) {
        anchorIndex = currentIndex + sel.anchorOffset;
      }
      if (node === sel.focusNode) {
        focusIndex = currentIndex + sel.focusOffset;
      }
      currentIndex += text.length;
    });

    const textContent = textSegments.map(({ text }) => text).join('');
    let isDeleteSelectText = false;

    let newTextContent = textContent;
    if (anchorIndex !== null && focusIndex !== null && anchorIndex > focusIndex) {
      const leftSide = textContent.split('').slice(0, focusIndex);
      const rightSide = textContent.split('').slice(anchorIndex);
      newTextContent = leftSide.concat(rightSide).join('');
      isDeleteSelectText = true;
    } else if (anchorIndex !== null && focusIndex !== null && anchorIndex < focusIndex) {
      const leftSide = textContent.split('').slice(0, anchorIndex);
      const rightSide = textContent.split('').slice(focusIndex);
      newTextContent = leftSide.concat(rightSide).join('');
      isDeleteSelectText = true;
    } else if (anchorIndex !== null && focusIndex !== null && anchorIndex === focusIndex) {
      const leftSide = textContent.split('').slice(0, anchorIndex - 1);
      const rightSide = textContent.split('').slice(focusIndex);
      newTextContent = leftSide.concat(rightSide).join('');
      isDeleteSelectText = true;
    }

    const textTriggers = newTextContent.split(trigger);
    const mentions = [...mentionsRef.current];
    const newMentions: IMentionOption[] = [];
    textTriggers.shift();

    let index = 0;
    textTriggers.forEach((item) => {
      const isMention = mentions.some((mentionValue) => item.slice(0, mentionValue.label.length) === mentionValue.label);
      if (!isMention) return;

      const check = () => {
        if (index >= mentions.length) return;
        if (item.slice(0, mentions[index].label.length) === mentions[index].label) {
          newMentions.push(mentions[index]);
          return;
        }
        index += 1;
        check();
      };

      check();

      index += 1;
    });

    mentionsRef.current = newMentions;

    setInputText(newTextContent);

    const caretPosition = valueFocusIndex || anchorIndex || focusIndex || 0;
    setLastCaretPosition(caretPosition || 0);
    if (caretPosition !== null) {
      if (newTextContent[caretPosition - 2] === trigger) {
        setShowDropdown(true);
        setLastTriggerPosition(caretPosition - 1);
      }
    }

    if (showDropdown && newTextContent[lastTriggerPosition - 1] !== trigger) {
      setShowDropdown(false);
    } else if (showDropdown && newTextContent[caretPosition - 1] === ' ' && textContent[caretPosition - 2] === trigger) {
      setShowDropdown(false);
    } else if (showDropdown) {
      setSearchText(textContent.slice(lastTriggerPosition, caretPosition - 1));
    }

    editorRef.current.innerHTML = renderText(newTextContent, caretPosition - 1);

    const focusMovement = isDeleteSelectText ? 0 : 1;
    if (focusIndex && anchorIndex && focusIndex < anchorIndex) {
      restoreSelection(focusIndex - focusMovement, focusIndex - focusMovement);
    } else if (focusIndex && anchorIndex && anchorIndex < focusIndex) {
      restoreSelection(anchorIndex - focusMovement, anchorIndex - focusMovement);
    } else if (focusIndex && anchorIndex && anchorIndex === focusIndex) {
      restoreSelection(anchorIndex - 1, anchorIndex - 1);
    }

    handleOnChange(newTextContent);
  };

  const dropdownClick = (data: IMentionOption) => {
    setShowDropdown(false);
    setActiveOptionIndex(0);
    const newNext = `${inputText.slice(0, lastTriggerPosition)}${data.label} ${inputText.slice(lastCaretPosition)}`;
    setInputText(newNext);

    let leftSide = inputText.slice(0, lastTriggerPosition - 1);
    let leftCounter = 0;
    mentionsRef.current.forEach((item) => {
      if (leftSide.includes(trigger + item.label)) {
        leftCounter += 1;
        leftSide = leftSide.replace(trigger + item.label, '');
      }
    });

    mentionsRef.current = [...mentionsRef.current.slice(0, leftCounter), data, ...mentionsRef.current.slice(leftCounter)];

    if (!editorRef.current) return;
    editorRef.current.innerHTML = newNext;
    inputHandler(undefined, lastTriggerPosition + data.label.length + 1);
  };

  const lastMention = useMemo(() => inputText.slice(lastTriggerPosition).trim(), [lastTriggerPosition, inputText]);

  const mentionAsPrivateCompany = () => {
    if (!lastMention) return;
    coverageService.savePrivateCompany(lastMention);
    dropdownClick({
      type: MentionType.PRIVATE,
      value: lastMention,
      label: lastMention
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Backspace' && event.target === editorRef.current) {
      event.preventDefault();
      backspaceHandler();
    }
    if (!showDropdown) return;
    if (event.key === 'ArrowDown' && event.target === editorRef.current) {
      event.preventDefault();
      setActiveOptionIndex((prev) => (mentionsOptions.length > prev ? prev + 1 : prev));
    } else if (event.key === 'ArrowUp' && event.target === editorRef.current) {
      event.preventDefault();
      setActiveOptionIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (event.key === 'Enter' && event.target === editorRef.current) {
      event.preventDefault();
      if (!mentionsOptions.length && !lastMention) {
        setShowDropdown(false);
        return;
      }
      if (activeOptionIndex === mentionsOptions.length) {
        mentionAsPrivateCompany();
      } else {
        dropdownClick(mentionsOptions[activeOptionIndex]);
      }
      setSearchText('');
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mentionsOptions, showDropdown, activeOptionIndex, lastTriggerPosition, inputText]);

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const sel = window.getSelection();
    if (onKeyDown) {
      onKeyDown(e);
    }
    if (e?.key === 'Enter' && !e.ctrlKey && !e.metaKey && editorRef.current && sel && !e?.shiftKey) {
      e.preventDefault();
      let absoluteFocusIndex = 0;
      let absoluteCurrentIndex = 0;
      let absoluteAnchorIndex = 0;
      const textSegments = getTextSegments(editorRef.current);
      textSegments.forEach(({ text, node }) => {
        if (node === sel.anchorNode) {
          absoluteAnchorIndex = absoluteCurrentIndex + sel.anchorOffset;
        }
        if (node === sel.focusNode) {
          absoluteFocusIndex = absoluteCurrentIndex + sel.focusOffset;
        }
        absoluteCurrentIndex += text.length;
      });
      const textContent = textSegments.map(({ text }) => text).join('');
      let newTextContent = textContent;
      if (absoluteAnchorIndex > absoluteFocusIndex) {
        const leftSide = textContent.split('').slice(0, absoluteFocusIndex);
        const rightSide = textContent.split('').slice(absoluteAnchorIndex);
        newTextContent = leftSide.concat(rightSide).join('');
        editorRef.current.innerHTML = newTextContent;
        inputHandler(undefined, absoluteFocusIndex);
      } else if (absoluteAnchorIndex < absoluteFocusIndex) {
        const leftSide = textContent.split('').slice(0, absoluteAnchorIndex);
        const rightSide = textContent.split('').slice(absoluteFocusIndex);
        newTextContent = leftSide.concat(rightSide).join('');
        editorRef.current.innerHTML = newTextContent;
        inputHandler(undefined, absoluteAnchorIndex);
      }
      let absoluteFocussIndex = 0;
      let absoluteCurrenttIndex = 0;
      const textSegmentss = getTextSegments(editorRef.current);
      textSegmentss.forEach(({ text, node }) => {
        if (node === sel.focusNode) {
          absoluteFocussIndex = absoluteCurrenttIndex + sel.focusOffset;
        }
        absoluteCurrenttIndex += text.length;
      });
      const updatedTextContent = textSegmentss.map(({ text }) => text).join('');
      const substring = '\n';
      const leftSide = updatedTextContent.split('').slice(0, absoluteFocussIndex);
      const rightSide = updatedTextContent.split('').slice(absoluteFocussIndex);
      const textWithEnter = leftSide.concat(substring, rightSide).join('');
      if (textWithEnter.length === absoluteFocussIndex + 1) {
        editorRef.current.innerHTML = `${textWithEnter}\n`;
        inputHandler(undefined, absoluteFocussIndex + 1);
      } else {
        editorRef.current.innerHTML = textWithEnter;
        inputHandler(undefined, absoluteFocussIndex + 1);
      }
    }
  };

  return (
    <Styled.MentionWrapper className={className} onDrop={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
      <Styled.EditorContainer
        aria-disabled={disabled}
        contentEditable
        placeholder={placeholder}
        isEmpty={!inputText.length}
        ref={editorRef}
        onInput={inputHandler}
        onPaste={onPaste}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
        onKeyDown={onEnter}
      />
      {showDropdown && !!dropdownPosition && Boolean(lastMention.length) && (
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <Styled.Dropdown isInModal={!!isInModal} top={dropdownPosition.top} left={dropdownPosition.left} ref={dropdownRef} width={400}>
            {mentionsOptions.map((option, index) => (
              <Styled.DropdownItemContainer key={option.value + option.label + index}>
                <Styled.DropdownTypeContainer width={typeWidth}>
                  <Styled.DropdownType>{mentionPrefix[option.type]}</Styled.DropdownType>
                </Styled.DropdownTypeContainer>
                <Styled.DropdownItem
                  onMouseEnter={() => setActiveOptionIndex(index)}
                  className={index === activeOptionIndex ? 'active' : ''}
                  onClick={() => dropdownClick(option)}
                  isActive={index === activeOptionIndex}
                  isUser={option.type === MentionType.USER}
                >
                  <p>{option.label}</p>
                  {option.type === MentionType.SECURITY && <Styled.DropdownSecurityTicker>{option.value}</Styled.DropdownSecurityTicker>}
                  {option.type === MentionType.USER && (
                    <Styled.DropdownPhoto borderRadius="50%" imageUrl={option.image} isImageHidden={!!option.isImageHidden} />
                  )}
                </Styled.DropdownItem>
              </Styled.DropdownItemContainer>
            ))}
            <Styled.DropdownItemContainer gap="0">
              <Styled.DropdownTypeContainer />
              <Styled.DropdownItemButton
                onMouseEnter={() => setActiveOptionIndex(mentionsOptions.length)}
                isActive={activeOptionIndex === mentionsOptions.length}
                className={activeOptionIndex === mentionsOptions.length ? 'active' : ''}
                onClick={mentionAsPrivateCompany}
              >
                <p>{`mention “${lastMention}” as a private company`}</p>
              </Styled.DropdownItemButton>
            </Styled.DropdownItemContainer>
          </Styled.Dropdown>
        </ClickAwayListener>
      )}
    </Styled.MentionWrapper>
  );
};
