import React, { useEffect, useMemo, useState } from 'react';
import LeftArrow from '@styles/icons/modal-back-arrow.png';
import Image from 'next/image';
import * as Styled from './reply-modal.styled';
import { useDeleteIdea, useIdeaByPostId, usePost } from '@/common/hooks';
import { Loader } from '@/common/components/loader';
import { IPost, ISubPost } from '@/common/types/post.type';
import { IdeaDTO } from '@/common/types';
import { wait } from '@/common/utils/wait';
import { PostRowComponent } from '../post-row';
import { IdeasModalComponent } from '../security-coverage-key-debates-section/ideas-modal';
import { quotePostModalWrapper } from '../new-quote-modal/new-quote-modal-wrapper.component';
import CloseIcon from '@/styles/icons/close-modal-pointer.png';
import EditArrow from '@/styles/icons/modal-edit-arrow.png';
import ImageTrashCan from '@/styles/icons/delete-memo-icon.webp';
import CloseConfirmIcon from '@/styles/icons/deleted-memo-close-icon.webp';
import ImageTrashCanConfirm from '@/styles/icons/already-deleted-memo-icon.webp';

interface IProps {
  open: boolean;
  isTreadOpen: boolean;
  originalPostId: string;
  searchPostId: string;
  isReplyOpen?: boolean;
  profileId?: string;
  openModal?: (it: string, searchId: string) => void;
  closeModal: () => void;
  userProfileId: string;
  quoteModalHistory: string[];
  setQuoteModalHistory: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ReplyModalComponent = ({
  open,
  isTreadOpen,
  originalPostId,
  searchPostId,
  isReplyOpen,
  profileId,
  openModal,
  closeModal,
  userProfileId,
  quoteModalHistory,
  setQuoteModalHistory
}: IProps) => {
  const [ideasModal, setIdeasModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const [postIdInModal, setPostIdInModal] = useState<undefined | string>(undefined);
  const [searchPostIdInModal, setSearchPostIdInModal] = useState<undefined | string>(undefined);
  const { mutateAsync: fetchPost, isLoading } = usePost();
  const { mutateAsync: deleteIdea, isSuccess: deleteIsSuccess, reset: deleteReset } = useDeleteIdea();

  const [postInRow, setPostInRow] = useState<IPost | undefined>(undefined);
  const [repliedPost, setRepliedPost] = useState<ISubPost | null>(null);

  const { mutateAsync: getIdea } = useIdeaByPostId();

  const closeHandler = () => {
    setPostIdInModal('');
    closeModal();
    setPostInRow(undefined);
    setRepliedPost(null);
    setQuoteModalHistory([]);
  };

  useEffect(() => {
    if (originalPostId && originalPostId !== postIdInModal) {
      setPostIdInModal(originalPostId);
    }
    if (searchPostId && searchPostId !== searchPostIdInModal) {
      setSearchPostIdInModal(searchPostId);
    }
  }, [originalPostId, searchPostId]);

  const updatePost = async () => {
    if (!postIdInModal) return;
    const searchedPostId = searchPostId !== originalPostId ? searchPostId : undefined;
    const data = await fetchPost({ id: postIdInModal, searchedPostId });
    if (!data) return;
    await setPostInRow(data);
    if (!searchPostId || !data.tread?.replies?.length || !isReplyOpen) return;
    const searchedReply = data.tread.replies.find((it) => it.id === searchPostId);
    if (searchedReply) {
      setRepliedPost(searchedReply);
    } else {
      setRepliedPost(null);
    }
  };

  useEffect(() => {
    if (postIdInModal) {
      updatePost();
    }
  }, [postIdInModal]);

  const openInsideQuotePost = async (newPostId: string, oldPostId: string) => {
    if (!openModal) return;
    await closeModal();
    await wait(300);
    await setQuoteModalHistory((prev) => [...prev, oldPostId]);
    await setPostIdInModal(newPostId);
    await openModal(newPostId, newPostId);
  };

  const openPreviousQuotePost = async () => {
    if (!openModal) return;
    await closeModal();
    await wait(300);
    await setPostIdInModal(quoteModalHistory[quoteModalHistory.length - 1]);
    const filterWithoutLast = quoteModalHistory.filter((item) => item !== quoteModalHistory[quoteModalHistory.length - 1]);
    await setQuoteModalHistory(filterWithoutLast ?? []);
    await openModal(quoteModalHistory[quoteModalHistory.length - 1], quoteModalHistory[quoteModalHistory.length - 1]);
  };

  const openPrevious = useMemo(() => (quoteModalHistory.length > 0 ? openPreviousQuotePost : undefined), [quoteModalHistory?.length]);

  const onClickByQuotePost = () => {
    if (!postInRow?.postId || !postInRow.id || !openInsideQuotePost) return;

    openInsideQuotePost(postInRow.postId, postInRow.id);
  };

  const [idea, setIdea] = useState<null | IdeaDTO>(null);

  const openEditCoverageModal = async () => {
    if (!postIdInModal) return;
    const data = await getIdea(postIdInModal);
    if (!data) return;
    setIdeasModal(true);
    setIdea(data);
  };

  const closeEditCoverageModal = () => {
    updatePost();
    setIdeasModal(false);
  };

  const { quotePostHandler, componentToView } = quotePostModalWrapper();

  const updatePostQuestionInModal = (post: ISubPost) => {
    setPostInRow((prev) => (prev ? { ...prev, question: post.question } : prev));
  };

  const onDeleteClick = async () => {
    try {
      if (!confirmDelete) {
        setConfirmDelete(true);
      } else {
        await deleteIdea(postInRow?.id!);
        closeModal();
      }
    } catch {
      /* empty */
    }
  };

  return (
    <Styled.ReplyModalWithBackButtonWrapper open={open} closeHandler={closeHandler} childTopPostion={15} isCreateModal>
      {componentToView}
      <IdeasModalComponent open={ideasModal} closeHandler={closeEditCoverageModal} idea={idea} closeReplyModal={closeHandler} />
      <Styled.ButtonsInModalWrapper>
        {!!quoteModalHistory.length && (
          <Styled.PreviousButton onClick={openPrevious}>
            <Styled.PrevieusButtonImage src={LeftArrow} alt="Left Arrow" />
            <span>back</span>
          </Styled.PreviousButton>
        )}
        {postInRow && postInRow.idea && !!userProfileId && postInRow.profileId === userProfileId && (
          <Styled.DeleteCoverageButton isConfirm={confirmDelete} onClick={onDeleteClick}>
            <Styled.DeleteCoverageButtonImage src={confirmDelete ? ImageTrashCanConfirm : ImageTrashCan} alt="delete" />
            <span>delete coverage</span>
            {confirmDelete && (
              <Image
                style={{ marginLeft: '1px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDelete(false);
                }}
                src={CloseConfirmIcon}
                width={10}
                height={10}
                alt="delete"
              />
            )}
          </Styled.DeleteCoverageButton>
        )}
        {!(postInRow && postInRow.idea && !!userProfileId && postInRow.profileId === userProfileId) && !quoteModalHistory.length && (
          <Styled.CloseButton onClick={closeHandler}>
            <Styled.CloseImage src={CloseIcon} alt="close-icon" />
            close
          </Styled.CloseButton>
        )}
      </Styled.ButtonsInModalWrapper>
      <Styled.ModalTitle>
        {postInRow?.idea
          ? 'Coverage'
          : postInRow?.question
          ? 'Key debate'
          : postInRow?.memo || postInRow?.memoSection
          ? 'Idea'
          : postInRow?.review
          ? 'Review'
          : (postInRow || !isLoading) && 'Post'}
      </Styled.ModalTitle>
      {isLoading && !postInRow && <Loader width="90px" height="90px" />}
      {!isLoading && postInRow && (
        <PostRowComponent
          dividerWidth={500}
          isLast
          profileId={profileId}
          userProfileId={userProfileId}
          post={postInRow}
          quotePostHandler={quotePostHandler}
          openReplyModal={onClickByQuotePost}
          updateQuestionInModal={updatePostQuestionInModal}
          isTreadOpen={isTreadOpen}
          closeModal={closeHandler}
          isInModal
          isReplyOpen={isReplyOpen}
          repliedPostProp={repliedPost ?? undefined}
        />
      )}
    </Styled.ReplyModalWithBackButtonWrapper>
  );
};
