/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import NewPost from '@styles/icons/add-new-post-icon.svg';
import NewPostAnon from '@styles/icons/new-post-icon-anon.webp';
import ModeIcon from '@styles/icons/mode-icon.webp';
import AnoneModeIcon from '@styles/icons/anon-toolbar-icon.webp';
import LineIcon from '@styles/icons/line-toolbar-icon.webp';
import UnActiveAnonModeIcon from '@styles/icons/un-active-anon-icon.webp';
import UnActiveRealModeIcon from '@styles/icons/un-active-real-icon.webp';
// import News from '@styles/icons/news.webp';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ISidebarProps } from '@/common/types/sidebar.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { useNotifications } from '@/common/hooks/use-notification';
import { useDialogs, useDirectMessages } from '@/common/hooks/use-direst-messages';
import {
  triedToActivate as triedToActivateAPI,
  useActivateUser,
  useSwitchToReacAcount,
  useSwitchToSimplifiedAnonAcount
} from '@/common/hooks';
import { sidebarList } from './sidebar.const';
import * as Styled from './sidebar.styled';
import { SearchModal } from '../search-page/components/search-modal';
// import { SlideTextButtonComponent } from './slide-text-button.component';
import { ActivateAnonModal } from '../anon/components/modals/activate-anon-modal/activate-anon-modal.component';
import { ActivateRealModal } from '../anon/components/modals/activate-real-modal/activate-real-modal.component';
import { SwitchToAnonModalComponent } from '../switch-to-anon-modal';
import { ConnectRealToAnonModal } from '../anon/components/modals/connect-real-to-anon-modal/connect-real-to-anon-modal.component';
import { SidebarLogoComponent } from './components/sidebar-logo/sidebar-logo.component';
import { LoginRealFromAnon } from '../anon/components/modals/login-real-from-anon-modal/login-real-from-anon-modal.component';
import { CreateModalComponent } from '../create-modal/create-modal.component';
import { CreateModalType } from '../create-modal/modal-type.const';
import { IGetNotifications, NotificationClientType } from '@/common/types/notification.type';

export const Sidebar = ({ active, profileHandler, isAnon, isNonFinanceUser }: ISidebarProps) => {
  const [openNewMusingModal, setOpenNewMusingModal] = useState<boolean>(false);
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
  const [activateAnonModal, setActivateAnonModal] = useState<boolean>(false);
  const [openSwitchToAnon, setOpenSwitchToAnon] = useState<boolean>(false);
  const [activateRealModal, setActivateRealModal] = useState<boolean>(false);
  const [connectRealToAnonModal, setConnectRealToAnonModal] = useState<boolean>(false);
  const [loginToRealModal, setLoginToRealModal] = useState<boolean>(false);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const { notifications } = useNotifications();
  const { data: messages } = useDirectMessages();
  const { user, updateUser } = useActivateUser();
  const [triedToActivate, setTriedToActivate] = useState(!!user?.triedToActivate);
  useEffect(() => setTriedToActivate(!!user?.triedToActivate), [user?.triedToActivate]);

  const { push } = useRouter();
  const { state } = useDialogs();

  const handleClick = async (link: string) => {
    if (active === 'Profile' && link === ROUTER_KEYS.PROFILE && profileHandler) {
      profileHandler();
    }
    push(link);
  };

  const handleFlashingClick = async () => {
    setTriedToActivate(true);
    updateUser({ triedToActivate: true });
    await triedToActivateAPI();
  };

  const { mutateAsync: switchToRealAcount, isLoading } = useSwitchToReacAcount();
  const { mutateAsync: switchToSimplifiedAnonAcount, isLoading: isSwitchingToSimplifiedAnon } = useSwitchToSimplifiedAnonAcount();

  const onClickBySwitchButton = () => {
    if (isLoading) return;
    switchToRealAcount();
  };

  const onClickBySwitchSimplifiedButton = () => {
    if (isSwitchingToSimplifiedAnon) return;
    switchToSimplifiedAnonAcount();
  };

  const getNotificationSummary = (notificationsInput: IGetNotifications) => {
    let total = 0;

    notificationsInput.forResponse.forEach((notification) => {
      switch (notification.type) {
        case NotificationClientType.REPLY:
          total += 1;
          break;
        case NotificationClientType.REPOST:
        case NotificationClientType.CONNECTION_REQUEST:
        case NotificationClientType.FOLLOW_REQUEST:
        case NotificationClientType.MENTION:
        case NotificationClientType.NEW_FOLLOWER:
          total += notification.count || 0;
          break;
        case NotificationClientType.POST_REACTS:
          total += (notification.claps || 0) + (notification.likes || 0);
          break;
        case NotificationClientType.QUOTE_POST:
          total += 1;
          break;
        default:
          break;
      }
    });

    return total;
  };

  const checkNewNotification = (): boolean => {
    if (notifications) {
      const count = getNotificationSummary(notifications);
      setNotificationsCount(count);
      const forResponse = notifications.forResponse?.length || 0;
      const adoration = notifications.adoration?.length || 0;
      return !!forResponse || !!adoration;
    }
    return false;
  };

  useEffect(() => {
    setIsNewNotification(checkNewNotification());
  }, [notifications]);

  const onSearchClick = () => {
    setOpenSearchModal(true);
  };

  const handleRealSwithClick = () => {
    const isDontHaveAnonAccount =
      user && ((user.id.includes('-') && !user.isSimplifiedAnon) || (user.email.includes('@') && user.isSimplifiedAnon));
    if (isDontHaveAnonAccount) {
      setActivateRealModal(true);
      return;
    }
    if (user && !user.id.includes('-') && !user.isSimplifiedAnon && !user.refreshTokenReal) {
      setLoginToRealModal(true);
      return;
    }
    onClickBySwitchButton();
  };

  const handleAnonSwitchClick = () => {
    if (user && user.isAnon) {
      onClickBySwitchButton();
      return;
    }
    if (user && !user.isUserHaveAnon) {
      setActivateAnonModal(true);
      return;
    }
    if (user && !user.isAnonLinked) {
      setConnectRealToAnonModal(true);
      return;
    }
    if (user && user.isUserHaveSimplifiedAnon) {
      onClickBySwitchSimplifiedButton();
      return;
    }
    setOpenSwitchToAnon(true);
  };

  const channelsToDisplay = React.useMemo(
    () =>
      state.channels
        ?.filter((ch) => !(ch.lastMessage === null) || ch.url === state.currentlyJoinedChannel?.url)
        .sort((a, b) => {
          if (a.lastMessage === null) return -1;
          if (b.lastMessage === null) return 1;
          return 0;
        }) || [],
    [state.channels, state.currentlyJoinedChannel]
  );

  return (
    <Styled.SidebarContainerWrapper>
      <Styled.SidebarContainer>
        <Styled.NewPostRouteButton isAnon={isAnon} onClick={() => setOpenNewMusingModal(true)}>
          <Styled.NewPostIcon priority src={isAnon ? NewPostAnon : NewPost} alt="New" />
          <Styled.NewPostText isAnon={isAnon}>New</Styled.NewPostText>
        </Styled.NewPostRouteButton>
        <Styled.RoutesContainer>
          {sidebarList.map((item, index) => {
            if (isNonFinanceUser && item.name === 'Memo') return true;
            return (
              <Styled.RouteButton
                onClick={() => (item.name === 'Search' ? onSearchClick() : handleClick(item.link))}
                isActive={active === item.name}
                key={`toolbar-button-${index}-${item.name}`}
                marginBottom={item.marginBottom}
              >
                {/* {((item.link === ROUTER_KEYS.NOTIFICATIONS && isNewNotification && active !== item.name) ||
                  (item.link === ROUTER_KEYS.MESSAGES && messages?.hasUnreadedMessages && active !== item.name)) && (
                  <Styled.NewItemCircle />
                )} */}
                {isAnon && (
                  <Styled.AnonActiveIcon
                    isAnon={isAnon}
                    priority
                    src={active === item.name ? item.valueActiveAnon : item.valueAnon}
                    alt={item.name}
                    isActive={active === item.name}
                  />
                )}
                {!isAnon && (
                  <Styled.Icon
                    isAnon={isAnon}
                    priority
                    src={active === item.name ? item.valueActive : item.value}
                    alt={item.name}
                    isActive={active === item.name}
                  />
                )}
                <Styled.NotificAndMessagesCountContainer>
                  <Styled.RouteText isActive={active == item.name} isAnon={isAnon}>
                    {item.name}
                  </Styled.RouteText>
                  {((item.link === ROUTER_KEYS.NOTIFICATIONS && isNewNotification && active !== item.name) ||
                    (item.link === ROUTER_KEYS.MESSAGES && messages?.hasUnreadedMessages && active !== item.name)) && (
                    <Styled.NotificAndMessagesCount>
                      {item.link === ROUTER_KEYS.NOTIFICATIONS && notificationsCount && notificationsCount > 0
                        ? notificationsCount && notificationsCount > 99
                          ? '>99'
                          : notificationsCount
                        : null}
                      {item.link === ROUTER_KEYS.MESSAGES &&
                        channelsToDisplay.length > 0 &&
                        (() => {
                          const unreadMessagesCount = channelsToDisplay.reduce(
                            (acc, dialog) => acc + (dialog.unreadMessageCount > 0 ? dialog.unreadMessageCount : 0),
                            0
                          );
                          return unreadMessagesCount > 0 ? (unreadMessagesCount > 99 ? '>99' : unreadMessagesCount) : null;
                        })()}
                    </Styled.NotificAndMessagesCount>
                  )}
                </Styled.NotificAndMessagesCountContainer>
              </Styled.RouteButton>
            );
          })}
        </Styled.RoutesContainer>
        <SwitchToAnonModalComponent open={openSwitchToAnon} closeHandler={() => setOpenSwitchToAnon(false)} />
        <CreateModalComponent
          open={openNewMusingModal}
          closeHandler={() => setOpenNewMusingModal(false)}
          modalType={CreateModalType.POST}
        />
        <SearchModal open={openSearchModal} closeHandler={() => setOpenSearchModal(false)} />
        <LoginRealFromAnon open={loginToRealModal} closeHandler={() => setLoginToRealModal(false)} />
        <ActivateAnonModal open={activateAnonModal} closeHandler={() => setActivateAnonModal(false)} />
        <ActivateRealModal open={activateRealModal} closeHandler={() => setActivateRealModal(false)} />
        <ConnectRealToAnonModal open={connectRealToAnonModal} closeHandler={() => setConnectRealToAnonModal(false)} />
      </Styled.SidebarContainer>

      <Styled.SidebarBottomContainer>
        <Styled.ModeWrapper isAnon={isAnon}>
          <Styled.UnActiveButton onClick={() => (isAnon ? handleRealSwithClick() : handleAnonSwitchClick())}>
            {((!isAnon && !!user && !user.isUserHaveAnon) ||
              (isAnon &&
                !!user &&
                ((user.id.includes('-') && !user.isSimplifiedAnon) || (user.email.includes('@') && user.isSimplifiedAnon)))) && (
                <Styled.ActivateLabelWrapper isFlashing={!triedToActivate} onClick={handleFlashingClick}>
                  <p>Activate</p>
                  <Image src={LineIcon} width={8} height={23} alt="line-icon" />
                </Styled.ActivateLabelWrapper>
            )}
            <Styled.ModeImgWrapper onClick={handleFlashingClick}>
              <Styled.ModeImg priority src={!isAnon ? UnActiveAnonModeIcon : UnActiveRealModeIcon} alt="Un Active Mode Icon" />
              <Styled.ModeLabel isAnon={isAnon}>{isAnon ? 'Real\nAccount' : 'Anon\nAccount'}</Styled.ModeLabel>
            </Styled.ModeImgWrapper>
          </Styled.UnActiveButton>
          <Styled.ModeImgWrapper>
            <Styled.ModeImg priority src={isAnon ? AnoneModeIcon : ModeIcon} alt="Mode Icon" />
            <Styled.ModeLabel isAnon={isAnon} isCurrent>
              {isAnon ? 'Anon\nAccount' : 'Real\nAccount'}
            </Styled.ModeLabel>
          </Styled.ModeImgWrapper>
        </Styled.ModeWrapper>
        <SidebarLogoComponent />
      </Styled.SidebarBottomContainer>
    </Styled.SidebarContainerWrapper>
  );
};
