import React, { useRef, useEffect, useMemo, FormEvent, useState } from 'react';
import { useDebounce } from '@/common/hooks';
import * as Styled from './max-length-area.styled';

interface IProps {
  maxLength: number;
  value: string;
  placeholder: string;
  isSummary?: boolean | undefined;
  isThesis?: boolean;
  isReturns?: boolean;
  isReturnsDetails?: boolean;
  isNewDebate?: boolean;
  warningColor?: string;
  autoFocus?: boolean;
  minHeight?: number;
  isInit?: boolean;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
}

interface IUndoStack {
  prevState: string;
  absoluteFocusIndex: number | null;
  absoluteAnchorIndex: number | null;
}

const StyleMaxLengthAreaComponent = ({
  maxLength,
  placeholder,
  value,
  isSummary,
  isThesis,
  isReturns,
  isReturnsDetails,
  isNewDebate,
  isDisabled,
  autoFocus,
  onChange,
  onPaste,
  onDrop,
  warningColor,
  minHeight,
  isInit
}: IProps) => {
  const [undoStack, setUndoStack] = useState<IUndoStack[]>([]);
  const [preStateForUndoStack, setPreStateForUndoStack] = useState<IUndoStack>({
    prevState: value,
    absoluteAnchorIndex: value.length,
    absoluteFocusIndex: value.length
  });
  const debouncedforUndoStack = useDebounce<IUndoStack>(preStateForUndoStack);
  const editorRef = useRef<HTMLDivElement>(null);
  const [isInitState, setIsInit] = useState(isInit);

  const addToUndo = (prevState: string, absoluteAnchorIndex: number | null, absoluteFocusIndex: number | null) => {
    setPreStateForUndoStack({ prevState, absoluteAnchorIndex, absoluteFocusIndex });
  };

  const getTextSegments = (element: HTMLElement): { text: string; node: Node }[] => {
    const textSegments: { text: string; node: Node }[] = [];
    Array.from(element.childNodes).forEach((node) => {
      switch (node.nodeType) {
        case Node.TEXT_NODE:
          textSegments.push({ text: node.nodeValue || '', node });
          break;
        case Node.ELEMENT_NODE:
          textSegments.splice(textSegments.length, 0, ...getTextSegments(node as HTMLElement));
          break;
      }
    });
    return textSegments;
  };

  const restoreSelection = (absoluteAnchorIndex: number | null, absoluteFocusIndex: number | null) => {
    const sel = window.getSelection();
    if (!editorRef.current || !sel) return;
    const textSegments = getTextSegments(editorRef.current);
    let anchorNode: HTMLDivElement | Node = editorRef.current;
    let anchorIndex = 0;
    let focusNode: HTMLDivElement | Node = editorRef.current;
    let focusIndex = 0;
    let currentIndex = 0;
    textSegments.forEach(({ text, node }) => {
      const startIndexOfNode = currentIndex;
      const endIndexOfNode = startIndexOfNode + text.length;
      if (startIndexOfNode <= absoluteAnchorIndex! && absoluteAnchorIndex! <= endIndexOfNode) {
        anchorNode = node;
        anchorIndex = absoluteAnchorIndex! - startIndexOfNode;
      }
      if (startIndexOfNode <= absoluteFocusIndex! && absoluteFocusIndex! <= endIndexOfNode) {
        focusNode = node;
        focusIndex = absoluteFocusIndex! - startIndexOfNode;
      }
      currentIndex += text.length;
    });
    sel?.setBaseAndExtent(anchorNode, anchorIndex, focusNode, focusIndex);
  };

  const renderText = (text: string) => {
    const main = text.slice(0, maxLength);
    const additional = text.slice(maxLength);
    if (additional.length) {
      return `${main}<span style="color: ${warningColor || 'red'}">${additional}</span>`;
    }

    return main;
  };

  const updateEditor = (e?: FormEvent<HTMLDivElement>, valueFocusIndex?: number) => {
    const sel = window.getSelection();
    if (!editorRef.current || !sel) return;
    if (isDisabled) {
      onChange('');
      editorRef.current.innerHTML = renderText('');
      return;
    }
    const textSegments = getTextSegments(editorRef.current);
    const textContent = textSegments.map(({ text }) => text).join('');
    onChange(textContent);
    let anchorIndex: number | null = null;
    let focusIndex: number | null = null;
    let currentIndex = 0;
    textSegments.forEach(({ text, node }) => {
      if (node === sel.anchorNode) {
        anchorIndex = currentIndex + sel.anchorOffset;
      }
      if (node === sel.focusNode) {
        focusIndex = currentIndex + sel.focusOffset;
      }
      currentIndex += text.length;
    });

    if (textContent) {
      editorRef.current.innerHTML = renderText(textContent);
    }

    addToUndo(textContent, valueFocusIndex || focusIndex, valueFocusIndex || anchorIndex);
    if (isInitState) {
      setIsInit(false);
      return;
    }
    restoreSelection(valueFocusIndex || anchorIndex, valueFocusIndex || focusIndex);
  };

  useEffect(() => {
    if (!editorRef.current || !value) return;
    const textSegments = getTextSegments(editorRef.current);
    const textContent = textSegments.map(({ text }) => text).join('');
    if (textContent !== value) {
      editorRef.current.innerHTML = value;
      updateEditor(undefined, value.length);
    }
  }, [value, editorRef.current]);

  useEffect(() => {
    if (autoFocus && editorRef.current) {
      editorRef.current.focus();
    }
  }, []);

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const sel = window.getSelection();
    if (e?.key === 'Enter' && editorRef.current && sel && !e?.shiftKey) {
      e.preventDefault();
      let absoluteFocusIndex = 0;
      let absoluteCurrentIndex = 0;
      let absoluteAnchorIndex = 0;
      const textSegments = getTextSegments(editorRef.current);
      textSegments.forEach(({ text, node }) => {
        if (node === sel.anchorNode) {
          absoluteAnchorIndex = absoluteCurrentIndex + sel.anchorOffset;
        }
        if (node === sel.focusNode) {
          absoluteFocusIndex = absoluteCurrentIndex + sel.focusOffset;
        }
        absoluteCurrentIndex += text.length;
      });
      const textContent = textSegments.map(({ text }) => text).join('');
      let newTextContent = textContent;
      if (absoluteAnchorIndex > absoluteFocusIndex) {
        const leftSide = textContent.split('').slice(0, absoluteFocusIndex);
        const rightSide = textContent.split('').slice(absoluteAnchorIndex);
        newTextContent = leftSide.concat(rightSide).join('');
        editorRef.current.innerHTML = newTextContent;
        updateEditor(undefined, absoluteFocusIndex);
      } else if (absoluteAnchorIndex < absoluteFocusIndex) {
        const leftSide = textContent.split('').slice(0, absoluteAnchorIndex);
        const rightSide = textContent.split('').slice(absoluteFocusIndex);
        newTextContent = leftSide.concat(rightSide).join('');
        editorRef.current.innerHTML = newTextContent;
        updateEditor(undefined, absoluteAnchorIndex);
      }
      let absoluteFocussIndex = 0;
      let absoluteCurrenttIndex = 0;
      const textSegmentss = getTextSegments(editorRef.current);
      textSegmentss.forEach(({ text, node }) => {
        if (node === sel.focusNode) {
          absoluteFocussIndex = absoluteCurrenttIndex + sel.focusOffset;
        }
        absoluteCurrenttIndex += text.length;
      });
      const updatedTextContent = textSegmentss.map(({ text }) => text).join('');
      const substring = '\n';
      const leftSide = updatedTextContent.split('').slice(0, absoluteFocussIndex);
      const rightSide = updatedTextContent.split('').slice(absoluteFocussIndex);
      const textWithEnter = leftSide.concat(substring, rightSide).join('');

      if (textWithEnter.length === absoluteFocussIndex + 1) {
        editorRef.current.innerHTML = `${textWithEnter}\n`;
        updateEditor(undefined, absoluteFocussIndex + 1);
      } else {
        editorRef.current.innerHTML = textWithEnter;
        updateEditor(undefined, absoluteFocussIndex + 1);
      }
    }
  };

  const onUndo = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey && e.key === 'z') || (e.metaKey && e.key === 'z')) {
      e.preventDefault();
      if (undoStack.length > 1 && editorRef.current) {
        undoStack.pop();
        onChange(undoStack[undoStack.length - 1].prevState);
        editorRef.current.innerHTML = undoStack[undoStack.length - 1].prevState;
        restoreSelection(undoStack[undoStack.length - 1].absoluteAnchorIndex, undoStack[undoStack.length - 1].absoluteFocusIndex);
      } else if (undoStack.length === 1 && editorRef.current) {
        onChange(undoStack[0].prevState);
        editorRef.current.innerHTML = undoStack[0].prevState;
        restoreSelection(undoStack[0].absoluteAnchorIndex, undoStack[0].absoluteFocusIndex);
      }
    }
  };

  useEffect(() => {
    setUndoStack((prev) => {
      const prevValue = prev;
      if (prevValue[prevValue.length > 0 ? prevValue.length - 1 : 0]?.prevState === debouncedforUndoStack?.prevState) return prevValue;
      prevValue.push(debouncedforUndoStack);
      return prevValue;
    });
  }, [debouncedforUndoStack]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    onEnter(e);
    onUndo(e);
  };

  const PasteFromClipboard = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    const filteredText = text.replace(/<[^>]*>/g, '');
    document.execCommand('insertText', false, filteredText);
  };

  const isEmplty = useMemo(() => !!(value.length === 0), [value]);

  const onDropHandler = (e: any) => {
    if (onDrop) {
      onDrop(e);
      return;
    }
    e.preventDefault();
  };

  return (
    <>
      {isSummary && (
        <Styled.EditorContainerSummary
          contentEditable
          placeholder={placeholder}
          isEmpty={isEmplty}
          ref={editorRef}
          id="editor"
          onInput={updateEditor}
          onKeyDown={onKeyDown}
          onDrop={onDropHandler}
          onPaste={PasteFromClipboard}
        />
      )}
      {isThesis && onPaste && (
        <Styled.EditorContainerThesis
          contentEditable
          placeholder={placeholder}
          isEmpty={isEmplty}
          ref={editorRef}
          id="editor"
          onInput={updateEditor}
          onKeyDown={onKeyDown}
          onDrop={onDropHandler}
          onPaste={(e) => {
            onPaste(e);
            PasteFromClipboard(e);
          }}
        />
      )}
      {isReturns && (
        <Styled.EditorContainerReturns
          contentEditable
          placeholder={placeholder}
          isEmpty={isEmplty}
          ref={editorRef}
          id="editor"
          onInput={updateEditor}
          onKeyDown={onKeyDown}
          onDrop={onDropHandler}
          onPaste={PasteFromClipboard}
        />
      )}
      {isReturnsDetails && (
        <Styled.EditorContainerReturnsDetails
          contentEditable
          placeholder={placeholder}
          isEmpty={isEmplty}
          ref={editorRef}
          id="editor"
          onInput={updateEditor}
          onKeyDown={onKeyDown}
          onDrop={onDropHandler}
          onPaste={PasteFromClipboard}
        />
      )}
      {isNewDebate && (
        <Styled.EditorContainerNewDebate
          contentEditable
          placeholder={placeholder}
          isEmpty={isEmplty}
          ref={editorRef}
          id="editor"
          onInput={updateEditor}
          onKeyDown={onKeyDown}
          onDrop={onDropHandler}
          onPaste={PasteFromClipboard}
        />
      )}
      {!isThesis && !isSummary && !isReturns && !isReturnsDetails && !isNewDebate && (
        <Styled.EditorContainer
          contentEditable
          placeholder={placeholder}
          isEmpty={isEmplty}
          ref={editorRef}
          id="editor"
          onInput={updateEditor}
          onKeyDown={onKeyDown}
          onDrop={onDropHandler}
          onPaste={PasteFromClipboard}
          minHeight={minHeight}
        />
      )}
    </>
  );
};
export default StyleMaxLengthAreaComponent;
