import styled, { AnyStyledComponent } from 'styled-components';
import { COLORS } from '@/theme';
import { SIZES } from '@/theme/fonts.const';
import { MentionTextComponent } from '../mention-text';

export const MemoWrapper = styled('div')<{ isQuote: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  gap: 5px;
`;

export const MemoTitle = styled('span')`
  color: #7c7c7c;
  display: inline-block;
  cursor: default;
  margin-right: 5px;
  min-width:46px
`;

export const LinkOnMemo = styled('span')<{ isShort: boolean; isQuote: boolean | undefined }>`
 // color: ${({ isShort, isQuote }) => (isQuote ? '#4D4D4D' : isShort ? COLORS.blue : COLORS.esmerald)};
 color: ${({ isShort}) => (isShort ? COLORS.blue : COLORS.esmerald)};
  cursor: pointer;
`;

export const MemoText = styled('span')<{ isQuote: boolean | undefined }>`
  color: ${({ isQuote }) => (isQuote ? '#4D4D4D' : COLORS.black)};
  white-space: pre-wrap;
  word-break: break-word;
  cursor: default;
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; 
`;

export const PostText = styled(MentionTextComponent as AnyStyledComponent)<{ isReply?: boolean; isQuote?: boolean }>`
  margin-bottom: -0.313rem;
  overflow: hidden;
  max-width: 430px;
  line-height: ${SIZES.xxxxl};
  color: ${({ isReply, isQuote }) => (isReply || isQuote ? COLORS.repliedPostText : COLORS.black)};
  ${({ isReply }) => !isReply && 'overflow-wrap: break-word;'}
  ${({ isReply }) => isReply && 'white-space: nowrap;text-overflow: ellipsis;'}
`;
