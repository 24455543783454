import React, { ChangeEvent } from 'react';
import AttachPhoto from '@styles/icons/reply-repost-attach-photo.webp';
import * as Styled from './input-with-image.styled';
import { ImagePreviewComponent } from '@/common/components/post/components/image-preview';

interface IProps {
  text: string;
  image: ArrayBuffer | string | null;
  isEdit: boolean;
  haveReply?: boolean;
  onPaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  uploadPhoto: (e: ChangeEvent<HTMLInputElement>) => void;
  removePhoto: () => void;
  setText: (text: string) => void;
}

export const InputWithImageComponent: React.FC<IProps> = ({
  text,
  haveReply,
  image,
  isEdit,
  onPaste,
  onDrop,
  uploadPhoto,
  removePhoto,
  setText
}) => {
  const uploadInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Styled.InputWithImage isNotEditable={!isEdit}>
      {isEdit ? (
        <Styled.ReviewInput
          isInModal
          maxLength={400}
          value={text}
          onChange={(value) => setText(value)}
          onPaste={onPaste}
          placeholder="Type here.."
          onDrop={onDrop}
        />
      ) : (
        <Styled.InputIsNotEditable>{text}</Styled.InputIsNotEditable>
      )}
      {image && (
        <ImagePreviewComponent
          img={typeof image === 'string' ? image : URL.createObjectURL(new Blob([image]))}
          deleteHandler={removePhoto}
        />
      )}
      <Styled.PostActionsContainer haveReplie={haveReply}>
        {isEdit && (
          <>
            <Styled.AttachImage src={AttachPhoto} alt="attach" onClick={() => uploadInputRef.current && uploadInputRef.current.click()} />
            <input ref={uploadInputRef} accept="image/*" onChange={uploadPhoto} style={{ display: 'none' }} type="file" />
          </>
        )}
        {/* <Styled.CloseImage src={replies.length ? CloseGrey : CloseBlack} alt="attach" onClick={closeHandler} /> */}
      </Styled.PostActionsContainer>
    </Styled.InputWithImage>
  );
};
