/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import Image from 'next/image';
import ChatsIcon from '@styles/icons/messages.webp';
import ChatsEmptyIcon from '@styles/icons/messages-read.webp';
import ChatsIconAnon from '@styles/icons/messages-anon.webp';
import ChatsIconAnonEmpty from '@styles/icons/messages-anon-empty.webp';

import { useRouter } from 'next/router';
import PrivateUserLogo from '@styles/icons/lock.webp';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { useDialogs, useDirectMessagesChannels } from '@/common/hooks/use-direst-messages';
import { IUser } from '@/common/types/user.type';
import * as Styled from './messages-bar.styled';

const USERS_TO_DISPLAY = 3;
export const MessagesBar = ({ user, isAnon }: { user?: IUser; isAnon?: boolean }) => {
  const router = useRouter();
  const [channelsTodisplay, setChannelsTodisplay] = useDirectMessagesChannels();
  const goToChats = () => router.push(ROUTER_KEYS.MESSAGES);
  const { state, chatsUsersInfo, allwowSendBird, setupUser, handleJoinChannel } = useDialogs();
  useEffect(() => {
    if (user && allwowSendBird.current) {
      setupUser(user?.profile?.fullName!, user?.profile?.id!, user?.profile?.imageUrl!, true);
      allwowSendBird.current = false;
    }
  }, [user]);

  useEffect(() => {
    const arrCopy = [...(state?.channels ?? [])].filter((ch) => ch.lastMessage !== null);
    if (arrCopy.length <= USERS_TO_DISPLAY) {
      setChannelsTodisplay(arrCopy);
      return;
    }
    setChannelsTodisplay(arrCopy.slice(0, USERS_TO_DISPLAY));
  }, [state.channels, state.hasUnreadedMessages]);

  const handleUserClick = (channelUrl: string) => {
    handleJoinChannel(channelUrl);

    if (router.pathname !== ROUTER_KEYS.MESSAGES) router.push(ROUTER_KEYS.MESSAGES);
  };

  const isNotEmpty = channelsTodisplay.length > 0 && chatsUsersInfo.length > 0 && user?.profile?.id;
  const someChannelIsNotRead = channelsTodisplay?.some((it) => it.unreadMessageCount > 0);
  return <div />;
  // return (
  //   <Styled.MessagesBarWrapper>
  //     <Styled.GoToChatsBtn type="button" onClick={goToChats}>
  //       <Image
  //         priority
  //         src={isAnon ? (someChannelIsNotRead ? ChatsIconAnon : ChatsIconAnonEmpty) : someChannelIsNotRead ? ChatsIcon : ChatsEmptyIcon}
  //         alt="Go to chats"
  //         height={20}
  //         width={20}
  //       />
  //     </Styled.GoToChatsBtn>
  //     {isNotEmpty && (
  //       <Styled.ChatsList>
  //         {channelsTodisplay.map((channel, i) => {
  //           const profile = chatsUsersInfo.find((p) => p.id === channel.members.find((m) => m.userId !== user.profile!.id)?.userId);
  //           if (!profile || (channel.lastMessage === null && state.currentlyJoinedChannel?.url !== channel.url)) return null;
  //           return (
  //             <Styled.ChatItem
  //               hasNewMessages={channel.unreadMessageCount > 0}
  //               isAnon={isAnon}
  //               key={channel.url + i}
  //               onClick={() => {
  //                 handleUserClick(channel.url);
  //               }}
  //             >
  //               {profile.isImageHidden ? (
  //                 <Styled.HiddenImage hasNewMessages={channel.unreadMessageCount > 0}>
  //                   <Styled.LockImage src={PrivateUserLogo} alt="private" />
  //                 </Styled.HiddenImage>
  //               ) : (
  //                 <Styled.ImageWrapper hasNewMessages={channel.unreadMessageCount > 0}>
  //                   {profile?.imageUrl && <img src={profile.imageUrl} height={30} width={30} alt={profile.fullName} />}
  //                 </Styled.ImageWrapper>
  //               )}
  //             </Styled.ChatItem>
  //           );
  //         })}
  //       </Styled.ChatsList>
  //     )}
  //   </Styled.MessagesBarWrapper>
  // );
};
