/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  GroupChannel,
  GroupChannelCollection,
  GroupChannelCollectionEventHandler,
  GroupChannelCreateParams,
  GroupChannelEventContext,
  GroupChannelFilter,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelListQueryParams,
  GroupChannelModule,
  Member,
  MessageCollection,
  MessageCollectionEventHandler,
  MessageCollectionInitPolicy,
  MessageEventContext,
  MessageFilter,
  QueryType
} from '@sendbird/chat/groupChannel';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import SendbirdChat, { UserUpdateParams, User, SendbirdChatWith } from '@sendbird/chat';
import { UserMessage, BaseMessage, MessageSearchQueryParams, MessageSearchQuery, MessageSearchOrder } from '@sendbird/chat/message';
import { QUERY_KEYS } from '../consts/app-keys.const';
// eslint-disable-next-line import/no-cycle
import { sendbirdService, profileService } from '../services';
import { IChannelsBasicInfo } from '../types/profile.type';
import { InboxState } from '../types/messages';
import { IUser } from '../types/user.type';
import { mapMessageSearchResults } from '../components/messages/utils/map-message-search-results';
import { useDebounce } from './use-debounce';

const appId = process.env.NEXT_PUBLIC_SENDBIRD_APP_ID ?? '';

const SEND_MESSAGE_CONTEXT = 'EVENT_MESSAGE_SENT_SUCCESS';
const READ_MESSAGES_CONTEXT = 'EVENT_CHANNEL_READ';
const EVENT_MESSAGE_RECEIVED = 'EVENT_MESSAGE_RECEIVED';

let sb: SendbirdChatWith<GroupChannelModule[]>;

export const useDirectMessages = () => {
  const queryClient = useQueryClient();
  return useQuery([QUERY_KEYS.CHANNELS, 'data'], () => queryClient.getQueryData<InboxState>([QUERY_KEYS.CHANNELS, 'data']));
};

export const useDirectMessagesChannels = (): [GroupChannel[], (state: GroupChannel[]) => void] => {
  const queryClient = useQueryClient();
  const { data } = useQuery([QUERY_KEYS.CHANNELS_STATE], () => queryClient.getQueryData<GroupChannel[]>([QUERY_KEYS.CHANNELS_STATE]));

  const setState = (state: GroupChannel[]) => {
    queryClient.setQueryData([QUERY_KEYS.CHANNELS_STATE], state);
    queryClient.invalidateQueries([QUERY_KEYS.CHANNELS_STATE]);
  };

  return [data ?? [], setState];
};

export const useDirectMessagesChannelsUserInfo = (): [IChannelsBasicInfo[], (state: IChannelsBasicInfo[]) => void] => {
  const queryClient = useQueryClient();
  const { data } = useQuery([QUERY_KEYS.CHANNELS_STATE_USER_INFO], () =>
    queryClient.getQueryData<IChannelsBasicInfo[]>([QUERY_KEYS.CHANNELS_STATE_USER_INFO])
  );

  const setState = (state: IChannelsBasicInfo[]) => {
    queryClient.setQueryData([QUERY_KEYS.CHANNELS_STATE_USER_INFO], state);
    queryClient.invalidateQueries([QUERY_KEYS.CHANNELS_STATE_USER_INFO]);
  };

  return [data ?? [], setState];
};

export const useDialogs = () => {
  const queryClient = useQueryClient();

  const { data } = useDirectMessages();

  const [uniqueUserIds, setUniqueUserIds] = useState<string[]>([]);
  const { data: newProfiles } = useQuery({
    queryKey: [QUERY_KEYS.MESSAGES_BASIC_INFO, ...uniqueUserIds],
    queryFn: () => {
      if (uniqueUserIds.length) {
        return profileService.getChannelsBasicInfo(uniqueUserIds);
      }
    },
    keepPreviousData: true,
    staleTime: 1000
  });

  const state: InboxState = data || {
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: '',
    channelNameUpdateValue: '',
    settingUpUser: true,
    file: null,
    currentUserId: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: true,
    error: false,
    isReactions: false,
    currentMessage: {},
    hasUnreadedMessages: false,
    userLoading: true,
    searchResults: [],
    searchQuery: null,
    selectedMessage: null
  };

  const [chatsUsersInfo, setChatsUsersInfo] = useDirectMessagesChannelsUserInfo();
  const [loadingChannels, setLoadingChannels] = useState(false);

  const stateRef = useRef(state);
  stateRef.current = state;
  const loadingMessageRef = useRef(false);
  const channelListQuery = useRef<GroupChannelCollection | null>(null);

  const allwowSendBird = useRef(true);

  const chatsUsersIds = useMemo(() => chatsUsersInfo.map((it) => it.id), [chatsUsersInfo]);

  const updateChannels = async (updatedChannels: any) => {
    await queryClient.setQueryData([QUERY_KEYS.CHANNELS, 'data'], updatedChannels);
  };

  const checkHasUnreadedMessages = (channels: GroupChannel[]): boolean => {
    let hasUnreadedMessages = false;
    channels.forEach((ch) => {
      if (hasUnreadedMessages) return;
      if (ch.unreadMessageCount > 0) {
        hasUnreadedMessages = true;
      }
    });
    return hasUnreadedMessages;
  };

  const channelHandlers: GroupChannelCollectionEventHandler = {
    onChannelsAdded: (context: any, channels: any) => {
      const updatedChannels = [
        ...channels.filter((channel: any) => !stateRef.current.channels.find((item) => item.url === channel.url)),
        ...stateRef.current.channels
      ];
      updateChannels({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });
    },
    onChannelsDeleted: (context: any, channels: any) => {
      const updatedChannels = stateRef.current.channels.filter((channel) => !channels.includes(channel.url));

      updateChannels({ ...stateRef.current, channels: updatedChannels });
    },
    onChannelsUpdated: (context: GroupChannelEventContext, channels: any) => {
      const { source } = context;
      const updatedChannels = stateRef.current.channels.map((channel) => {
        const updatedChannel = channels.find((incomingChannel: any) => incomingChannel.url === channel.url);
        if (updatedChannel) {
          return updatedChannel;
        }
        return channel;
      });
      const updatedState = {
        ...stateRef.current,
        channels: updatedChannels,
        currentlyJoinedChannel: stateRef.current.currentlyJoinedChannel
          ? updatedChannels.find((channel) => channel.url === stateRef.current.currentlyJoinedChannel?.url) ??
            stateRef.current.currentlyJoinedChannel
          : null
      };
      if (source === READ_MESSAGES_CONTEXT || source === EVENT_MESSAGE_RECEIVED) {
        updatedState.hasUnreadedMessages = checkHasUnreadedMessages(updatedChannels);
      }

      updateChannels(updatedState);
    }
  };

  const messageHandlers: MessageCollectionEventHandler = {
    onMessagesAdded: (context: MessageEventContext, channel: any, messages: any) => {
      const updatedMessages = [...stateRef.current.messages, ...messages];

      const updatedState = {
        ...stateRef.current,
        messages: updatedMessages
      };
      if (context.source === EVENT_MESSAGE_RECEIVED) {
        updatedState.hasUnreadedMessages = true;
      }

      updateChannels(updatedState);
    },
    onMessagesUpdated: (context: MessageEventContext, channel: any, messages: any) => {
      const { source } = context;
      const updatedMessages = [...stateRef.current.messages];
      // eslint-disable-next-line guard-for-in
      for (const i in messages) {
        const incomingMessage = messages[i];
        const indexOfExisting = stateRef.current.messages.findIndex((message) => incomingMessage.reqId === message.reqId);

        if (indexOfExisting !== -1) {
          updatedMessages[indexOfExisting] = incomingMessage;
        }
        if (!incomingMessage.reqId) {
          updatedMessages.push(incomingMessage);
        }
      }

      let { channels } = stateRef.current;
      if (source === SEND_MESSAGE_CONTEXT) {
        channels = [channel, ...stateRef.current.channels.filter((ch) => ch.url !== channel.url)];
      }

      updateChannels({
        ...stateRef.current,
        messages: updatedMessages,
        channels
      });
    },
    onMessagesDeleted: (context: any, channel: any, messageIds: any) => {
      const updateMessages = stateRef.current.messages.filter((message) => !messageIds.includes(message.messageId));

      updateChannels({ ...stateRef.current, messages: updateMessages });
    },
    onChannelUpdated: (context: any, channel: any) => {},
    onChannelDeleted: (context: any, channelUrl: any) => {},
    onHugeGapDetected: () => {}
  };

  const loadMessages = async (
    channel: GroupChannel,
    messageHandlers: MessageCollectionEventHandler,
    onCacheResult: (err: Error | null, messages: BaseMessage[] | null) => void,
    onApiResult: (err: Error | null, messages: BaseMessage[] | null, collection: MessageCollection) => void,
    foundMessageTs?: number
  ) => {
    const messageFilter = new MessageFilter();

    const collection = channel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      limit: 30,
      ...(foundMessageTs ? { startingPoint: foundMessageTs, limit: 2, prevResultLimit: 30, nextResultLimit: 30 } : {})
    });
    collection.setMessageCollectionHandler(messageHandlers);
    // collection.initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API).onCacheResult(onCacheResult).onApiResult(onApiResult);
    collection.initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API).onApiResult((err: Error | null, messages: BaseMessage[] | null,) => onApiResult(err, messages, collection));
  };

  const loadMoreMessage = async () => {
    if (state.messageCollection && state.messageCollection.hasPrevious) {
      const messages = await state.messageCollection.loadPrevious();

      updateChannels({ ...state, messages: [...messages.reverse(), ...state.messages] });
    }
  };

  const loadNextMessage = async () => {
    if (state.messageCollection && state.messageCollection.hasNext) {
      const messages = await state.messageCollection.loadNext();

      updateChannels({ ...state, messages: [...state.messages, ...messages.reverse()] });
    }
  };

  const handleJoinChannel = async (channelUrl: string, addedChannel?: GroupChannel, foundMessage?: BaseMessage) => {
    if (loadingMessageRef.current) return;

    const { channels } = state;

    loadingMessageRef.current = true;
    const channel = channels.find((channel) => channel.url === channelUrl);

    if (!!addedChannel && !channel) {
      stateRef.current = { ...stateRef.current, channels: [addedChannel, ...channels] };
    }

    const onCacheResult = (err: any, messages: any) => {
      updateChannels({
        ...stateRef.current,
        currentlyJoinedChannel: addedChannel ?? channel ?? null,
        messages: messages.reverse(),
        loadingMessage: false,
        selectedMessage: foundMessage || null
      });
    };

    const onApiResult = (err: any, messages: any, collection: MessageCollection) => {
      updateChannels({
        ...stateRef.current,
        messageCollection: collection,
        currentlyJoinedChannel: addedChannel ?? channel ?? null,
        messages: messages.reverse(),
        selectedMessage: foundMessage || null
      });
      loadingMessageRef.current = false;
    };

    loadMessages(addedChannel ?? channel!, messageHandlers, onCacheResult, onApiResult, foundMessage?.createdAt);
  };

  const createChannel = async (channelName: string, operatorUserIds: string[]): Promise<[GroupChannel | null, unknown | null]> => {
    try {
      const [createId, memberId] = operatorUserIds;
      const groupChannelParams: GroupChannelCreateParams = {};
      groupChannelParams.invitedUserIds = operatorUserIds;
      groupChannelParams.name = channelName;
      groupChannelParams.operatorUserIds = operatorUserIds;
      const groupChannel = await sb.groupChannel.createChannel(groupChannelParams);
      return [groupChannel, null];
    } catch (error) {
      return [null, error];
    }
  };

  const onError = (error: any) => {
    updateChannels({ ...state, error: error.message });
  };

  const handleCreateChannel = async (id: string, fullName: string, imageUrl?: string, authUser?: IUser) => {
    const channelName = `${state.currentUserId}_${id}`;
    const isUserExis = await sendbirdService.isUserExist(id);
    if (!isUserExis) {
      await sendbirdService.createUserSendbird({ user_id: id, nickname: fullName, profile_url: imageUrl });
    } else {
      const params: GroupChannelListQueryParams = {
        userIdsFilter: {
          userIds: [state.currentUserId!, id],
          includeMode: false,
          queryType: QueryType.AND
        }
      };
      const query: GroupChannelListQuery = sb.groupChannel.createMyGroupChannelListQuery(params);

      const channels: GroupChannel[] = await query.next();
      if (channels[0]) {
        handleJoinChannel(channels[0].url, channels[0]);
        return;
      }
    }
    const [groupChannel, error] = await createChannel(channelName, [state.currentUserId! as string, id]);

    if (groupChannel) {
      handleJoinChannel(groupChannel.url, groupChannel);
    }
    if (error) {
      return onError(error);
    }
  };

  const deleteChannel = async (channelUrl: string) => {
    try {
      const channel = await sb.groupChannel.getChannel(channelUrl);
      await channel.delete();
      return [channel, null];
    } catch (error) {
      return [null, error];
    }
  };

  const deleteMessage = async (currentlyJoinedChannel: GroupChannel, messageToDelete: BaseMessage) => {
    await currentlyJoinedChannel.deleteMessage(messageToDelete);
  };

  const inviteUsersToChannel = async (channel: GroupChannel, userIds: string[]) => {
    await channel.inviteWithUserIds(userIds);
  };

  const handleUpdateChannelMembersList = async () => {
    const { currentlyJoinedChannel, groupChannelMembers } = state;
    await inviteUsersToChannel(currentlyJoinedChannel!, groupChannelMembers);

    updateChannels({ ...state, applicationUsers: [] });
  };

  const handleDeleteChannel = async (channelUrl: string) => {
    const [channel, error] = await deleteChannel(channelUrl);
    if (error) {
      return onError(error);
    }
  };

  const handleDeleteMessage = async (messageToDelete: UserMessage) => {
    const { currentlyJoinedChannel } = state;
    await deleteMessage(currentlyJoinedChannel!, messageToDelete);
  };

  const updateMessage = async (message: UserMessage) => {
    updateChannels({ ...state, messageToUpdate: message, messageInputValue: message.message });
  };

  const getAllApplicationUsers = async (): Promise<[User[], null] | [null, unknown]> => {
    try {
      const userQuery = sb.createApplicationUserListQuery({ limit: 100 });
      const users = await userQuery.next();
      return [users, null];
    } catch (error) {
      return [null, error];
    }
  };

  const handleLoadMemberSelectionList = async () => {
    updateChannels({ ...state, currentlyJoinedChannel: null });
    const [users, error] = await getAllApplicationUsers();
    if (error) {
      return onError(error);
    }

    updateChannels({ ...state, currentlyJoinedChannel: null, applicationUsers: users!, groupChannelMembers: [sb.currentUser?.userId!] });
  };

  const addToChannelMembersList = (userId: string) => {
    const groupChannelMembers = [...state.groupChannelMembers, userId];

    updateChannels({ ...state, groupChannelMembers });
  };

  const loadChannels = async (channelHandlers: GroupChannelCollectionEventHandler) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;

    channelListQuery.current = sb.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      limit: 30
    });

    channelListQuery.current.setGroupChannelCollectionHandler(channelHandlers);

    const channels = await channelListQuery.current.loadMore();
    return [channels, null];
  };

  const loadMoreChannels = async (): Promise<void> => {
    if (!channelListQuery.current || loadingChannels) return;
    if (!channelListQuery.current.hasMore) return;
    setLoadingChannels(true);
    const newChannels = await channelListQuery.current.loadMore();
    const filteredNewChannels = newChannels.filter((channel) => !state.channels.find((item) => item.url === channel.url));
    updateChannels({ ...state, channels: [...state.channels, ...filteredNewChannels] });
    setLoadingChannels(false);
  };

  const setupUser = async (fullName: string, id: string, profileUrl: string, isMain: boolean) => {
    updateChannels({ ...state, userLoading: true });
    try {
      const isNotCurrentUser = state.currentUserId !== id;
      if (!sb || isNotCurrentUser) {
        const sendbirdChat = await SendbirdChat.init({
          appId,
          localCacheEnabled: true,
          modules: [new GroupChannelModule()]
        });

        sb = sendbirdChat;
      }

      if (sb.connectionState !== 'OPEN' || isNotCurrentUser) {
        if (isNotCurrentUser) {
          await sb.disconnect();
        }
        await sb.connect(id);
        await sb.setChannelInvitationPreference(true);
        const userUpdateParams: UserUpdateParams = {};
        userUpdateParams.nickname = fullName;
        userUpdateParams.profileUrl = profileUrl;
        await sb.updateCurrentUserInfo(userUpdateParams);
        if (!isMain) {
          updateChannels({ ...state, userLoading: false });
        }
      }
      if (isMain) {
        const [channels, error] = await loadChannels(channelHandlers);
        if (error) {
          return onError(error);
        }

        updateChannels({
          ...state,
          channels: channels!,
          userLoading: false,
          settingUpUser: false,
          loading: false,
          currentUserId: id,
          hasUnreadedMessages: checkHasUnreadedMessages(channels!)
        });
      }
      allwowSendBird.current = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const disconnectUser = () => {
    try {
      sb?.disconnect();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const handleLeaveChannel = async () => {
    const { currentlyJoinedChannel } = state;
    await currentlyJoinedChannel?.leave();
    updateChannels({ ...state, currentlyJoinedChannel: null });
  };

  const setCurrentChannel = (channel: GroupChannel | null) => {
    updateChannels({ ...state, currentlyJoinedChannel: channel });
  };

  const getChannlesUsers = async (channels: GroupChannel[]) => {
    if (channels.length < 1) {
      await setChatsUsersInfo([]);
      return;
    }
    const userIds = channels.map((channel) => channel.members.map((member) => member.userId));
    const uniqueUserIds = Array.from(new Set(userIds.flat()));

    if (chatsUsersIds.length !== uniqueUserIds.length || !chatsUsersIds.every((id) => uniqueUserIds.includes(id))) {
      setUniqueUserIds(uniqueUserIds);
    }
  };

  useEffect(() => {
    if (newProfiles) {
      setChatsUsersInfo(newProfiles);
    }
  }, [newProfiles]);

  const debounceData = useDebounce(state.channels, 1000);
  useEffect(() => {
    getChannlesUsers(debounceData);
  }, [debounceData]);

  const getInterlocutor = (members: Member[], userId: string): IChannelsBasicInfo => {
    const member = members.find((member) => member.userId !== userId);
    const interlocutor = chatsUsersInfo.find((user) => user.id === member?.userId);
    return interlocutor!;
  };
  const getCurrentUser = (userId: string) => {
    const currUser = chatsUsersInfo.find((user) => user.id === userId);
    return currUser!;
  };

  const searchMessages = async (keyword: string) => {
    if (!sb) return;
    const params: MessageSearchQueryParams = {
      keyword,
      limit: 15,
      exactMatch: false,
      order: MessageSearchOrder.TIMESTAMP,
      reverse: false
    };
    const query = sb.createMessageSearchQuery(params);
    const firstPageResults = await query.next();
    updateChannels({
      ...stateRef.current,
      searchResults: mapMessageSearchResults(firstPageResults as UserMessage[], keyword),
      searchQuery: query
    });
  };

  const getNextSearchPage = async () => {
    if (!stateRef.current.searchQuery || !stateRef.current.searchQuery.hasNext) return;
    const messages = await stateRef.current.searchQuery.next();
    const mappedResults = mapMessageSearchResults(messages as UserMessage[], stateRef.current.searchQuery.keyword);
    updateChannels({ ...stateRef.current, searchResults: [...stateRef.current.searchResults, ...mappedResults] });
  };

  const closeConversation = () => {
    updateChannels({ ...state, currentlyJoinedChannel: null, selectedMessage: null, messageCollection: null });
  };

  const resetMessagesSearchResults = () => {
    updateChannels({ ...stateRef.current, searchResults: [] });
  };

  return {
    allwowSendBird,
    setupUser,
    disconnectUser,
    setCurrentChannel,
    handleJoinChannel,
    handleCreateChannel,
    chatsUsersInfo,
    getInterlocutor,
    getCurrentUser,
    state,
    updateChannels,
    loadMoreChannels,
    searchMessages,
    loadMoreMessage,
    loadNextMessage,
    getNextSearchPage,
    closeConversation,
    resetMessagesSearchResults,
    sb
  };
};
