import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { BoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';
import { WEIGHTS } from '@/theme/fonts.const';

export const SentimentSwingsWrapper = styled(BoxAroundSection)``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BoxContainer = styled.div`
  position: absolute;
  height: 230px;
  width: 500px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  width: fit-content;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  column-gap: 30px;
  row-gap: 40px;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
`;

export const SentimentSwingsItemWrapper = styled.div`
  width: 500px;
  /* height: 281px; */
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.FAMILIES.robotoFlex};
`;

export const SwingsTitle = styled.div`
  display: inline;
  align-items: start;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 1px;
  white-space: pre-wrap;
`;

export const SwingsSubtitle = styled.span`
  color: #0018f1;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space: nowrap;
`;

export const HighlightedTitle = styled.p`
  display: inline;
  font-weight: ${WEIGHTS.semiBold};
`;

export const SwingDescriptoin = styled.div`
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const SwingsChartWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  min-height: 230px;
  max-height: 230px;
  padding-top: 3px;
`;

export const ChartDateLineContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: SPACE-BETWEEN;
  gap: 3px;
  width: 585px;
`;

export const ChartDateText = styled.div`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.light};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  color: #989898;
  z-index: 2;
  right: 85px;
  position: absolute;
`;

export const ChartDateLine = styled.div`
  position: relative;
  height: 1px;
  background: rgba(117, 117, 117, 0.2);
  width: 77%;
`;

export const ChartDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 4px;
`;

export const ChartDate = styled.div<{ horizontal: 'left' | 'right' }>`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.light};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  letter-spacing: -0.6px;
  color: #6d6d6d;
  bottom: 0;
  right: ${({ horizontal }) => (horizontal === 'right' ? '0' : 'none')};
  z-index: 2;
`;

export const YourVoteLineContainer = styled.div<{ answer: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${({ answer }) => (answer <= 3 ? '#4E0EB6' : '#0E5B57')};
  /* bottom: ${({ answer }) => `${answer}px`}; */
`;

export const YourVoteLine = styled.div`
  height: 1px;
  background-image: linear-gradient(to right, black 20%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  width: 87%;
`;

export const YourIcon = styled(Image)`
  width: 6px;
  height: 6px;
`;

export const YourVote = styled.div`
  color: #b6730e;
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.light};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  letter-spacing: 0;
  z-index: 2;
  white-space: nowrap;
  width: fit-content;
  background-color: white;
`;

const BullBearStyle = css`
  position: absolute;
  right: 0;
  font-size: ${FONTS.SIZES.s};
  font-weight: ${WEIGHTS.light};
  padding: 4px 0px 4px 5px;
  z-index: 2;
`;

export const Bull = styled.div`
  ${BullBearStyle}
  padding-top: 7px;
  color: #0e5b57;
  right: 0;
  top: 0;
`;

export const Bear = styled.div`
  ${BullBearStyle}
  color: #4E0EB6;
  bottom: 0;
  right: 0;
`;

export const ChartLineWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

export const titleSectionStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.normal};
  letter-spacing: 0.32px;
  color: ${COLORS.black};
`;

export const descriptionSectionStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.descriptionColorInBlock};
`;

export const Title = styled.div`
  ${titleSectionStyle}
`;

export const Description = styled.div`
  ${descriptionSectionStyle}
  font-weight: ${FONTS.WEIGHTS.extra_light};
`;
