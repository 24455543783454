import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 387px;
`;

export const Title = styled.p`
  display: flex;
  padding: 7px 0;
  margin-right: 5px;
  align-items: flex-start;
  gap: 10px;
  color: ${COLORS.lighterBlue};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;
