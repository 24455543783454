import styled from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { QuestionVoteComponent } from '@/common/components/question-vote';
import { SelectButtonComponent } from '@/common/components/select-button';
import StyleMaxLengthAreaComponent from '@/common/components/onboard/components/max-length-area';
import { FAMILIES } from '@/theme/fonts.const';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  margin-bottom: 26px;
`;

export const ActionButtonsContainer = styled.div`
  padding-left: 35px;
  margin-top: 35px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.div`
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 40px;
  border-radius: 10px;
  background: #e3e3e3;
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const CloseImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const Section = styled.div`
  max-width: 497px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StepContainer = styled.div`
  display: flex;
  gap: 7px;
  height: 12px;
  margin-bottom: 10px;
`;

export const StepTitle = styled.div<{ isActive?: boolean }>`
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.s};
  color: ${({ isActive }) => (isActive ? '#000' : '#8A8A8A')};
`;

export const StepTitleAppear = styled(StepTitle)`
  color: #8a8a8a;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const StepText = styled.div<{ isActive?: boolean }>`
  color: #979797;

  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const StepCaption = styled.div<{ isDot?: boolean }>`
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.s};
  color: #9f9f9f;
  ${({ isDot }) => isDot && 'color: #c4c4c4;'}
`;

export const StepDot = styled(StepCaption)`
  color: #c4c4c4;
`;

export const InputText = styled(StyleMaxLengthAreaComponent)`
  border-radius: 10px;
  border: 1px solid #f5f5f5;

  background: #fbfbfb;
`;

export const SelectYearList = styled.div`
  display: flex;
  gap: 5px;
`;

export const SelectYearItem = styled(SelectButtonComponent)<{ isDisabled?: boolean; selected?: boolean }>`
  border-radius: 10px;
  background: ${({ selected }) => (selected ? '#EFF1FF' : '#F6F6F6')};
  color: ${({ selected }) => (selected ? '#0016D8' : '#000')};
  padding: 8px 12px;
  font-size: 14px;
  height: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;

  ${({ isDisabled }) =>
    isDisabled &&
    `color: #969696;
    cursor: default;
    background: #F6F6F6;
    `}

  :disabled {
    color: #969696;
    cursor: default;
    background: #f6f6f6;
  }
`;

export const PreviewText = styled.div`
  color: #515151;
  padding: 10px 0;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow-wrap: break-word;
`;

export const QuestionVote = styled(QuestionVoteComponent)`
  width: 100%;
`;
