import React, { useState } from 'react';
import * as Styled from '../modal-default.styled';
import { SlideType } from '@/common/types/modal.type';
import { ActivateAnonInformationBlockComponent } from '../../information-blocks/anon-information-block/anon-information-block.component';
import { AnonymitySelectButton } from '../../ui/anonymity-select-button/anonymity-select-button.component';
import { AnonymityToEveryoneForm } from './forms/anonymity-to-everyone.form';
import { AnonymityToEveryoneAndUsForm } from './forms/anonymity-to-everyone-and-us.form';
import { FormType } from '@/common/types/anon.types';

interface IActivateAnonModalProps {
  open: boolean;
  closeHandler: () => void;
}

export const ActivateAnonModal = ({ open, closeHandler }: IActivateAnonModalProps) => {
  const [activeForm, setActiveForm] = useState<FormType | null>(null);
  const [interacted, setInteracted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [usernameToCheck, setUsernameToCheck] = useState('');
  const [isUsernameConfirmed, setIsUsernameConfirmed] = useState(false);
  const [isUsernameReserved, setIsUsernameReserved] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);
  const [linkClicked, setLinkClicked] = useState(false);

  const [saveFormData, setSaveFormData] = useState({
    email: '',
    username: '',
    password: '',
    activeForm: FormType.Everyone
  });
  
  const setDefault = () => {
    setIsFormValid(false);
    setUsernameToCheck('');
    setIsUsernameConfirmed(false);
    setIsUsernameReserved(false);
    setIsMessageSent(false);
    setSaveFormData({
      email: '',
      username: '',
      password: '',
      activeForm: FormType.Everyone
    });
  };

  const onClose = () => {
    closeHandler();
    setTimeout(() => {
      setActiveForm(null);
      setInteracted(false);
      setDefault();
    }, 300);
  };

  const onBack = () => {
    setActiveForm(null);
  };

  const handleLinkClick = () => {
    setLinkClicked(true);
  };

  const resetLinkClicked = () => {
    setLinkClicked(false);
  };

  const handleButtonClick = (formType: FormType) => {
    if (activeForm === formType) {
      setActiveForm(null);
      setInteracted(false);
    } else {
      setActiveForm(formType);
      setInteracted(true);
    }
  };


  return (
    <Styled.StyledModal
      open={open}
      closeHandler={onClose}
      isCenter
      slideType={SlideType.BOTTOM}
      openTimeout={800}
      closeTimeout={600}
      hideChildBackground
      hideChildeShadow
      hideBackdrop
      hideBorder
      childTopPostion={0}
      isUserModeSwitchModal
    >
      
        <>
          {activeForm === null ? (
            <Styled.ModalWrapper>
              <ActivateAnonInformationBlockComponent handleClose={onClose} />
              <Styled.ModalDivider formType={FormType.Everyone || FormType.EveryoneAndUs} />
              <Styled.AnonymityActionsBlockContainer>
                <Styled.AnonymityActionsOptions>
                  <Styled.AnonymityActionsOption>Option 1</Styled.AnonymityActionsOption>
                  <Styled.AnonymityActionsOptionText>
                    Only Villan could identify the owner of an Anonymous Account
                  </Styled.AnonymityActionsOptionText>
                </Styled.AnonymityActionsOptions>
                <AnonymitySelectButton
                  title="anonymity to other users"
                  active={activeForm === FormType.Everyone || activeForm === null}
                  onClick={() => {
                    handleButtonClick(FormType.Everyone);
                    if (activeForm !== FormType.Everyone) setDefault();
                  }}
                  formType={FormType.Everyone}
                  interacted={interacted}
                />
              </Styled.AnonymityActionsBlockContainer>
              <Styled.ModalDivider formType={FormType.Everyone || FormType.EveryoneAndUs} />
              <Styled.AnonymityActionsBlockContainer>
                <Styled.AnonymityActionsOptions>
                  <Styled.AnonymityActionsOption>Option 2</Styled.AnonymityActionsOption>
                  <Styled.AnonymityActionsOptionText>
                    Villan can never identify the owner of an Anonymous Account
                  </Styled.AnonymityActionsOptionText>
                </Styled.AnonymityActionsOptions>
                <AnonymitySelectButton
                  title="anonymity to other users and Villan"
                  active={activeForm === FormType.EveryoneAndUs || activeForm === null}
                  onClick={() => {
                    handleButtonClick(FormType.EveryoneAndUs);
                    if (activeForm !== FormType.EveryoneAndUs) setDefault();
                  }}
                  formType={FormType.EveryoneAndUs}
                  interacted={interacted}
                />
                <Styled.AnonymityActionsRequireText>you will need to provide a new email address</Styled.AnonymityActionsRequireText>
              </Styled.AnonymityActionsBlockContainer>
            </Styled.ModalWrapper>
          ) : (
            <>
              {activeForm === FormType.Everyone && (
                  <AnonymityToEveryoneForm
                    onClose={onClose}
                    linkClicked={linkClicked}
                    handleLinkClick={handleLinkClick}
                    usernameToCheck={usernameToCheck}
                    isUsernameReserved={isUsernameReserved}
                    isUsernameConfirmed={isUsernameConfirmed}
                    formType={FormType.Everyone}
                    resetLinkClicked={resetLinkClicked}
                    setIsFormValid={setIsFormValid}
                    setUsernameToCheck={setUsernameToCheck}
                    setIsUsernameReserved={setIsUsernameReserved}
                    setIsUsernameConfirmed={setIsUsernameConfirmed}
                    isFormValid={isFormValid}
                    onBack={onBack}
                  />
              )}
              {activeForm === FormType.EveryoneAndUs && (
                <AnonymityToEveryoneAndUsForm
                  onClose={onClose}
                  formType={FormType.EveryoneAndUs}
                  linkClicked={linkClicked}
                  usernameToCheck={usernameToCheck}
                  handleLinkClick={handleLinkClick}
                  isUsernameReserved={isUsernameReserved}
                  isUsernameConfirmed={isUsernameConfirmed}
                  resetLinkClicked={resetLinkClicked}
                  setIsUsernameConfirmed={setIsUsernameConfirmed}
                  setUsernameToCheck={setUsernameToCheck}
                  setIsUsernameReserved={setIsUsernameReserved}
                  setIsFormValid={setIsFormValid}
                  setIsMessageSent={setIsMessageSent}
                  isMessageSent={isMessageSent}
                  isFormValid={isFormValid}
                  onBack={onBack}
                  onResetUserEmail={() => {
                    setIsMessageSent(false);
                    setIsFormValid(false);
                    handleLinkClick();
                  }}
                />
              )}
            </>
          )}
      </>
          
    </Styled.StyledModal>
  );
};
