import React, { useState } from 'react';
import DeletePostIcon from '@styles/icons/delete-post-icon.webp';
import CopiedPostIcon from '@styles/icons/copied-post-icon.webp';
import Image from 'next/image';
import * as Styled from './dropdown-menu.style';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IProps {
  postId: string;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  onDelete?: () => void;
}

export const SourceMenuComponent: React.FC<IProps> = ({ postId, anchorEl, setAnchorEl, onDelete }) => {
  const open = Boolean(anchorEl);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isCopyClicked, setIsCopyClicked] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setIsCopyClicked(false);
    setIsDeleteClicked(false);
  };

  const onCopyClick = () => {
    setIsDeleteClicked(false);
    if (typeof window !== 'undefined') {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      navigator.clipboard.writeText(`${baseUrl}${ROUTER_KEYS.PROFILE}?postId=${postId}`).then(() => setIsCopyClicked(true));
    }
  };

  const onDeleteClick = () => {
    setIsCopyClicked(false);
    setIsDeleteClicked(true);
  };

  return (
    <Styled.DropdownMenu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
      <button type="button" onClick={onCopyClick}>
        {isCopyClicked ? <span>copied</span> : 'copy url'}
        {isCopyClicked && (
          <Image
            src={CopiedPostIcon}
            width={14}
            height={14}
            style={{
              margin: '3px'
            }}
            alt="delete post icon"
          />
        )}
      </button>
      {onDelete && (
        <button type="button" onClick={isDeleteClicked ? onDelete : onDeleteClick}>
          {isDeleteClicked ? <p>confirm</p> : 'delete'}
          {isDeleteClicked && (
            <Image
              src={DeletePostIcon}
              width={14}
              height={14}
              style={{
                margin: '3px'
              }}
              alt="delete post icon"
            />
          )}
        </button>
      )}
    </Styled.DropdownMenu>
  );
};
