import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';

export const EditorContainer = styled.div<{ isEmpty: boolean; minHeight?: number }>`
  position: relative;
  margin-top: 5px;
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.light};
  font-style: normal;
  font-size: 14px;
  color: ${COLORS.black};
  line-height: 20px;
  min-height: ${({ minHeight }) => minHeight || 40}px;
  width: 100%;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  &:focus {
    outline: none;
  }

  &:before {
    color: ${COLORS.secondLightGray};
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    content: attr(placeholder);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const EditorContainerSummary = styled.div<{ isEmpty: boolean }>`
  position: relative;
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.light};
  font-style: normal;
  font-size: 14px;
  color: ${COLORS.black};
  line-height: 22px;
  min-height: 88px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  &:focus {
    outline: none;
  }

  &:before {
    color: ${COLORS.summaryTextArea};
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    content: attr(placeholder);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const BasicEditorContainer = styled.div<{ isEmpty: boolean }>`
  white-space: pre-wrap;
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.light};
  font-style: normal;
  font-size: 14px;
  color: ${COLORS.black};
  line-height: 22px;
  min-height: 88px;
  width: 100%;
  overflow-wrap: break-word;
  &:focus {
    outline: none;
  }
`;

export const EditorContainerThesis = styled(BasicEditorContainer)`
  min-height: 24px;
  &:before {
    color: #c1c1c1;
    font-family: ${FAMILIES.robotoFlex};
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    content: attr(placeholder);
    position: absolute;
    top: 7px;
    left: 0;
  } 

  ${({ isEmpty }) =>
    !isEmpty &&
    `
        font-family: ${FAMILIES.robotoFlex};
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.3px;
    `}
`;

export const EditorContainerReturns = styled(BasicEditorContainer)`
  min-height: 66px;
  &:before {
    font-style: italic;
    color: rgba(99, 99, 99, 0.5);
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    white-space: pre-wrap;
    content: attr(placeholder);
    position: absolute;
    width: 600px;
    top: 29px;
    left: 0;
  }
  &:after {
    color: rgba(99, 99, 99, 0.5);
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    white-space: pre-wrap;
    content: 'Type here    500 character limit';
    position: absolute;
    width: 600px;
    top: 7px;
    left: 0;
  }
`;

export const EditorContainerReturnsDetails = styled(BasicEditorContainer)`
  min-height: 22px;
  ${({ isEmpty }) => isEmpty && 'height: 22px;'}
  &:before {
    color: rgba(99, 99, 99, 0.5);
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    white-space: pre-wrap;
    content: attr(placeholder);
    position: absolute;
    width: 600px;
    top: 7px;
    left: 0px;
  }
`;

export const EditorContainerNewDebate = styled.div<{ isEmpty: boolean }>`
  position: relative;

  border-radius: 10px;
border: 1px solid #F5F5F5;
color: #000;
background: #FBFBFB;
display: flex;
width: 505px;
padding: 10px;
align-items: center;
gap: 7px;
  &:focus {
    outline: none;
  }

font-family: ${FAMILIES.robotoFlex}
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
overflow: hidden;


  &:before {
    color: #969696;
    pointer-events: none;
    font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    content: attr(placeholder);
    position: absolute;
    top: 10;
    left: 10;
  }
`;
