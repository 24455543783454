import { EUnits } from '@/common/types/poll.types';

export const calculateChartPoints = (
  midPoint: number
): {
  topPoint: number;
  bottomPoint: number;
} => {
  const topPoint = parseFloat((midPoint * 1.5).toFixed(2));
  const bottomPoint = parseFloat((midPoint * 0.5).toFixed(2));

  return {
    topPoint,
    bottomPoint
  };
};

export const getUnitText = (unit: EUnits) => {
  if (unit === EUnits.THOUSANDS) {
    return 'K';
  }
  if (unit === EUnits.BILLIONS) {
    return 'B';
  }
  if (unit === EUnits.PERCENTAGES) {
    return '%';
  }
  if (unit === EUnits.MILLIONS) {
    return 'M';
  }
  if (unit === EUnits.NONE) {
    return '';
  }
};

export const calculateIntervals = (startDate: string | Date, endDate: string, stepHours: number) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const intervals = [];

  for (let date = new Date(start); date <= end; date.setHours(date.getHours() + stepHours)) {
    intervals.push(new Date(date));
  }

  return intervals;
};

export const calculateIntervalsExcludeEndAndStart = (startDate: string | Date, endDate: string | Date, stepHours: number) => {
  const start = new Date(startDate);
  start.setHours(start.getHours() + stepHours);
  const end = new Date(endDate);
  const intervals = [];

  for (let date = new Date(start); date < end; date.setHours(date.getHours() + stepHours)) {
    intervals.push(new Date(date));
  }

  return intervals;
};
