import React, { useEffect, useRef, useState } from 'react';
import { BEAR_ANSWER, BULL_ANSWER, NEUTRAL_ANSWER, SKIP_ANSWER } from '@/common/consts/idea.const';
import * as Styled from './idea-vote.styled';

interface IIdeaVoteComponentProps {
  text?: string;
  horizonText?: string;
  answer?: number | null; // (skip, bear, neutral, bull) - in constants, null/undefined - not voted
  answerCallback?: (answer: number) => void;
  allowChange?: boolean;
  className?: string;
  isOnboarding?: boolean;
  isLatest?: boolean;
  fn?: (value: string) => void;
  scrollTo?: string;
  isFirst?: boolean;
  isDisabled?: boolean;
  isCoverageModal?: boolean;
  width?: number;
  borderRadius?: number;
}

export const IdeaVoteComponent = ({
  text,
  horizonText,
  answer,
  answerCallback,
  allowChange,
  className,
  isOnboarding,
  isLatest,
  isFirst,
  isCoverageModal,
  isDisabled,
  width,
  fn,
  scrollTo,
  borderRadius
}: IIdeaVoteComponentProps) => {
  const [currentAnswer, setCurrentAnswer] = useState(answer);

  useEffect(() => {
    setCurrentAnswer(answer);
  }, [answer]);

  const clickHandler = (newAnswer: number) => {
    if ((!!currentAnswer || currentAnswer === SKIP_ANSWER) && !allowChange) {
      return;
    }
    setCurrentAnswer(newAnswer);
    if (answerCallback) {
      const prevDisabled = isDisabled;
      answerCallback(newAnswer);
      if (isLatest && !prevDisabled && fn && scrollTo) fn(scrollTo);
    }
  };

  return (
    <Styled.IdeaVoteWrapper
      className={className}
      isOnboarding={isOnboarding}
      isFirst={isFirst}
      isLatest={isLatest || (!currentAnswer && currentAnswer !== 0)}
      isDisabled={isDisabled || !!currentAnswer || currentAnswer === 0}
    >
      {(!!text || !!horizonText) && (
        <Styled.IdeaTitle isOnboarding={isOnboarding}>
          {!!text && (
            <Styled.IdeaText width={width} isOnboarding={isOnboarding}>
              {text}
            </Styled.IdeaText>
          )}
          {!!horizonText && <Styled.IdeaHorizonText isOnboarding={isOnboarding}>{horizonText}</Styled.IdeaHorizonText>}
        </Styled.IdeaTitle>
      )}
      <Styled.IdeaVoteContainer>
        <Styled.IdeaVoteItemsWrapper isOnboarding={isOnboarding}>
          <Styled.BearVoteItem
            isCoverageModal={isCoverageModal}
            allowChange={!!allowChange}
            isChosen={currentAnswer === BEAR_ANSWER}
            isDisabled={currentAnswer !== null && currentAnswer !== BEAR_ANSWER}
            onClick={() => clickHandler(BEAR_ANSWER)}
            isOnboarding={false}
            isOnThink={currentAnswer === undefined}
            borderRadius={borderRadius}
          >
            bear
          </Styled.BearVoteItem>
          <Styled.BullVoteItem
            isCoverageModal={isCoverageModal}
            allowChange={!!allowChange}
            isChosen={currentAnswer === BULL_ANSWER}
            isDisabled={currentAnswer !== null && currentAnswer !== BULL_ANSWER}
            onClick={() => clickHandler(BULL_ANSWER)}
            isOnboarding={false}
            isOnThink={currentAnswer === undefined}
            borderRadius={borderRadius}
          >
            bull
          </Styled.BullVoteItem>
        </Styled.IdeaVoteItemsWrapper>

        <Styled.IdeaVoteItemsWrapper isOnboarding={isOnboarding}>
          <Styled.NeutralVoteItem
            isCoverageModal={isCoverageModal}
            allowChange={!!allowChange}
            isChosen={currentAnswer === NEUTRAL_ANSWER}
            isDisabled={currentAnswer !== null && currentAnswer !== NEUTRAL_ANSWER}
            onClick={() => clickHandler(NEUTRAL_ANSWER)}
            isOnboarding={false}
            borderRadius={borderRadius}
          >
            neutral
          </Styled.NeutralVoteItem>
          {/* <Styled.SkipVoteItem
            allowChange={!!allowChange}
            isChosen={currentAnswer === SKIP_ANSWER}
            isDisabled={currentAnswer !== null && currentAnswer !== SKIP_ANSWER}
            onClick={() => clickHandler(SKIP_ANSWER)}
            isOnboarding={false}
            borderRadius={borderRadius}
          >
            skip
          </Styled.SkipVoteItem> */}
        </Styled.IdeaVoteItemsWrapper>
      </Styled.IdeaVoteContainer>
    </Styled.IdeaVoteWrapper>
  );
};
