import React from 'react';
import { useRouter } from 'next/router';
import { Direction, IMemoSection } from '@/common/types/memo.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import * as Styled from './display-memo-section.styled';
import { FlexComponent } from '@/common/components/flex';
import { getSectionText, getSectionTitle } from './model/helpers';

export interface IDisplayMemoSectionComponent {
  memoSection: IMemoSection;
  isQuote: boolean | undefined;
}

export const DisplayMemoSectionComponent = ({ memoSection, isQuote }: IDisplayMemoSectionComponent) => {
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (isQuote) return;
    e.stopPropagation();
    router.push(`${ROUTER_KEYS.MEMO_PUBLISH}/${memoSection.memoId}`);
  };

  return (
    <Styled.MemoWrapper isQuote={isQuote}>
      <div>
        <Styled.MemoTitle style={{ width: '46px' }}>idea</Styled.MemoTitle>
        <Styled.LinkOnMemo isShort={memoSection.direction === Direction.SHORT} isQuote={isQuote} onClick={handleClick}>
          {memoSection?.title.split('  ')[0]} &#160;{memoSection?.title.split('  ')[1]}
        </Styled.LinkOnMemo>
      </div>
      <FlexComponent gap="10px">
        <div>
          <Styled.MemoTitle>{getSectionTitle(memoSection)}</Styled.MemoTitle>
          <Styled.MemoText isQuote={isQuote}>{getSectionText(memoSection)}</Styled.MemoText>
        </div>
        {!!memoSection.risk && (
          <div>
            <Styled.MemoTitle style={{ width: '76px' }}>risk / reward</Styled.MemoTitle>
            <Styled.MemoText isQuote={isQuote}>{memoSection.risk}x</Styled.MemoText>
          </div>
        )}
      </FlexComponent>
    </Styled.MemoWrapper>
  );
};
