import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { QUERY_KEYS, ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { questionService } from '@/common/services/question.service';
import { IAddQueryAnswerPayload, IQuestionPayload, IQuestionProfileResponse } from '../types/question.type';
import { IStatistic, ISubPost } from '../types/post.type';
import { setPostStatistics } from '../utils/post-statistics';
import { useAllAdoratedPosts, useAllHomePosts, useAllProfilePosts, useUpdateTreadingQuestion } from './use-post';
import { useAllUserBookmarks } from './use-bookmarks';
import { useUpdateQuestionAnswerInAllPosts } from './use-algolia';

export const useSectorQuestions = () =>
  useQuery({
    queryKey: [QUERY_KEYS.QUESTION_SECTORS],
    queryFn: () => questionService.getSectorQuestions()
  });

export const useCompanyQuestions = () =>
  useQuery({
    queryKey: [QUERY_KEYS.QUESTION_COMPANIES],
    queryFn: () => questionService.getCompaniesQuestions()
  });

export const useQueryQuestions = () =>
  useQuery({
    queryKey: [QUERY_KEYS.QUESTION_QUERIES],
    queryFn: () => questionService.getQueriesQuestions()
  });

export const useQuerySentimentProfileQuestions = (profileId: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEYS.QUESTION_QUERIES, 'sentimentProfile', profileId],
    queryFn: () => questionService.getSentimentProfileQuestions(profileId),
    onSuccess: (sentiments) => {
      if (!sentiments) return;
      const data = [
        ...sentiments.aligned,
        ...sentiments.variantAgreed,
        ...sentiments.variantDisagreed,
        ...sentiments.awaitingForResult
      ].map((item) => ({
        ...item.post,
        id: item.postId
      }));

      data.forEach((item) => {
        setPostStatistics(item as IStatistic, queryClient);
      });
    }
  });
};

export const useQuerySentimentHomeQuestions = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEYS.QUESTION_QUERIES, 'sentimentHome'],
    queryFn: () => questionService.getSentimentHomeQuestions(),
    onSuccess: (sentiments) => {
      if (!sentiments) return;
      const data = sentiments.map((item) => ({ ...item.post, id: item.postId }));

      data.forEach((item) => {
        setPostStatistics(item as IStatistic, queryClient);
      });
    }
  });
};

export const useQuerySentimentSwings = () =>
  useQuery({
    queryKey: [QUERY_KEYS.SENTIMENT_SWINGS],
    queryFn: () => questionService.getSentimentSwings()
  });

export const useCreateQuestion = () => {
  const queryClient = useQueryClient();
  const { setNewPosts } = useAllProfilePosts();
  const { setNewPosts: setNewHomePostsFollowing } = useAllHomePosts(false);
  const { setNewPosts: setNewHomePosts } = useAllHomePosts(true);

  return useMutation({
    mutationKey: [QUERY_KEYS.QUESTION_QUERIES],
    mutationFn: (data: IQuestionPayload) => questionService.createQuestion(data),
    onSuccess: (data) => {
      if (!data) return;

      setNewPosts([data], data.profileId, true);
      setNewHomePostsFollowing([data], data.profileId, true);
      setNewHomePosts([data], data.profileId, true);
      setPostStatistics(data, queryClient);

      if (data.question && data.question.ownerProfileId) {
        const sentimentsData = queryClient.getQueryData<IQuestionProfileResponse>([
          QUERY_KEYS.QUESTION_QUERIES,
          'sentimentProfile',
          data.question.ownerProfileId
        ]);
        if (!sentimentsData) return;
        const newSentimentData = {
          ...sentimentsData,
          awaitingForResult: [
            ...(sentimentsData.awaitingForResult ?? []),
            {
              id: data.question.id,
              user: data.question.user,
              text: data.question.text,
              postId: data.id,
              post: { ...data },
              postProfileId: data.profileId,
              averageUsers: data.question.averageAnswer,
              answer: data.question.ownAnswer,
              isResult: data.question.isResult,
              type: data.question.type
            }
          ]
        };
        queryClient.setQueryData([QUERY_KEYS.QUESTION_QUERIES, 'sentimentProfile', data.question.ownerProfileId], newSentimentData);
      }
    }
  });
};

export const useAddQueryAnswer = (
  userProfileId?: string,
  cb?: () => void,
  updateQuestionInModal?: (post: ISubPost) => void,
  isExecuteCb: boolean = false
) => {
  const queryClient = useQueryClient();
  const { setNewQueryAnswer } = useAllProfilePosts();
  const { setNewQueryAnswer: setNewQueryAnswerHomeFollowing } = useAllHomePosts(false);
  const { setNewQueryAnswer: setNewQueryAnswerHome } = useAllHomePosts(true);
  const { pathname } = useRouter();
  const { updateQuestions: updateQuestionsInTreandings } = useUpdateTreadingQuestion();
  const { updateQuestionInBookmark } = useAllUserBookmarks();
  const { updateQuestionInAdorations } = useAllAdoratedPosts(userProfileId ?? '');
  const { updateQuestionsOnSearchResult } = useUpdateQuestionAnswerInAllPosts();
  return useMutation({
    mutationKey: [QUERY_KEYS.QUESTION_QUERIES],
    mutationFn: (data: IAddQueryAnswerPayload) => questionService.addQueryAnswer(data),
    onSuccess: (data) => {
      if (cb !== undefined && isExecuteCb) {
        cb();
      }

      if (!data || !data.post) return;
      if (updateQuestionInModal) {
        updateQuestionInModal(data.post);
      }
      setPostStatistics(data.post, queryClient);
      if (pathname.includes(ROUTER_KEYS.PROFILE)) {
        setNewQueryAnswer(data.post, data.post.profileId);
      }
      if (pathname.includes(ROUTER_KEYS.HOME)) {
        setNewQueryAnswerHomeFollowing(data.post, userProfileId ?? '');
        setNewQueryAnswerHome(data.post, userProfileId ?? '');
        updateQuestionsInTreandings(data.post);
      }
      if (pathname.includes(ROUTER_KEYS.BOOKMARKS)) {
        updateQuestionInBookmark(data.post);
        updateQuestionInAdorations(data.post);
      }
      if (pathname.includes(ROUTER_KEYS.SEARCH_PAGE)) {
        updateQuestionsOnSearchResult(data.post);
      }

      queryClient.invalidateQueries(QUERY_KEYS.QUESTION_QUERIES);
    }
  });
};

export const useFollowedQueries = () =>
  useQuery({
    queryKey: [QUERY_KEYS.QUESTION_QUERIES, 'followed'],
    queryFn: () => questionService.getFollowedQueries(),
  });

export const useQueryAnswer = (questionId?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.QUESTION_ANSWER, questionId],
    queryFn: () => (questionId ? questionService.getQueryAnswer(questionId) : null)
  });
