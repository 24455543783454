import styled from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';

export const VoteInputContainer = styled.div`
  position: relative;
`;

export const VoteInput = styled.input`
  outline: none;
  color: #000;
  width: 390px;
  height: 40px;
  display: flex;
  padding: 10px;
  padding-right: 215px;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #fbfbfb;

  &::placeholder {
    color: #969696;
    opacity: 1;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const VoteInputText = styled.p`
  position: absolute;
  left: 177px;
  top: 50%;
  transform: translateY(-50%);
  color: #4a4a4a;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const VoteInputButton = styled.button<{ isConfirm?: boolean }>`
  display: flex;
  border: none;
  height: 40px;
  width: 100px;
  padding: 11px 15px;
  align-items: center;
  gap: 2px;
  border-radius: 10px;
  color: #fff;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  background: #8a8a8a;

  & p {
    min-width: 50px;
  }

  cursor: pointer;

  &:disabled {
    background: rgba(138, 138, 138, 0.2);
  }

  ${({ isConfirm }) =>
    isConfirm &&
    `
  background: #00A880;
  `}
`;

export const VoteInputButtonIcon = styled(Image)`
  width: 12px;
  height: 12px;
  margin: 3px;
`;
