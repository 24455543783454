import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './stock-entry-modal.styled';
import { useCompanies, useCompaniesBySearch, useCompaniesCoverageBySearchUnique, useDebounce } from '@/common/hooks';
import { ICompanySearchFilter, ISelectedCompany } from '@/common/types/coverage.type';
import { coverageService } from '@/common/services';

interface IProps {
  activeCompanies: ISelectedCompany[] | null;
  onCompanyClick: (companyData: ISelectedCompany) => void;
  onboardStep?: number;
  variant?: 'small' | 'poll-modal';
  placeholder?: string;
  uniqCompanies?: boolean;
}

export const StockEntryModalComponent = ({
  activeCompanies,
  placeholder = 'Enter company or ticker name',
  onboardStep,
  onCompanyClick,
  variant,
  uniqCompanies
}: IProps) => {
  const [company, setCompany] = useState('');
  const [companies, setCompanies] = useCompanies();
  const [isQueryUsed, setIsQueryUsed] = useState(false);
  const [refetchOneCompany, setRefetchOneCompany] = useState(true);
  const securityInput = useRef<HTMLInputElement | null>(null);

  const debouncedForCompany = useDebounce<ICompanySearchFilter>({ symbol: company }, 400);
  const { refetch: refetchCompany } = uniqCompanies
    ? useCompaniesCoverageBySearchUnique(debouncedForCompany, setCompanies, setIsQueryUsed)
    : useCompaniesBySearch(debouncedForCompany, setCompanies);

  const onCompanyEnter = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
    setCompanies(null);
  };

  useEffect(() => {
    if (refetchOneCompany) {
      refetchCompany();
    }
    setRefetchOneCompany(false);
  }, [debouncedForCompany]);

  const filteredCompanies = useMemo(
    () =>
      companies?.filter((item) => {
        if (!item.name) return false;
        if (!activeCompanies) return true;

        const findInCompanies = activeCompanies.find((it) => it.id === item.id);
        if (!findInCompanies) {
          return true;
        }
        return false;
      }),
    [companies, activeCompanies]
  );

  useEffect(() => {
    if (securityInput && onboardStep && onboardStep >= 4 && onboardStep !== 7) securityInput.current?.focus();
  }, [activeCompanies, onboardStep]);

  const isTextShortened = (value: boolean, text: string) => {
    const width = 175;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return value ? false : text;
    const font = 'Roboto Flex';
    const fontSize = 14;
    ctx.font = `${fontSize}px ${font}`;
    let textWidth = ctx.measureText(text).width;

    if (!value && textWidth > width) {
      let truncatedText = text;
      let left = 0;
      let right = text.length - 1;

      while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        truncatedText = text.slice(0, middle);
        textWidth = ctx.measureText(truncatedText).width;

        if (textWidth > 170) {
          right = middle - 1;
        } else {
          left = middle + 1;
        }
      }

      truncatedText = `${text.slice(0, right)} ..`;
      return truncatedText;
    }
    if (!value && textWidth <= width) {
      return text;
    }

    return textWidth > width;
  };

  return (
    <div>
      <Styled.Input ref={securityInput} value={company} placeholder={placeholder} onChange={onCompanyEnter} variant={variant} />
      <div style={{ position: 'relative', width: 0, height: 0 }}>
        {!!filteredCompanies && (filteredCompanies.length > 0 || uniqCompanies) && (
          <Styled.Dropdown variant={variant}>
            {filteredCompanies?.map((item) => (
              <Styled.DropdownItem
                variant={variant}
                onClick={() => {
                  setCompany('');
                  setCompanies(null);
                  onCompanyClick(item);
                }}
                key={`company-key-${item.id}`}
              >
                {variant === 'small' ? (
                  <Styled.ItemText>
                    {item.id === item.name ? 'private' : item.id}&nbsp;&nbsp;{item.name}
                  </Styled.ItemText>
                ) : (
                  <Styled.ItemText>
                    {isTextShortened(true, `${item.id} ${item.name}`) ? (
                      <span>
                        {item.id === item.name ? 'private' : item.id}&nbsp;&nbsp;{isTextShortened(false, `${item.name}`)}
                      </span>
                    ) : (
                      <span>
                        {item.id === item.name ? 'private' : item.id}&nbsp;&nbsp;{item.name}
                      </span>
                    )}
                  </Styled.ItemText>
                )}
              </Styled.DropdownItem>
            ))}

            {uniqCompanies && (
              <Styled.DropdownItem
                disabled={isQueryUsed}
                variant={variant}
                onClick={() => {
                  if (!company.trim()) return;
                  setCompany('');
                  setCompanies(null);
                  onCompanyClick({ name: company.trim(), id: company.trim() });
                  coverageService.savePrivateCompany(company.trim());
                }}
              >
                <Styled.ItemText>
                  {isQueryUsed ? `${company.trim()} is already in your coverage` : `Select "${company.trim()}" as a private company`}
                </Styled.ItemText>
              </Styled.DropdownItem>
            )}
          </Styled.Dropdown>
        )}
      </div>
    </div>
  );
};
