/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import SixHoursLeft from '@styles/icons/siz-hours-left-to-vote.webp';
import NewDebateOnAnotherUserProfileIcon from '@styles/icons/new-debate-on-another-user.webp';
import PollIcon from '@styles/icons/poll-to-vote.webp';
import ConfirmVoteLine1 from '@styles/icons/confirm-vote-1.svg';
import ConfirmVoteLine2 from '@styles/icons/confirm-vote-2.svg';
import ConfirmVoteLine5 from '@styles/icons/confirm-vote-5.svg';
import ConfirmVoteLine6 from '@styles/icons/confirm-vote-6.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { boolean } from 'joi';
import { Stack } from '@mui/material';
import * as Styled from './poll-vote.styled';
import { checkTimeAlmostUp } from '@/common/utils/check-time-almost-up';
import { IPollPosts, ISubPost } from '@/common/types/post.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { useAddPollAnswer } from '@/common/hooks';
import { month } from '@/common/components/onboard/components/employer/employer.const';
import { PollTextComponent } from '../poll-text';
import { PollAnswerInputComponent } from '../poll-answer-input';
import ArrowBottomRight from '@/styles/icons/arrow-bottom-right.svg';
import { FlexComponent } from '@/common/components/flex';

interface IPollVoteComponentProps {
  userProfileId?: string;
  isAnon?: boolean;
  isPost?: boolean;
  isOwner?: boolean;
  isRepost?: boolean;
  isNotEnouthAnswerForResult?: boolean;
  isResultInProcessing?: boolean;
  pollId?: string;
  text?: string;
  answer?: number | null; // 0 - skip, 1-6 - answers, null/undefined - not voted
  answerCallback?: (answer: number) => void;
  updateQuestionInModal?: (post: ISubPost) => void;
  showSkip?: boolean;
  hideLeftSkip?: boolean;
  isQuote?: boolean;
  isOnNotification?: boolean;
  isOnHome?: boolean; // space beatween text and buttons less than in post tile
  isOnPost?: boolean;
  allowChange?: boolean;
  hideDebateCapture?: boolean;
  className?: string;
  isOnboarding?: boolean;
  isAnotherUserProfile?: boolean;
  ownerFullName?: string;
  ownerId?: string;
  questionEndAt?: string;
  followersCount?: number;
  onClickByPostText?: () => void;
  openPostInModal?: () => void;
  cb?: () => void;
  voteWidth?: number;
  isFirst?: boolean;
  isLatest?: boolean;
  isDisabled?: boolean;
  isTwoRows?: boolean;
  isPopular?: boolean;
  shouldShowVoteOwner?: {
    ownerName: string;
  };
  isCurrent?: boolean;
  poll?: IPollPosts;
  withConfirm?: boolean;
  highlight?: string;
}

export const PollVoteComponent = ({
  userProfileId,
  pollId,
  poll,
  isAnon,
  isPost,
  isOwner,
  isRepost,
  isNotEnouthAnswerForResult,
  isResultInProcessing,
  text,
  answer,
  answerCallback,
  updateQuestionInModal,
  showSkip,
  hideLeftSkip,
  isQuote,
  isOnHome,
  isOnNotification,
  isAnotherUserProfile,
  allowChange,
  hideDebateCapture,
  ownerFullName,
  ownerId,
  questionEndAt,
  followersCount,
  className,
  isOnboarding,
  onClickByPostText,
  openPostInModal,
  cb,
  voteWidth,
  isDisabled,
  isFirst,
  isLatest,
  isTwoRows,
  shouldShowVoteOwner,
  isOnPost,
  isPopular,
  isCurrent,
  withConfirm = true,
  highlight
}: IPollVoteComponentProps) => {
  const { push } = useRouter();
  const [showRules, setShowRules] = useState<boolean>(false);
  const [isTimeAlmostUp, setIsTimeAlmostUp] = useState(checkTimeAlmostUp(questionEndAt));
  const [currentAnswer, setCurrentAnswer] = useState(answer);
  const [currentToConfirmAnswer, setCurrentToConfirmAnswer] = useState<null | number>(null);
  const { mutateAsync: addQuestionAnswer, isLoading } = useAddPollAnswer(userProfileId, cb, updateQuestionInModal, true);
  const pollEndAt = poll?.endAt ? new Date(poll.endAt) : new Date();
  const [isAnswerSuccess, setIsAnswerSuccess] = useState<boolean>(false);

  const isNewAnswerDisabled = isAnswerSuccess || !allowChange;

  useEffect(() => {
    if (answer) {
      setCurrentAnswer(answer);
    }
  }, [answer]);

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newAnswer: number) => {
    if (isQuote) return;
    event.preventDefault();
    event.stopPropagation();

    if (currentAnswer) return;

    setCurrentToConfirmAnswer(newAnswer);
  };

  const clickConfirmHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newAnswer: number) => {
    try {
      if (isQuote) return;
      event.preventDefault();
      event.stopPropagation();

      if (withConfirm && currentAnswer === undefined) {
        return;
      }
      if (((!!currentAnswer || currentAnswer === 0) && !allowChange) || isLoading) {
        return;
      }
      const newAnswerToSend = newAnswer;
      if (!!currentAnswer && currentAnswer === newAnswer && isPost) {
        if (isOwner) return;
        // if (pollId) {
        //   addQuestionAnswer({ pollId, answer: newAnswerToSend });
        // }
      }
      setCurrentAnswer(newAnswerToSend);
      if (answerCallback) {
        answerCallback(newAnswerToSend);
      } else if (pollId && newAnswerToSend) {
        await addQuestionAnswer({ pollId, answer: newAnswerToSend });
        setIsAnswerSuccess(true);
      }
    } catch {
      setIsAnswerSuccess(false);
    }
  };

  const onClickByText = () => {
    if (onClickByPostText) {
      onClickByPostText();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeAlmostUp(checkTimeAlmostUp(questionEndAt));
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isNotNewDebate = !isNotEnouthAnswerForResult && !isResultInProcessing;

  const onClickByCreator = () => {
    if (!ownerId) return;
    push(`${ROUTER_KEYS.PROFILE}?id=${ownerId}`);
  };

  return (
    <Styled.QuestionVoteWrapper
      isRepost={isRepost}
      className={className}
      isOnboarding={isOnboarding}
      isLatest={isLatest || (!currentAnswer && currentAnswer !== 0)}
      isFirst={isFirst}
      isDisabled={isDisabled || !!currentAnswer || currentAnswer === 0}
      isOnPost={isOnPost}
    >
      {isAnotherUserProfile && isNotNewDebate && (
        <Styled.DebateOnAnotherUserProfileTitle>
          <Image
            src={NewDebateOnAnotherUserProfileIcon}
            alt="New Poll on another user profile icon"
            width={14}
            height={14}
            style={{ margin: '3px 0' }}
          />
          <p>{shouldShowVoteOwner?.ownerName ?? ''} voted on this debate</p>
        </Styled.DebateOnAnotherUserProfileTitle>
      )}
      {isTimeAlmostUp && !answer && !isQuote && !hideDebateCapture && isNotNewDebate && (
        <Styled.DebateAlmostUpWrapper>
          <Image src={SixHoursLeft} alt="Six hours left to submit icon" width={14} height={14} style={{ margin: '3px 7px 3px 0' }} />6 hours
          left to vote <span>•</span> {followersCount ?? 0} members you follow have submitted
        </Styled.DebateAlmostUpWrapper>
      )}
      {!!ownerFullName && !hideDebateCapture && (
        <Styled.NewDebateWrapper isQuote={isQuote} isClicable={!!ownerId}>
          {!isQuote && (
            <Styled.PollIcon isTimeAlmostUp={isTimeAlmostUp}>
              <Image src={PollIcon} alt="Icon" width={20} height={20} />
            </Styled.PollIcon>
          )}
          <p>New Poll</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClickByCreator();
            }}
            type="button"
          >
            created by {ownerFullName}
          </button>
        </Styled.NewDebateWrapper>
      )}
      {!!text && <PollTextComponent poll={poll} highlight={highlight} isQuote={isQuote} />}

      {/* {shouldShowVoteOwner && !isQuote && !isAnotherUserProfile && isNotNewDebate && (
        <Styled.OwnerText isOnboarding={isOnboarding}>
          Vote below. Results and how users voted are only available to those that vote:
        </Styled.OwnerText>
      )} */}

      {!isQuote && !isAnotherUserProfile && isNotNewDebate && (
        <Styled.VoteWithSkipContainer voteWidth={voteWidth} isOnboarding={isOnboarding}>
          <PollAnswerInputComponent
            mt="8px"
            unit={poll?.unit}
            answer={currentAnswer!}
            setAnswer={setCurrentAnswer}
            clickConfirmHandler={clickConfirmHandler}
            isDisabled={isLoading || isNewAnswerDisabled}
          />
        </Styled.VoteWithSkipContainer>
      )}
      {showRules ? (
        <Styled.RulesText>
          Live consensus chart is only available to participants
          <br />
          Post earnings release, only accounts with the most accurate estimate are revealed
          <br />
          You can re-submit 30 days after your previous entry; poll closes 15:45 EST on release day
        </Styled.RulesText>
      ) : (
        <FlexComponent onClick={() => setShowRules((prev) => !prev)} paddingLeft="10px" mt="12px" gap="5px" alignItems="center">
          <Styled.RulesTitleImage src={ArrowBottomRight} alt="rules arrow" />
          <Styled.RulesTitle>view polling rules</Styled.RulesTitle>
        </FlexComponent>
      )}

      {/* {shouldShowVoteOwner && !isQuote && !isAnotherUserProfile && isNotNewDebate && (
        <Styled.KeyDebatesDescription>
          {currentToConfirmAnswer
            ? 'Current Villan consensus will be shown after you vote'
            : `Poll closes at 15:45 EST on ${pollEndAt.getDate()} ${
                month[pollEndAt.getUTCMonth()]
              } ${pollEndAt.getFullYear()}; you cannot change
          your vote`}
        </Styled.KeyDebatesDescription>
      )} */}

      {isAnotherUserProfile && isNotNewDebate && !answer && (
        <Styled.KeyDebateDescriptionOnAnotherUserProfile>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              if (openPostInModal) {
                openPostInModal();
              }
            }}
          >
            Click here to vote
          </button>
          <span>•</span>
          <p>results are only available to those that vote</p>
        </Styled.KeyDebateDescriptionOnAnotherUserProfile>
      )}
      {isAnotherUserProfile && isNotNewDebate && !!answer && (
        <Styled.KeyDebateDescriptionOnAnotherUserProfileAlreadyVoted>
          You have already voted on this
        </Styled.KeyDebateDescriptionOnAnotherUserProfileAlreadyVoted>
      )}
      {isNotEnouthAnswerForResult && (
        <Styled.KeyDebateDescriptionNotReveald>
          This debate did not reach enough votes and is now closed.
        </Styled.KeyDebateDescriptionNotReveald>
      )}
      {isResultInProcessing && (
        <Styled.KeyDebateDescriptionNotReveald>Debate results only available to those who voted.</Styled.KeyDebateDescriptionNotReveald>
      )}
    </Styled.QuestionVoteWrapper>
  );
};
